import { Expose } from 'class-transformer';

export interface ICoverage {
  active: boolean;
  covered: boolean;
  expired: boolean;
}

export class Coverage implements ICoverage {
  @Expose() active: boolean;
  @Expose() covered: boolean;
  @Expose() expired: boolean;
}
