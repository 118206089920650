import { fetchJourneys, type FetchJourneysResponse } from '@airhelp/plus';
import { type ApiQueryOptions } from 'hooks/api/queryHelper';
import { useProtectedQuery } from 'hooks/api/useProtectedQuery';
import { ahplusClient } from 'api/clients/clients';
import { transformToFetchJourneyClass } from 'models/journey/Journey';

const useJourneys = (options?: ApiQueryOptions<FetchJourneysResponse>) => {
  return useProtectedQuery({
    queryKey: ['journeys'],
    queryFn: () => fetchJourneys({ client: ahplusClient }),
    select: (data) =>
      data.map((journey) => transformToFetchJourneyClass(journey)),
    ...options,
  });
};

export default useJourneys;
