import {
  colorGreyscale100,
  colorGreyscale200,
  colorGreyscale300,
  colorPrimary900,
} from '@airhelp/design-tokens';
import { PAGE_PATHS } from 'config/routes';

const setStatusBarColor = (color: string) => {
  document
    .querySelector("meta[name='theme-color']")
    ?.setAttribute('content', color);
};

const ROUTES_WITH_WHITE_STATUS_BAR = [
  PAGE_PATHS.LOGIN_PAGE,
  PAGE_PATHS.ERROR_PAGE,
];

export const setStatusBarColorByPathname = (
  pathname: string,
  displayAddToHomeScreenBanner?: boolean,
) => {
  switch (true) {
    case pathname === PAGE_PATHS.HOME_PAGE:
      setStatusBarColor(
        displayAddToHomeScreenBanner ? colorPrimary900 : colorGreyscale300,
      );
      break;
    case ROUTES_WITH_WHITE_STATUS_BAR.includes(pathname):
    case pathname.includes(PAGE_PATHS.FLIGHTS.NEW_PAGE) ||
      pathname.includes(PAGE_PATHS.MEMBERSHIPS.BUY.PROCESSING_PAYMENT_PAGE) ||
      pathname.includes('trip-registration') ||
      pathname.includes('trips'):
      setStatusBarColor(colorGreyscale100);
      break;
    case PAGE_PATHS.LOUNGE_FINDER_PAGE.includes(pathname):
    case pathname.includes(PAGE_PATHS.MEMBERSHIPS_PAGE):
      setStatusBarColor(colorGreyscale200);
      break;
    default:
      setStatusBarColor(colorGreyscale300);
      break;
  }
};
