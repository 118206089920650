import { createBaseAuthHTTPClient } from '@airhelp/auth';
import { type GenericUnauthorizedCatcher } from '@airhelp/http-client';
import { retry } from '@airhelp/http-client/middleware';
import { createBaseAhPlusHTTPClient } from '@airhelp/plus';
import {
  AuthenticationRequiredError,
  createRefreshAccessTokenUnauthorizedCatcher,
} from '@airhelp/session';
import { createBaseWebappHTTPClient } from '@airhelp/webapp';
import { ZodError } from 'zod';
import { convertDomainUrl } from 'utils/domains';
import config from 'config';
import { sentryUtils } from 'utils';

export const AHPLUS_API_URL = convertDomainUrl(
  window.location.origin,
  config.ahplusApiUrl,
);

export const authClient = createBaseAuthHTTPClient({
  caller: config.appName,
  baseURL: convertDomainUrl(window.location.origin, config.authUrl),
});

const throwAuthRequiredWithReturnURL = () => {
  throw new AuthenticationRequiredError();
};

const onUnauthorized: GenericUnauthorizedCatcher =
  createRefreshAccessTokenUnauthorizedCatcher({
    authClient,
    onRetryUnauthorized: throwAuthRequiredWithReturnURL,
  });

export const webappClient = createBaseWebappHTTPClient({
  caller: config.appName,
  baseURL: convertDomainUrl(window.location.origin, config.webappApiUrl),
})
  .middlewares([
    retry({
      maxAttempts: 3,
      retryOnNetworkError: true,
      until: (response) => {
        return typeof response !== 'undefined' && response?.status < 500;
      },
    }),
  ])
  .catcher(401, onUnauthorized);

export const ahplusClient = createBaseAhPlusHTTPClient({
  caller: config.appName,
  baseURL: convertDomainUrl(window.location.origin, config.ahplusApiUrl),
})
  .middlewares([
    retry({
      maxAttempts: 3,
      retryOnNetworkError: true,
      until: (response) => {
        return typeof response !== 'undefined' && response?.status < 500;
      },
    }),
  ])
  .catcher(401, onUnauthorized)
  .catcherFallback((error) => {
    if (error instanceof ZodError) {
      sentryUtils.captureException(error);
    }
    throw error;
  });
