import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Clock,
  AirplaneTakeOffAirstrip,
  AirplaneLandingAirstrip,
} from '@airhelp/icons';
import { Box, Flex, Text } from '@chakra-ui/react';
import { type Airport, type Flight } from '@airhelp/plus';
import config from 'config';
import { journeyUtils } from 'utils';

const { flightStatuses } = config;

interface IComponent {
  airport: Airport | null;
  flight: Flight;
  flightType: 'arrival' | 'departure';
}

const FlightDetails: React.FC<IComponent> = ({
  airport,
  flight,
  flightType,
}) => {
  const { t } = useTranslation();

  const timezone = airport?.timeZone;

  const gmtDepartureDateTime =
    flight.flightStatus?.gmtDepartureDateTime || flight.gmtDepartureDateTime;
  const gmtArrivalDateTime =
    flight.flightStatus?.gmtArrivalDateTime || flight.gmtArrivalDateTime;

  const isCanceledFlight =
    flightType === 'departure'
      ? flight.flightStatus?.departureStatus === flightStatuses.canceled
      : flight.flightStatus?.arrivalStatus === flightStatuses.canceled;

  const scheduledFlightTime =
    flightType === 'departure' ? gmtDepartureDateTime : gmtArrivalDateTime;

  const estimatedDepartureTime = flight.flightStatus?.gmtEstimatedDepartureAt;
  const actualDepartureTime = flight.flightStatus?.gmtActualDepartureAt;

  const estimatedArrivalTime = flight.flightStatus?.gmtEstimatedArrivalAt;
  const actualArrivalTime = flight.flightStatus?.gmtActualArrivalAt;

  const updatedFlightTime =
    flightType === 'departure'
      ? actualDepartureTime || estimatedDepartureTime
      : actualArrivalTime || estimatedArrivalTime;

  return (
    <Box width={{ base: '100%', md: '50%' }} mb={1}>
      <Flex alignItems="center" fontWeight="medium">
        {flightType === 'arrival' && (
          <AirplaneLandingAirstrip color="primary.500" h={5} w="auto" me={3} />
        )}
        {flightType === 'departure' && (
          <AirplaneTakeOffAirstrip color="primary.500" h={5} w="auto" me={3} />
        )}
        {journeyUtils.useCityTranslations(airport)}
      </Flex>
      {airport?.name ? (
        <Text color="greyscale.700" fontSize="sm" mt={1}>
          {airport.name}
        </Text>
      ) : null}
      <Flex mt={4} flexDirection="column">
        <Flex flexDirection={{ base: 'column', md: 'row' }}>
          {isCanceledFlight ? (
            <Text color="danger.500" fontSize="sm" mt={{ base: 4, md: 0 }}>
              {t('flights_list.flight_status.canceled')}
            </Text>
          ) : (
            <Flex
              alignItems="center"
              color="greyscale.700"
              fontSize="sm"
              me={6}
            >
              <Clock color="greyscale.700" h={4} w="auto" me={1} />
              {t('flights_list.scheduled')}{' '}
              {scheduledFlightTime
                ? journeyUtils.flightLocalTime(scheduledFlightTime, timezone)
                : t('common.en_na')}
            </Flex>
          )}
        </Flex>
        <Flex alignItems="center" mt={2} color="greyscale.700" fontSize="sm">
          {!isCanceledFlight && updatedFlightTime ? (
            <>
              <Clock color="greyscale.700" h={4} w="auto" me={1} />
              {flightType === 'departure'
                ? actualDepartureTime
                  ? `${t('common.actual')} `
                  : t('common.estimated')
                : actualArrivalTime
                  ? `${t('common.actual')} `
                  : t('common.estimated')}{' '}
              {journeyUtils.flightLocalTime(updatedFlightTime, timezone)}
            </>
          ) : null}
        </Flex>
      </Flex>
    </Box>
  );
};

export default FlightDetails;
