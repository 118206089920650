import { AirBundleDocumentFile, AirBundleDocumentParam } from '@airhelp/plus';
import { type TranslationKey } from 'types/i18n';

export interface AirPayoutDocument {
  name: string;
  tKey: TranslationKey;
}

interface CountrySpecificDocuments {
  canceled: AirPayoutDocument[];
  delayedOrDiverted: AirPayoutDocument[];
}

// Common documents for CN
const COMMON_CN_DOCUMENTS: AirPayoutDocument[] = [
  {
    name: `${AirBundleDocumentFile.PASSPORT}File`,
    tKey: 'air_luggage.luggage_details.documents.passport_or_id',
  },
  {
    name: `${AirBundleDocumentFile.ORIGINAL_INVOICE}File`,
    tKey: 'air_luggage.luggage_details.documents.original_invoice_of_purchase',
  },
];

// Common documents for DEFAULT
const COMMON_DEFAULT_DOCUMENTS: AirPayoutDocument[] = [
  {
    name: `${AirBundleDocumentFile.PASSPORT}File`,
    tKey: 'air_luggage.luggage_details.documents.passport_or_id',
  },
];

export const DOCUMENTS_CONFIG: Record<string, CountrySpecificDocuments> = {
  CN: {
    canceled: [
      ...COMMON_CN_DOCUMENTS,
      {
        name: `${AirBundleDocumentFile.BOOKING}File`,
        tKey: 'air_luggage.luggage_details.documents.booking_confirmation',
      },
      {
        name: `${AirBundleDocumentFile.CANCELATION}File`,
        tKey: 'air_luggage.luggage_details.documents.flight_cancellation_certificate',
      },
    ],
    delayedOrDiverted: [
      ...COMMON_CN_DOCUMENTS,
      {
        name: `${AirBundleDocumentFile.BOARDING}File`,
        tKey: 'air_luggage.luggage_details.documents.boarding_pass',
      },
    ],
  },
  DEFAULT: {
    canceled: [
      ...COMMON_DEFAULT_DOCUMENTS,
      {
        name: `${AirBundleDocumentFile.BOOKING}File`,
        tKey: 'air_luggage.luggage_details.documents.booking_confirmation',
      },
      {
        name: `${AirBundleDocumentFile.CANCELATION}File`,
        tKey: 'air_luggage.luggage_details.documents.cancel_confirmation_document',
      },
    ],
    delayedOrDiverted: [
      ...COMMON_DEFAULT_DOCUMENTS,
      {
        name: `${AirBundleDocumentFile.BOARDING}File`,
        tKey: 'air_luggage.luggage_details.documents.boarding_pass',
      },
    ],
  },
};

export const getAirPayoutDocuments = (
  selectedCountry: string,
  airPayoutEligibleCanceled: boolean,
): AirPayoutDocument[] => {
  const countryDocuments =
    DOCUMENTS_CONFIG[selectedCountry] || DOCUMENTS_CONFIG.DEFAULT;
  return airPayoutEligibleCanceled
    ? countryDocuments.canceled
    : countryDocuments.delayedOrDiverted;
};

export const airPayoutDocumentMappings = [
  {
    fileKey: `${AirBundleDocumentFile.BOOKING}File`,
    documentType: AirBundleDocumentParam.BOOKING,
  },
  {
    fileKey: `${AirBundleDocumentFile.PASSPORT}File`,
    documentType: AirBundleDocumentParam.PASSPORT,
  },
  {
    fileKey: `${AirBundleDocumentFile.CANCELATION}File`,
    documentType: AirBundleDocumentParam.CANCELATION,
  },
  {
    fileKey: `${AirBundleDocumentFile.BOARDING}File`,
    documentType: AirBundleDocumentParam.BOARDING,
  },
  {
    fileKey: `${AirBundleDocumentFile.ORIGINAL_INVOICE}File`,
    documentType: AirBundleDocumentParam.ORIGINAL_INVOICE,
  },
];
