import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import rtl from 'stylis-plugin-rtl';
import { locales } from 'contexts/LocaleContext/locales';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';

const options = {
  rtl: { key: 'css-ar', stylisPlugins: [rtl] },
  ltr: { key: 'css-en' },
};

const RtlProvider = ({ children }) => {
  const { locale } = useLocale();
  const dir = locales[locale]?.rtl ? 'rtl' : 'ltr';
  const cache = createCache(options[dir]);

  return <CacheProvider value={cache}>{children}</CacheProvider>;
};

export default RtlProvider;
