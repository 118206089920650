import React from 'react';
import { Text, Box, Flex, UnorderedList } from '@chakra-ui/react';
import { Check } from '@airhelp/icons';
import BenefitItem from './BenefitItem';
import { type Benefit } from './types';

interface IComponent {
  name: string;
  benefits?: Benefit[];
}

const BenefitsSection: React.FC<IComponent> = ({ name, benefits }) => {
  return (
    <Box mb={4}>
      <Flex alignItems="center">
        <Check boxSize={5} me={2} color="primary.500" />
        <Text fontSize="md" fontWeight="medium">
          {name}
        </Text>
      </Flex>
      <UnorderedList spacing={1} fontSize="md" listStyleType="disc" mt={2}>
        {benefits
          ? benefits.map((section) => (
              <BenefitItem benefit={section} key={section.text} />
            ))
          : null}
      </UnorderedList>
    </Box>
  );
};

export default BenefitsSection;
