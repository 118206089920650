import { Flex, VStack, Text, Box } from '@chakra-ui/react';
import { ID, MoneyPaper } from '@airhelp/icons';
import { type CountryCode } from '@airhelp/flags';
import {
  type CurrencyCode,
  formatValueToCurrency,
} from 'stores/preferences/currencies';
import bg from 'assets/images/claim-bg.png';
import { ShortInfo } from './parts/ShortInfo';
import { Airport } from './parts/Airport';
import { Separator } from './parts/Separator';

interface ClaimOverviewProps {
  departureAirportCountryCode: CountryCode;
  departureAirportName: string;
  departureAirportCode: string;
  arrivalAirportCountryCode: CountryCode;
  arrivalAirportName: string;
  arrivalAirportCode: string;
  claimId: number | string;
  compensation: string;
  currency: CurrencyCode;
}

export function ClaimOverview({
  departureAirportCountryCode,
  departureAirportName,
  departureAirportCode,
  arrivalAirportCountryCode,
  arrivalAirportName,
  arrivalAirportCode,
  claimId,
  compensation,
  currency,
}: ClaimOverviewProps) {
  return (
    <Box
      rounded="3xl"
      boxShadow="lg"
      overflow="hidden"
      backgroundColor="primary.700"
      position="relative"
      height={{
        base: '158px',
        md: '112px',
      }}
      isolation="isolate"
    >
      <Box
        backgroundImage={`url(${bg})`}
        filter={{
          base: 'drop-shadow(2px 4px 6px #1E29361F) blur(7px)',
          md: 'drop-shadow(2px 4px 6px #1E29361F) blur(7px)',
        }}
        backgroundRepeat="no-repeat"
        backgroundPosition={{
          base: 'right -190px bottom 0px',
          md: 'right 10px bottom 0px',
          lg: 'right -60% bottom 5px',
          xl: 'right 5% bottom 5px',
        }}
        backgroundSize={{
          base: 'cover',
          md: 'contain',
        }}
        height="110%"
      />
      <Flex
        p={6}
        zIndex={2}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="100%"
        justifyContent="space-between"
        flexDirection={{
          base: 'column',
          md: 'row',
        }}
      >
        <FlightSummary
          departureAirportName={departureAirportName}
          departureAirportCountryCode={departureAirportCountryCode}
          departureAirportCode={departureAirportCode}
          arrivalAirportName={arrivalAirportName}
          arrivalAirportCountryCode={arrivalAirportCountryCode}
          arrivalAirportCode={arrivalAirportCode}
        />
        <CompensationInfo
          claimId={claimId}
          compensation={compensation}
          currency={currency}
        />
      </Flex>
    </Box>
  );
}

interface FlightSummaryProps {
  departureAirportCountryCode: CountryCode;
  departureAirportName: string;
  departureAirportCode: string;
  arrivalAirportCountryCode: CountryCode;
  arrivalAirportName: string;
  arrivalAirportCode: string;
}

function FlightSummary({
  departureAirportCountryCode,
  departureAirportName,
  departureAirportCode,
  arrivalAirportCountryCode,
  arrivalAirportName,
  arrivalAirportCode,
}: FlightSummaryProps) {
  return (
    <VStack
      spacing={2}
      maxW={{
        base: '100%',
        md: '300px',
        lg: '320px',
      }}
      flex={1}
    >
      <Flex justifyContent="space-between" w="full">
        <Airport
          airport={departureAirportName}
          airportCountryCode={departureAirportCountryCode}
        />
        <Airport
          direction="row-reverse"
          airport={arrivalAirportName}
          airportCountryCode={arrivalAirportCountryCode}
        />
      </Flex>
      <Flex justifyContent="space-between" w="100%" gap={6}>
        <Text color="greyscale.100" fontSize="30px" fontWeight="bold">
          {departureAirportCode}
        </Text>
        <Separator />
        <Text color="greyscale.100" fontSize="30px" fontWeight="bold">
          {arrivalAirportCode}
        </Text>
      </Flex>
    </VStack>
  );
}

interface CompensationInfoProps {
  compensation: string;
  currency: CurrencyCode;
  claimId: number | string;
  iconPlacement?: 'left' | 'right';
}

function CompensationInfo({
  compensation,
  claimId,
  currency,
}: CompensationInfoProps) {
  const localCompensation = formatValueToCurrency(compensation, currency);

  return (
    <Flex
      boxShadow="0px 20px 40px -8px #1E29361F"
      gap={2}
      justifyContent={{
        base: 'space-between',
        md: 'flex-end',
      }}
    >
      <ShortInfo icon={ID} label="Claim ID" value={claimId} />
      <ShortInfo
        icon={MoneyPaper}
        reversed
        label="Compensation"
        value={localCompensation}
      />
    </Flex>
  );
}
