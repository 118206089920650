import React from 'react';
import { Trans } from 'react-i18next';
import { Info } from '@airhelp/icons';
import { InfoBox } from '@airhelp/react';
import { Text, Button, VStack, Flex } from '@chakra-ui/react';
import { format as formatDate } from 'date-fns';
import { t } from 'i18next';
import CancelConfirmationModal from 'components/MyAccountView/BenefitsView/ManageYourPlan/CancelConfirmationModal';
import {
  dateFnLocaleHelper,
  useLocale,
} from 'contexts/LocaleContext/LocaleContextProvider';
import type { UserProfile } from 'models/userProfile/UserProfile';
import { useTrackEvent } from 'utils/tracking/hooks';

const ManageYourPlan: React.FC<{
  userProfile: UserProfile;
}> = ({ userProfile }) => {
  const policy = userProfile.policy;
  const { locale } = useLocale();

  const isMembershipCanceled = policy.membership?.canceled;

  const endDate = policy.membership?.endDate;
  const dateLocale = dateFnLocaleHelper(locale);
  const expiredDate = endDate
    ? formatDate(new Date(endDate), 'dd LLLL, yyyy', { locale: dateLocale })
    : null;
  const { trackCtaClick } = useTrackEvent();

  return (
    <Flex
      flexDirection="column"
      bg="greyscale.100"
      p={{ base: 4, md: 6 }}
      borderRadius="lg"
      alignItems="flex-start"
    >
      <VStack alignItems="flex-start" spacing={0} mb={6}>
        <Text as="span" fontSize="lg" fontWeight="medium">
          <Trans i18nKey="my_account.my_benefits.manage_your_plan.header" />
        </Text>
        <Trans
          i18nKey="my_account.my_benefits.manage_your_plan.description"
          values={{ plan: policy.membership?.displayName }}
          components={{
            strong: <Text />,
          }}
        />
      </VStack>
      {isMembershipCanceled ? (
        <InfoBox
          isChat
          backgroundColor="greyscale.100"
          icon={<Info color="primary.500" display="inline-flex" mb={1} />}
          variant="secondary"
        >
          <Trans
            i18nKey="my_account.membership.membership_cancelled_info_box"
            values={{
              planName: policy.membership?.displayName,
              date: expiredDate,
            }}
            components={{
              strong: <Text as="span" fontSize="md" fontWeight="medium" />,
            }}
          />
        </InfoBox>
      ) : (
        <CancelConfirmationModal
          endDate={endDate}
          render={(openModalHandler) => (
            <Button
              size="sm"
              variant="link"
              onClick={() => {
                trackCtaClick('cancel my plan', 'my account page');
                openModalHandler();
              }}
              color="danger.500"
              fontWeight="normal"
              fontSize="md"
              whiteSpace="nowrap"
              _focus={{ boxShadow: 'none' }}
              data-testid="cancel-my-membership-link"
            >
              {t('my_account.my_benefits.manage_your_plan.cancel')}
            </Button>
          )}
        />
      )}
    </Flex>
  );
};

export default ManageYourPlan;
