import React from 'react';
import { Switch, Text, Flex, VStack, HStack } from '@chakra-ui/react';
import { Envelope } from '@airhelp/icons';
import { useTranslation } from 'react-i18next';
import { type NotificationItem } from '../types';

interface IComponent {
  item: NotificationItem;
  onToggle: () => void;
  isDisabled: boolean;
}

const NotificationDetails: React.FC<IComponent> = ({
  item,
  onToggle,
  isDisabled,
}) => {
  const { t } = useTranslation();

  const { title, description, state, name } = item;

  return (
    <Flex
      flexDirection={{ base: 'column', md: 'row' }}
      gap={{ base: 4, md: 8 }}
      bg="greyscale.100"
      p={{ base: 4, md: 6 }}
      borderRadius="lg"
      width="100%"
      flex="1 148px"
    >
      <VStack
        align="flex-start"
        spacing={0}
        width={{ base: '100%', md: '33%' }}
      >
        <Text color="primary.900" fontWeight="medium">
          {title}
        </Text>
        <Text fontSize="md" color="greyscale.600">
          {description}
        </Text>
      </VStack>
      <HStack
        borderWidth="1px"
        borderColor="greyscale.400"
        borderRadius="lg"
        width={{ base: '100%', md: '67%' }}
        height="100%"
        p={4}
        spacing={4}
        justify="space-between"
        alignItems="center"
      >
        <HStack spacing={4} height="100%" flexGrow={1}>
          <Envelope
            boxSize={9}
            p={2}
            bg="primary.100"
            color="primary.500"
            borderRadius="md"
            me={2}
          />
          <Text
            alignSelf="center"
            color="primary.900"
            fontSize="md"
            fontWeight="medium"
          >
            {t('common.email')}
          </Text>
        </HStack>
        <Switch
          isChecked={state}
          isDisabled={isDisabled}
          onChange={onToggle}
          data-testid={`${name}-notification-switch`}
        />
      </HStack>
    </Flex>
  );
};

export default NotificationDetails;
