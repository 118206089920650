import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Check } from '@airhelp/icons';
import { Button, Input, InputGroup, Text } from '@chakra-ui/react';
import { useTrackEvent } from 'utils/tracking/hooks';

interface Props {
  id: string;
  inputValue: string;
  fullWidth?: boolean;
  valueAlign?: 'left' | 'center' | 'right';
  isLoading?: boolean;
  onCopy?: () => void;
  eventLabel?: string;
  eventPlacement?: string;
}

const CopyToClipboardButton: React.FC<Props> = ({
  id,
  inputValue,
  fullWidth = false,
  valueAlign = 'center',
  onCopy,
  eventLabel,
  eventPlacement,
}) => {
  const [hasCopied, setHasCopied] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const { trackCtaClick } = useTrackEvent();

  const copyValueToClipboard = async () => {
    const input = inputRef.current;

    if (onCopy) {
      onCopy();
    }

    if (eventLabel && eventPlacement) {
      trackCtaClick(eventLabel, eventPlacement);
    }
    if (input && input.value.length > 0) {
      input.select();

      // Required for iOS
      // See also https://stackoverflow.com/a/54966926/45974
      input.setSelectionRange(0, input.value.length);
      const textToCopy = input.value;
      try {
        await navigator.clipboard.writeText(textToCopy);
        setHasCopied(true);
      } catch {
        try {
          document.execCommand('copy');
          setHasCopied(true);
        } catch (e) {
          // eslint-disable-next-line no-console -- We need to log a warning if the copy command fails
          console.warn('Failed to execute copy command.', e);
        }
      }
    }
  };

  return (
    <InputGroup
      width={fullWidth ? '100%' : 'auto'}
      onClick={copyValueToClipboard}
      data-testid={`${id}-copy-to-clipboard-button`}
    >
      <Input
        ref={inputRef}
        value={inputValue}
        isReadOnly
        fontSize="md"
        fontWeight="normal"
        borderRightRadius="none"
        backgroundColor="greyscale.100"
        cursor="pointer"
        data-testid={`${id}-copy-to-clipboard-text-input`}
        width={fullWidth ? '100%' : '130px'}
        _focus={{ outline: 'none', boxShadow: 'none', borderColor: 'none' }}
        textAlign={valueAlign}
        textOverflow="ellipsis"
      />

      <Button
        borderLeftRadius="none"
        {...(hasCopied && {
          bgColor: 'success.600',
          _hover: { bgColor: 'success.700' },
        })}
        whiteSpace="nowrap"
      >
        {hasCopied ? (
          <>
            <Check color="greyscale.100" me={2} />
            {t('copy_to_clipboard_button.copied')}
          </>
        ) : (
          <Text color="greyscale.100" fontSize="md" fontWeight="medium">
            {t('copy_to_clipboard_button.copy')}
          </Text>
        )}
      </Button>
    </InputGroup>
  );
};

export default CopyToClipboardButton;
