import { Box, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const airBundleTableStyle = {
  th: {
    padding: 0,
    fontSize: 'sm',
    fontWeight: 'medium',
    color: 'greyscale.600',
  },
  td: {
    px: 0,
    fontSize: 'sm',
    fontWeight: 'medium',
    color: 'primary.900',
  },
};

const AirBundleThead = () => {
  const { t } = useTranslation();

  return (
    <Box display={{ base: 'none', md: 'block' }}>
      <Flex display="grid" gridTemplateColumns="repeat(3, 1fr)">
        <Text {...airBundleTableStyle.th}>{t('common.single_passenger')}</Text>
        <Text {...airBundleTableStyle.th}>{t('common.status')}</Text>
        <Text {...airBundleTableStyle.th} textAlign="right">
          {t('common.action')}
        </Text>
      </Flex>
    </Box>
  );
};

export default AirBundleThead;
