import React from 'react';
import { track } from 'react-tracking';
import CheckoutView from 'components/Upsell/Trip/CheckoutView';
import StripePaymentContextProvider from 'contexts/StripePaymentContext/StripePaymentContext';
import { usePageTracking } from 'utils/tracking';

const CheckoutPage = () => {
  usePageTracking();

  return (
    <StripePaymentContextProvider>
      <CheckoutView />
    </StripePaymentContextProvider>
  );
};

export default track({ page_type: 'Upsell.Trips.Checkout' })(CheckoutPage);
