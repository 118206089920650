import React from 'react';
import { Box } from '@chakra-ui/react';
import { ErrorBadge, Loader } from 'elements';
import useBillingManagement from 'hooks/api/billingManagement/useBillingManagement';
import NoCardView from './PaymentMethodsView/NoCardView';
import PaymentMethodsView from './PaymentMethodsView/PaymentMethodsView';

const PaymentManagementView: React.FC = () => {
  const {
    isLoading,
    isError,
    data: billingManagement,
  } = useBillingManagement({});

  if (isLoading) {
    return <Loader />;
  }
  if (isError || !billingManagement) {
    return (
      <Box maxWidth={{ base: '100%', md: '300px' }}>
        <ErrorBadge mt={6} />
      </Box>
    );
  }

  const { payment } = billingManagement;

  if (payment) {
    return <PaymentMethodsView billingManagement={billingManagement} />;
  }
  return <NoCardView billingManagement={billingManagement} />;
};

export default PaymentManagementView;
