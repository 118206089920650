import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useTracking } from 'react-tracking';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { TrackingContext } from 'contexts';
import { getPageViewedData, getUserData } from '../props/ga4';
import { useTrackOnce } from './useTrackOnce';

interface TrackingData {
  page_type?: string;
}
export const usePageTracking = () => {
  const { setSubPageType } = useContext(TrackingContext);
  const { userProfile, queryState: userProfileQueryState } = useMyProfile();
  const { i18n } = useTranslation();
  const tracking = useTracking();
  const location = useLocation();

  const isLoadingUserProfile = userProfileQueryState?.status === 'pending';
  const pageReferrer = sessionStorage.getItem('pageReferrer') || '';
  const trackingData = tracking.getTrackingData();

  useEffect(() => {
    if (pageReferrer !== window.location.href) {
      sessionStorage.setItem('pageReferrer', window.location.href);
    }
  }, [location.pathname]);

  // tracking "ga4" page_view event
  useTrackOnce(
    (setTracked) => {
      if (!isLoadingUserProfile) {
        tracking.trackEvent({
          ...getPageViewedData(i18n.language, pageReferrer),
          ...getUserData(userProfile),
        });

        setTracked();
      }
    },
    [isLoadingUserProfile, userProfile],
  );

  // set subPageType on TrackingContext based on page_type set on a pages/ component
  useEffect(() => {
    const pageType = (trackingData as TrackingData).page_type || '';

    setSubPageType(pageType);
  }, [location.pathname, trackingData]);
};
