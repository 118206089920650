import React from 'react';
import { useTranslation } from 'react-i18next';
import { type StackProps } from '@chakra-ui/react';
import tripsRoute from 'assets/images/trips-route@2x.png';
import config from 'config';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import LargeBlankTile from 'elements/LargeBlankTile';

type IComponent = { onClick: () => void } & StackProps;

const LargeUpsellTile = ({ onClick, ...props }: IComponent) => {
  const { t } = useTranslation();
  const { locale } = useLocale();

  const buttonProps = {
    variant: 'primaryAlt',
    'data-testid': 'btn-see-our-plans-large-tile',
    _visited: { color: 'greyscale.100' },
  };

  const urlPricingPlans = `${config.websiteUrl}/${locale}/airhelp-plus/#pricing-plans`;

  return (
    <LargeBlankTile
      image={tripsRoute}
      header={t('journeys.protect_future_trip_with_ah')}
      description={t('journeys.protect_future_trip_with_ah_desc')}
      onClick={onClick}
      buttonLabel={t('common.check_our_plans')}
      showPlusIcon={false}
      externalLink={urlPricingPlans}
      buttonProps={buttonProps}
      border={{ base: '1px solid', md: 'none' }}
      borderColor="greyscale.400"
      data-testid="large-upsell-tile"
      {...props}
    />
  );
};

export default LargeUpsellTile;
