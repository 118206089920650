import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  type DrawerProps,
  useBreakpointValue,
} from '@chakra-ui/react';
import { type ComponentProps } from 'react';
import { TravelDetails } from './TravelDetails';

export function TravelDetailsDrawer({
  isOpen,
  onClose,
  flights,
}: {
  isOpen: boolean;
  onClose: () => void;
  flights: ComponentProps<typeof TravelDetails>['flights'];
}) {
  const placement: DrawerProps['placement'] =
    useBreakpointValue({ base: 'bottom', lg: 'end' }) ?? 'end';
  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement={placement}>
      <DrawerOverlay />
      <DrawerContent
        m={{
          base: 0,
          lg: 4,
        }}
        p={4}
        pt={6}
        rounded="xl"
        pos="relative"
        minW={{ lg: 400 }}
        overflow="hidden"
      >
        <TravelDetails
          header={`${flights.at(0)?.departureAirport.city} - ${flights.at(-1)?.arrivalAirport.city}`}
          i18n={{
            connectingFlightsCountText: 'Connecting flights:',
            connectionSeperatorText: 'connected with',
          }}
          flights={flights}
        />
      </DrawerContent>
    </Drawer>
  );
}
