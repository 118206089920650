import { type IconType } from '@airhelp/icons';
import { Flex, VStack, Text, Circle } from '@chakra-ui/react';

interface ShortInfoProps {
  icon: IconType;
  label: string;
  value: string | number;
  reversed?: boolean;
}

function IconWrapper({ children }) {
  return (
    <Circle
      size={8}
      alignSelf="center"
      textAlign="center"
      border="1px solid rgba(255, 255, 255, 0.3)"
      background="linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.03) 100%)"
      backdropFilter="blur(14px)"
    >
      {children}
    </Circle>
  );
}

export function ShortInfo({
  icon: Icon,
  label,
  value,
  reversed = false,
}: ShortInfoProps) {
  return (
    <Flex
      rounded="2xl"
      backdropFilter={{
        base: 'none',
        md: 'blur(14px)',
      }}
      background={{
        base: 'none',
        md: 'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.03) 100%)',
      }}
      border={{
        base: 'none',
        md: '1px solid rgba(255, 255, 255, 0.3)',
      }}
      p={{
        base: 0,
        md: '16px 18px',
      }}
      gap={{
        base: 2,
        md: 3,
      }}
      alignItems="center"
      flexDirection={{
        base: reversed ? 'row-reverse' : 'row',
        md: 'row',
      }}
    >
      <IconWrapper>
        <Icon color="greyscale.100" w={4} h={4} />
      </IconWrapper>

      <VStack spacing={0} alignItems={reversed ? 'flex-end' : 'flex-start'}>
        <Text
          color="greyscale.500"
          fontSize="11px"
          textTransform="uppercase"
          letterSpacing="-0.014em"
          fontWeight="bold"
        >
          {label}
        </Text>
        <Text color="greyscale.100" fontSize="md" fontWeight="bold">
          {value}
        </Text>
      </VStack>
    </Flex>
  );
}
