import React, { type ChangeEvent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '@airhelp/react';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalBody,
  Button,
  ModalCloseButton,
  ModalContent,
  Heading,
  VStack,
  Text,
} from '@chakra-ui/react';
import { type UpdateJourneyPayload } from '@airhelp/plus';
import { ErrorBadge } from 'elements';
import { MAX_LENGTH_JOURNEY_TITLE } from 'utils/journey/journey';
import { type Journey } from 'models/journey/Journey';
import useUpdateJourney from 'hooks/api/journey/useUpdateJourney';

interface IComponent {
  journey: Journey;
  handleToggleRename: () => void;
  isOpen: boolean;
}

const Rename: React.FC<IComponent> = ({
  journey,
  handleToggleRename,
  isOpen,
}) => {
  const { t, i18n } = useTranslation();
  const journeyName = journey.generateDisplayName(t, i18n.language);
  const updateJourney = useUpdateJourney();
  const { isError, isPending: isLoading, reset: resetApi } = updateJourney;

  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UpdateJourneyPayload>({ mode: 'onChange' });

  const formHasErrors = Object.keys(errors).length > 0;

  const resetForm = () => {
    reset({ name: journeyName });
    resetApi();
  };

  const closeModalHandler = () => {
    resetForm();
    handleToggleRename();
  };

  const processUpdateJourney = (data: UpdateJourneyPayload) => {
    updateJourney.mutate(
      { id: journey.id, payload: data },
      {
        onSuccess: () => {
          handleToggleRename();
        },
      },
    );
  };

  const handleNameChange = (
    name: string | null,
    formOnChange: (name) => void,
  ) => {
    formOnChange(name ? name : '');
  };

  const onSubmit = (data: UpdateJourneyPayload) => {
    if (!data.name) {
      data.name = journeyName;
    }

    processUpdateJourney(data);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModalHandler}
      size="sm"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton
          borderRadius="sm"
          boxSize={2}
          top={4}
          right={4}
          backgroundColor="greyscale.300"
          sx={{
            svg: { boxSize: 2.5 },
            _hover: { backgroundColor: 'primary.200' },
            _active: {
              backgroundColor: 'primary.800',
              color: 'greyscale.200',
            },
          }}
          data-testid="btn-close-modal-change-journey-name"
        />
        <ModalBody p={{ base: 4, md: 4 }} sx={{ overflow: 'auto' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Heading
              variant="h5"
              mb={4}
              mt={0}
              lineHeight="short"
              width="calc(100% - 32px)"
            >
              {t('journeys.rename_modal.rename_your_trip')}
            </Heading>
            <Controller
              name="name"
              control={control}
              rules={{
                pattern: { value: /^\S+/, message: t('errors.required') },
                required: { value: true, message: t('errors.required') },
                maxLength: {
                  value: MAX_LENGTH_JOURNEY_TITLE,
                  message: t('errors.too_long', {
                    attribute: t('journeys.name_placeholder'),
                    max: MAX_LENGTH_JOURNEY_TITLE,
                  }),
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <Box mb={error?.message ? 3 : 0}>
                  <Input
                    isInvalid={invalid}
                    errorMessage={error?.message || ''}
                    size="lg"
                    value={value}
                    type="text"
                    defaultValue={journeyName}
                    placeholder={t('journeys.name_placeholder')}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      handleNameChange(event.target.value, onChange);
                    }}
                    data-testid="journey-name"
                  />
                  {!error?.message && (
                    <Text fontSize="sm" color="greyscale.600" mt={1} mb={4}>
                      {t('common.max_characters', { max: 40 })}
                    </Text>
                  )}
                </Box>
              )}
            />
            {isError ? (
              <Box mb={6}>
                <ErrorBadge>{t('errors.something_went_wrong')}</ErrorBadge>
              </Box>
            ) : null}
            <VStack spacing={2}>
              <Button
                type="submit"
                variant="primary"
                textTransform="capitalize"
                width="100%"
                size="s"
                isLoading={isLoading}
                isDisabled={formHasErrors || isLoading || isError}
                data-testid="save-button-change-journey-name"
              >
                {t('common.save')}
              </Button>
              <Button
                onClick={closeModalHandler}
                variant="secondary"
                size="s"
                textTransform="capitalize"
                width="100%"
                data-testid="cancel-button-change-journey-name"
              >
                {t('common.cancel')}
              </Button>
            </VStack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Rename;
