import React from 'react';
import { Outlet } from 'react-router-dom';
import DefaultLayout from 'components/Layouts';

const DefaultPageRouteLayout = () => {
  return (
    <DefaultLayout>
      <Outlet />
    </DefaultLayout>
  );
};

export default DefaultPageRouteLayout;
