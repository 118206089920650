import { LoungesPage } from 'pages';
import * as LoungesPages from 'pages/Lounges';

export const LoungesRoutes = {
  path: 'lounges',
  element: <LoungesPage />,
  children: [
    {
      index: true,
      element: <LoungesPages.IndexPage />,
    },
    {
      path: 'passes',
      element: <LoungesPages.PassesPage />,
    },
  ],
};
