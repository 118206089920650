import React, { type BaseSyntheticEvent } from 'react';
import { type UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@chakra-ui/react';
import { BANK_INPUTS, type InsuranceType } from '@airhelp/plus';
import { camelCase, omit } from 'lodash';
import { useLayout } from 'contexts/LayoutContext/LayoutContext';
import { useTrackEvent } from 'utils/tracking/hooks';

const AirBundlePayoutActions = ({
  form,
  uploadInProgress,
  bankDetailsCollected,
  setBankDetailsCollected,
  onSubmit,
  insuranceType,
  submitError,
  isDisabled,
  isUpdatePayment,
  resetReducer,
}: {
  form: UseFormReturn;
  uploadInProgress?: boolean;
  bankDetailsCollected?: boolean;
  setBankDetailsCollected?: (value: boolean) => void;
  onSubmit: (data) => void;
  insuranceType: InsuranceType;
  submitError: boolean;
  isDisabled?: boolean;
  isUpdatePayment?: boolean;
  resetReducer?: () => void;
}) => {
  const { t } = useTranslation();
  const { trackCtaClick } = useTrackEvent();
  const { setValue, watch, formState, handleSubmit, getValues, reset } = form;
  const { activeStep, setActiveStep } = useLayout();

  const resetBankFields = () => {
    const currentValues = getValues();
    const newValues = omit(currentValues, Object.values(BANK_INPUTS));
    reset(newValues);
    if (resetReducer) {
      resetReducer();
    }
  };

  const { isSubmitting } = formState;

  const isLoadingPayoutButton = isSubmitting || uploadInProgress;
  const isDisabledPayoutButton =
    isLoadingPayoutButton || submitError || isDisabled;

  const countrySelected = watch('country')?.value || '';
  const pageEventName = `ahp ${camelCase(insuranceType.replace(/_/g, ' '))} page`;

  const handleNextStep = () => {
    if (setBankDetailsCollected) {
      setActiveStep(activeStep + 1);
      setBankDetailsCollected(true);
      trackCtaClick('continue button', pageEventName);
    }
    return null;
  };

  const handleRequest = (data: BaseSyntheticEvent | undefined) => {
    handleSubmit(onSubmit)(data);
    trackCtaClick('request payment button', pageEventName);
  };

  const allowRequestPayment =
    bankDetailsCollected || (countrySelected && isUpdatePayment);

  const handleBackStep = () => {
    if (countrySelected && !bankDetailsCollected) {
      setValue('country', '');
      resetBankFields();
    }

    if (bankDetailsCollected && setBankDetailsCollected) {
      setActiveStep(activeStep - 1);
      setBankDetailsCollected(false);
    }
    return null;
  };

  return (
    <Stack
      width="100%"
      direction={{ base: 'column-reverse', md: 'row' }}
      justifyContent="right"
      mt={6}
      spacing={5}
    >
      {countrySelected ? (
        <Button
          variant="secondary"
          size="s"
          onClick={handleBackStep}
          data-testid="back-button"
        >
          {t('common.back')}
        </Button>
      ) : null}
      <Button
        isLoading={isLoadingPayoutButton}
        isDisabled={isDisabledPayoutButton}
        onClick={
          allowRequestPayment ? handleRequest : handleSubmit(handleNextStep)
        }
        size="s"
        data-testid={
          allowRequestPayment
            ? 'request-payment-button'
            : 'save-data-details-button'
        }
      >
        {allowRequestPayment
          ? t('instant_cash.payout.request_payment')
          : t('common.continue')}
      </Button>
    </Stack>
  );
};

export default AirBundlePayoutActions;
