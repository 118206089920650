import { useImperativeHandle, type Ref } from 'react';

export interface ModalRef {
  openModal: () => void;
  closeModal: () => void;
}

export const useModalHandle = (
  ref: Ref<ModalRef>,
  openModal: () => void,
  closeModal: () => void,
) => {
  useImperativeHandle(ref, () => ({
    openModal,
    closeModal,
  }));
};
