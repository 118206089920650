import React from 'react';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { Input } from '@airhelp/react';
import { Box } from '@chakra-ui/react';
import { type TFunction } from 'i18next';

const DEFAULT_BSB_MIN_LENGTH = 6;
const DEFAULT_BSB_MAX_LENGTH = 6;

const bsbCode = ({ form, t }: { form: UseFormReturn; t: TFunction }) => {
  const BSB_MAX_LENGTH = DEFAULT_BSB_MAX_LENGTH;

  const BSB_MIN_LENGTH = DEFAULT_BSB_MIN_LENGTH;

  return (
    <Controller
      name="bsbCode"
      control={form.control}
      rules={{
        required: { value: true, message: t('errors.required') },
        pattern: {
          value: /^[0-9]+$/,
          message: t('errors.please_insert_valid_field', {
            field: t('common.bsb_code'),
          }),
        },
        minLength: {
          value: BSB_MIN_LENGTH,
          message: t('errors.too_short', {
            attribute: t('common.bsb_code'),
            min: BSB_MIN_LENGTH,
          }),
        },
        maxLength: {
          value: BSB_MAX_LENGTH,
          message: t('errors.too_long', {
            attribute: t('common.bsb_code'),
            max: BSB_MAX_LENGTH,
          }),
        },
      }}
      defaultValue=""
      render={({
        field: { onChange, name, value },
        fieldState: { invalid, error },
      }) => (
        <Box width="100%" minHeight="106px">
          <Input
            isInvalid={invalid}
            errorMessage={error?.message || ''}
            name={name}
            label={t('common.bsb_code')}
            value={value}
            placeholder={t('common.bsb_code')}
            onChange={onChange}
            minLength={BSB_MIN_LENGTH}
            maxLength={BSB_MIN_LENGTH}
            data-testid="bsb-code"
          />
        </Box>
      )}
    />
  );
};

export default bsbCode;
