import {
  fetchCheckDeletableJourney,
  type FetchCheckDeletableJourneyResponseResponseSchema,
} from '@airhelp/plus';
import { ahplusClient } from 'api/clients/clients';
import { useProtectedQuery } from 'hooks/api/useProtectedQuery';
import { type ApiQueryOptions } from 'hooks/api/queryHelper';
import { transformToJourneyCheckDeletableClass } from 'models/journey/JourneyCheckDeletable';

const useCheckDeletableJourney = ({
  id,
  options,
}: {
  id: number;
  options?: ApiQueryOptions<FetchCheckDeletableJourneyResponseResponseSchema>;
}) => {
  return useProtectedQuery({
    queryKey: ['journey', { id }, 'checkDeletableJourney'],
    queryFn: () =>
      fetchCheckDeletableJourney({ client: ahplusClient, params: { id } }),
    select: (data) => transformToJourneyCheckDeletableClass(data),
    ...options,
  });
};
export default useCheckDeletableJourney;
