import { Box, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import CheckoutLayout from 'components/Layouts/CheckoutLayout';
import { ErrorBadge } from 'elements';
import AirhelpBrandWithPlanName from 'elements/AirhelpBrandWithPlanName';
import { formatValueToCurrency } from 'stores/preferences/currencies';
import { type Order } from 'models/Order';
import { type UserProfile } from 'models/userProfile/UserProfile';
import { type Offer } from 'models/Offers';
import Side from './Side';

const LabeledValue = ({
  label,
  labelColor,
  labelBold,
  value,
  valueColor,
  valueBold,
  ...props
}: {
  label: string;
  labelColor?: string;
  labelBold?: string;
  value: string;
  valueColor?: string;
  valueBold?: string;
}) => {
  return (
    <HStack {...props} width="100%" justifyContent="space-between">
      <Text
        data-testid="label"
        fontSize="md"
        color={labelColor || 'greyscale.500'}
        fontWeight={labelBold}
      >
        {label}
      </Text>
      <Text
        data-testid="value"
        fontSize="md"
        color={valueColor}
        fontWeight={valueBold}
      >
        {value}
      </Text>
    </HStack>
  );
};

interface IComponent {
  order?: Order;
  userProfile?: UserProfile;
  claimEnquiryUuid?: string;
  channel: string;
  claimId?: string;
  isError: boolean;
  productTitle: string;
  isUpgradeOffer: boolean;
  productDetails: Offer;
}

const SucceedSummaryView: React.FC<IComponent> = ({
  order,
  userProfile,
  claimEnquiryUuid,
  channel,
  claimId,
  isError,
  productTitle,
  isUpgradeOffer,
  productDetails,
}) => {
  const { t } = useTranslation();

  return (
    <CheckoutLayout
      side={
        userProfile ? (
          <Side
            channel={channel}
            claimId={claimId}
            claimEnquiryUuid={claimEnquiryUuid}
            userProfile={userProfile}
            isUpgradeOffer={isUpgradeOffer}
            productDetails={productDetails}
          />
        ) : null
      }
    >
      <Box maxWidth="586px" mb={4} data-testid="payment-success">
        <Heading variant="h2" mb={8}>
          {t('common.summary')}
        </Heading>

        <AirhelpBrandWithPlanName
          label={t('memberships.twelve_months_coverage')}
          description={productTitle}
        />

        {order && !isError ? (
          <VStack
            mt={8}
            pt={8}
            borderTop="1px solid"
            borderTopColor="greyscale.300"
            spacing={8}
          >
            <LabeledValue
              label={`${t('common.order_number')}:`}
              value={order.identifier}
              data-testid="order_number"
            />
            <LabeledValue
              label={`${t('pricing.duration')}:`}
              value={`12 ${t('common.months')}, ${t('pricing.renews_annually')}`}
              data-testid="duration"
            />
            <LabeledValue
              label={`${productTitle}:`}
              value={formatValueToCurrency(order.totalAmount, order.currency)}
              data-testid="product_amount"
            />
            {order.discountAmount ? (
              <LabeledValue
                label={`${t('pricing.discount')}:`}
                value={formatValueToCurrency(
                  order.discountAmount,
                  order.currency,
                )}
                valueColor="success.500"
                data-testid="discount_amount"
              />
            ) : null}
            <LabeledValue
              label={`${t('pricing.total_paid')}:`}
              labelColor="primary.900"
              value={formatValueToCurrency(order.paidAmount, order.currency)}
              labelBold="medium"
              valueBold="medium"
              data-testid="paid_amount"
            />
          </VStack>
        ) : (
          <ErrorBadge mt={6} />
        )}
      </Box>
    </CheckoutLayout>
  );
};

export default SucceedSummaryView;
