import { LoungeFinderPage } from 'pages';
import * as LoungeFinderPages from 'pages/LoungeFinder';

export const LoungeFinderRoutes = {
  path: 'lounge-finder',
  element: <LoungeFinderPage />,
  children: [
    {
      index: true,
      element: <LoungeFinderPages.IndexPage />,
    },
    {
      path: 'airport/:airport_code',
      element: <LoungeFinderPages.AirportPage />,
    },
    {
      path: 'airport/:airport_code/lounge/:lounge_code',
      element: <LoungeFinderPages.LoungePage />,
    },
  ],
};
