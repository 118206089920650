// TODO: Temporary solution to fix Button as=Link styling.
//       Later on we should think about proper solution
const buttonLinkStyles = {
  _hover: {
    color: 'greyscale.100',
    background: 'primary.600',
  },
  _visited: {
    color: 'greyscale.100',
  },
};

export default { buttonLinkStyles };
