export const MIN_TILE_HEIGHT = '168px';

// to keep consistency with the design, it is better not to change this value to more than 5
export const LIMIT_JOURNEYS_ITEMS_HOME_PAGE = 3;

export const TILE_STYLE = {
  minH: MIN_TILE_HEIGHT,
  background: 'greyscale.100',
  border: '1px solid',
  borderColor: 'greyscale.100',
  borderRadius: 'lg',
  p: 6,
  role: 'group',
};
