import React from 'react';
import { Outlet } from 'react-router-dom';
import { track } from 'react-tracking';
import useProtectedPage from 'hooks/routing/useProtectedPage';
import { usePageTracking } from 'utils/tracking';

const LoungesPage: React.FC = () => {
  usePageTracking();
  useProtectedPage();

  return <Outlet />;
};

export default track({ page_type: 'Lounges' })(LoungesPage);
