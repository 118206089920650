import React, { type PropsWithChildren } from 'react';
import { Flex } from '@chakra-ui/react';

interface IComponent extends PropsWithChildren {
  dataTestId?: string;
}

const PaymentWrapper: React.FC<IComponent> = ({ children, dataTestId }) => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      textAlign="center"
      minHeight={{ base: '450px', lg: 'initial' }}
      me={{ base: 0, xlg: '106px' }}
      data-testid={dataTestId}
    >
      {children}
    </Flex>
  );
};

export default PaymentWrapper;
