import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Box, Container, HStack } from '@chakra-ui/react';
import BackButton from 'components/LoungeFinder/BackButton';
import InfoBox from 'components/LoungeFinder/InfoBox';
import { type AirportLounge } from 'models/AirportLounge';
import LoungeDetails from './LoungeDetails';
import SideNavigation from './SideNavigation';

interface IComponent {
  lounges: AirportLounge[];
  activeLoungeCode: string;
}

const LoungeView: React.FC<IComponent> = ({ lounges, activeLoungeCode }) => {
  const { t } = useTranslation();
  const { search } = useLocation();

  const lounge = lounges.filter(
    (loungeValue) => loungeValue.loungeCode === activeLoungeCode,
  )[0];

  return lounge?.airportCode ? (
    <>
      <Box
        mt={{ base: 4, sm: 4, lg: 8 }}
        mb={{ base: 4, sm: 0 }}
        mx={{ base: 0, md: 16, lg: 'auto' }}
        w={{ base: 'none', md: 'calc(100vw - 128px)', lg: '970px' }}
      >
        <BackButton
          url={`/lounge-finder/airport/${lounge.airportCode}${search}`}
          label={t('lounge_finder.en_go_back_to_lounges')}
        />
      </Box>
      <Container w={{ base: '100%', lg: '970px' }} mx="auto" p="0" maxW="none">
        <HStack
          spacing={{ base: 0, lg: 7 }}
          bg={{ base: 'greyscale.100', sm: 'none' }}
          w={{ base: '100vw', lg: '970px' }}
          mx={{ base: 'auto', md: 'auto' }}
          px={{ base: 4, md: 16, lg: 0 }}
          alignItems="flex-start"
        >
          <SideNavigation
            lounges={lounges}
            activeLoungeCode={activeLoungeCode}
          />
          <LoungeDetails lounge={lounge} />
        </HStack>
      </Container>
    </>
  ) : (
    <InfoBox
      content={t('lounge_finder.en_lounge_not_available_at_location')}
      labelButton={t('lounge_finder.en_go_back_to_search')}
      urlButton="/lounge-finder"
    />
  );
};

export default LoungeView;
