import { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { useClaim } from '@airhelp/webapp/react';
import { Loader } from 'elements';
import { Status } from './Status/Status';

export function ClaimProgressView() {
  const { id } = useParams();
  const { data: claim, isLoading } = useClaim({
    params: { id: Number(id) },
  });

  if (!claim) {
    return null;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <Status />
    </Suspense>
  );
}
