import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, generatePath } from 'react-router-dom';
import { track } from 'react-tracking';
import loungeBuka from 'assets/images/lounges/spot-lounges-buka@2x.png';
import LoungesView from 'components/LoungesView';
import { PAGE_PATHS } from 'config/routes';
import { usePageNavigationHeader } from 'contexts/LayoutContext/LayoutContext';
import { Loader } from 'elements';
import MembershipUpsell from 'elements/MembershipUpsell';
import useFetchLoungePasses from 'hooks/api/userProfiles/useFetchLoungePasses/useFetchLoungePasses';
import useFetchMyProfile from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { usePageTracking } from 'utils/tracking';

const IndexPage = () => {
  usePageTracking();
  const { data: userProfile, isLoading: isUserProfileLoading } =
    useFetchMyProfile();
  const { t } = useTranslation();
  const { isLoading: isLoungePassesLoading, data: loungePassesData } =
    useFetchLoungePasses();

  usePageNavigationHeader({
    title: t('navigation.lounges'),
    backPath: generatePath(PAGE_PATHS.HOME_PAGE),
  });

  if (isUserProfileLoading || isLoungePassesLoading || !userProfile) {
    return <Loader dataTestId="loader" />;
  }

  const upsellEnabled = userProfile.policy.dashboard.upsellEnabled;

  const loungePasses = loungePassesData || [];

  if (
    userProfile.policy.dashboard.loungesPerkEnabled ||
    loungePasses.length > 0
  ) {
    return <LoungesView loungePasses={loungePassesData || []} />;
  }
  if (upsellEnabled) {
    return (
      <MembershipUpsell
        imageUrl={loungeBuka}
        header={t('lounges.upsell.header')}
        description={t('lounges.upsell.description')}
        eventPlacement="lounges page"
      />
    );
  }
  return <Navigate to={PAGE_PATHS.HOME_PAGE} />;
};

export default track({ page_type: 'Lounges.Index' })(IndexPage);
