import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { Box, Button, Heading, Text } from '@chakra-ui/react';
import processingPaymentImage from 'assets/images/processing-payment.jpg';
import { TwoColumnsImageLayout } from 'components/Layouts';
import { PAGE_PATHS } from 'config/routes';

const Component = ({
  handleHomePageAction,
}: {
  handleHomePageAction: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Heading variant="h1">{t('payment.longer_process.title')}</Heading>
      <Text fontSize="lg" fontWeight="medium" mt={3}>
        {t('payment.longer_process.description_p1')}
      </Text>
      <Text fontSize="lg" fontWeight="medium" mt={3}>
        {t('payment.longer_process.description_p2')}
      </Text>
      <Button
        size="m"
        onClick={handleHomePageAction}
        mt={{ base: 6, lg: 12 }}
        width={{ base: '100%', lg: 'initial' }}
        data-testid="home-page-action-button"
      >
        {t('payment.home_page')}
      </Button>
    </Box>
  );
};

const LongerProcess = ({ noLayoutWrap }: { noLayoutWrap?: boolean }) => {
  const navigate = useNavigate();

  const handleHomePageAction = () => {
    navigate(generatePath(PAGE_PATHS.HOME_PAGE));
  };

  if (noLayoutWrap) {
    return <Component handleHomePageAction={handleHomePageAction} />;
  }

  return (
    <TwoColumnsImageLayout imageSrc={processingPaymentImage} noFeaturedImages>
      <Component handleHomePageAction={handleHomePageAction} />
    </TwoColumnsImageLayout>
  );
};

export default LongerProcess;
