import React from 'react';
import { InfoOutline } from '@airhelp/icons';
import {
  type As,
  Tag,
  TagLabel,
  TagLeftIcon,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';

export interface IBoxTag {
  label?: string;
  icon?: As;
  style?: {
    color?: string;
    backgroundColor?: string;
  };
}

const AirBundleTagStatus = ({
  statusBox,
  tooltipLabel,
  dataTestId,
}: {
  statusBox: IBoxTag;
  tooltipLabel?: string;
  dataTestId?: string;
}) => {
  const { isOpen, onOpen, onToggle, onClose } = useDisclosure();

  if (!statusBox) {
    return null;
  }

  return (
    <>
      {statusBox.label ? (
        <Tooltip
          label={tooltipLabel}
          isOpen={isOpen}
          hasArrow
          arrowPadding={6}
          bgColor="greyscale.800"
          borderRadius="md"
          p={4}
          placement="top-start"
        >
          <Tag
            {...statusBox.style}
            size="xs"
            maxWidth="fit-content"
            background="greyscale.100"
            maxHeight={6}
            cursor={tooltipLabel ? 'pointer' : 'inherit'}
            onMouseEnter={onOpen}
            onMouseLeave={onClose}
            onClick={onToggle}
            data-testid={dataTestId}
          >
            {statusBox.icon ? (
              <TagLeftIcon
                boxSize={4}
                color={statusBox?.style?.color || 'primary.400'}
                as={statusBox.icon}
              />
            ) : (
              <TagLeftIcon
                boxSize={4}
                color={statusBox?.style?.color || 'primary.400'}
                as={InfoOutline}
              />
            )}
            <TagLabel
              fontSize="x-small"
              fontWeight="bold"
              textTransform="uppercase"
              color={statusBox?.style?.color || 'primary.400'}
            >
              {statusBox.label}
            </TagLabel>
          </Tag>
        </Tooltip>
      ) : null}
    </>
  );
};

export default AirBundleTagStatus;
