import React, { useContext, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { track } from 'react-tracking';
import { startOfDay } from 'date-fns';
import FlightsDetailsView from 'components/Flights/New/FlightsDetailsView';
import { PAGE_PATHS } from 'config/routes';
import { EditedJourneyContext } from 'contexts';
import { PageLoader } from 'elements';
import useFetchMyProfile from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { type ERROR_CODES } from 'types/api';
import { usePageTracking } from 'utils/tracking';
import { useTrackEvent } from 'utils/tracking/hooks';
import useFetchAirBundleSubscription from 'hooks/api/userProfiles/useFetchAirBundleSubscription';

const FlightsDetailsPage = () => {
  usePageTracking();
  const { trackCtaClick, trackAddFlightFunnel } = useTrackEvent();

  const { saveJourney, isReturnTrip, journey, itineraryIndex, fromRoute } =
    useContext(EditedJourneyContext);
  const { data: userProfile, isLoading: isLoadingUserProfile } =
    useFetchMyProfile();
  const navigate = useNavigate();

  const {
    isLoading: isLoadingAirBundleSubscription,
    data: airBundleSubscription,
  } = useFetchAirBundleSubscription({
    gcTime: 0,
  });

  const [apiErrorCode, setApiErrorCode] = useState<ERROR_CODES | null>(null);
  const [isSavingJourney, setIsSavingJourney] = useState(false);

  if (isLoadingAirBundleSubscription || isLoadingUserProfile) {
    return <PageLoader />;
  }
  const airLuggageAccess = Boolean(airBundleSubscription?.airLuggageAccess);

  const firstFlightLocalDepartureDate =
    journey.itineraries[0].flights[0].localDepartureDate;
  const firstFlightInPast =
    !!firstFlightLocalDepartureDate &&
    firstFlightLocalDepartureDate < startOfDay(new Date());

  const nextStep = async () => {
    if (airLuggageAccess && !firstFlightInPast) {
      if (!isReturnTrip) {
        navigate({
          pathname: generatePath(PAGE_PATHS.FLIGHTS.NEW.RETURN_FLIGHT_PAGE),
          search: `?itineraryIndex=${itineraryIndex}&src=${fromRoute}`,
        });
      } else {
        navigate({
          pathname: generatePath(PAGE_PATHS.FLIGHTS.NEW.AIR_LUGGAGE_PAGE),
          search: `?itineraryIndex=${itineraryIndex}&src=${fromRoute}`,
        });
      }
    } else if (!isReturnTrip) {
      navigate({
        pathname: generatePath(PAGE_PATHS.FLIGHTS.NEW.RETURN_FLIGHT_PAGE),
        search: `?itineraryIndex=${itineraryIndex}&src=${fromRoute}`,
      });
    } else {
      setIsSavingJourney(true);

      const result = await saveJourney();

      if (!result.errors && result.journey) {
        navigate(
          generatePath(PAGE_PATHS.JOURNEY.FLIGHTS_PAGE, {
            id: result.journey.id,
          }),
          {
            state: { showAirPayoutSubscriptionModal: true },
          },
        );

        trackAddFlightFunnel('add this flight');
      } else if (result.errors && result.errorCode) {
        setApiErrorCode(result.errorCode);
      }

      setIsSavingJourney(false);
    }
    trackCtaClick(
      'continue',
      `${itineraryIndex === 0 ? 'outbound' : 'inbound'}  - connecting flight details`,
    );
  };

  const previousStep = () => {
    navigate({
      pathname: generatePath(PAGE_PATHS.FLIGHTS.NEW.CONNECTING_FLIGHTS_PAGE),
      search: `?itineraryIndex=${itineraryIndex}&src=${fromRoute}`,
    });
    trackCtaClick(
      'back',
      `${itineraryIndex === 0 ? 'outbound' : 'inbound'}  - connecting flight details`,
    );
  };

  return (
    <FlightsDetailsView
      onNextStep={nextStep}
      onPreviousStep={previousStep}
      isSavingJourney={isSavingJourney}
      apiErrorCode={apiErrorCode}
      airLuggageAccess={airLuggageAccess}
      userProfile={userProfile}
      firstFlightInPast={firstFlightInPast}
    />
  );
};

export default track({ page_type: 'Flights.New.FlightsDetails' })(
  FlightsDetailsPage,
);
