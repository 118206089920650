import React from 'react';
import { Check, InfoOutline } from '@airhelp/icons';
import {
  Box,
  ListItem,
  Tooltip,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';
import { type Benefit } from './types';

interface IComponent {
  benefit: Benefit;
}

const BenefitItem: React.FC<IComponent> = ({ benefit }) => {
  const { isOpen, onOpen, onToggle, onClose } = useDisclosure();

  return (
    <ListItem display="flex" alignItems="flex-start" color="greyscale.700">
      <Check boxSize={5} me={2} />
      <Box>
        <Box display="inline-block" verticalAlign="baseline">
          {benefit.text}
          {benefit.tip ? (
            <Tooltip
              label={benefit.tip}
              isOpen={isOpen}
              key={`${benefit.text}-tooltip`}
              hasArrow
              arrowPadding={6}
              bgColor="greyscale.800"
              borderRadius="md"
              p={4}
            >
              <InfoOutline
                color="primary.500"
                ms={1}
                mt={0.5}
                boxSize={4}
                h="auto"
                verticalAlign="text-top"
                cursor="pointer"
                onMouseEnter={onOpen}
                onMouseLeave={onClose}
                onClick={onToggle}
              />
            </Tooltip>
          ) : null}
        </Box>
        {benefit.children ? (
          <UnorderedList spacing={1} fontSize="md" listStyleType="disc" mt={2}>
            {benefit.children.map((child) => (
              <ListItem key={child.text} color="greyscale.700">
                {child.text}
              </ListItem>
            ))}
          </UnorderedList>
        ) : null}
      </Box>
    </ListItem>
  );
};

export default BenefitItem;
