import React from 'react';
import { createIcon } from '@chakra-ui/icon';

const AddToHomeScreen = createIcon({
  displayName: 'AddToHomeScreen',
  viewBox: '0 0 24 24',
  path: (
    <>
      <rect opacity="0" width="24" height="24" fill="white" fillOpacity="0" />
      <path
        d="M15.5882 4.00706L8.52941 4C7.75294 4 7.11765 4.63529 7.11765 5.41176V7.5294H8.52941V6.82352H15.5882V16.7058H8.52941V16H7.11765V18.1176C7.11765 18.8941 7.75294 19.5294 8.52941 19.5294H15.5882C16.3647 19.5294 17 18.8941 17 18.1176V5.41176C17 4.63529 16.3647 4.00706 15.5882 4.00706ZM9.94118 13.8823H11.3529V8.94116H6.41176V10.3529H8.94588L5 14.2988L5.99529 15.2941L9.94118 11.3482V13.8823Z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      />
    </>
  ),
});

const BrandIcon = createIcon({
  displayName: 'brandIcon',
  viewBox: '0 0 19 20',
  path: (
    <>
      <rect opacity="0" width="19" height="20" fill="white" fillOpacity="0" />
      <path
        d="M11.8711 15.9238H2.2949C1.94116 15.9243 1.59251 15.8396 1.27846 15.6768C0.964421 15.514 0.694237 15.2779 0.4908 14.9885C0.287362 14.6991 0.156665 14.3649 0.109766 14.0143C0.0628668 13.6637 0.101151 13.3069 0.221379 12.9742L4.31659 1.85499C4.51605 1.31377 4.87583 0.846247 5.34792 0.514818C5.82 0.183389 6.38193 0.00381531 6.95872 6.01202e-05C7.53552 -0.00369507 8.09975 0.168547 8.57611 0.493801C9.05247 0.819055 9.41831 1.28185 9.6248 1.82043L13.9135 12.9397C14.0425 13.2717 14.0885 13.6302 14.0477 13.984C14.0068 14.3378 13.8804 14.6764 13.6792 14.9703C13.478 15.2642 13.2082 15.5047 12.8932 15.6708C12.5781 15.837 12.2273 15.9238 11.8711 15.9238Z"
        fill="#0D73FF"
      />
      <path
        d="M11.8401 4.09253C11.4213 4.09526 11.0084 4.19024 10.6305 4.37073L13.9136 12.9223C14.0426 13.2546 14.0886 13.6133 14.0476 13.9674C14.0066 14.3216 13.8798 14.6603 13.6783 14.9543C13.4767 15.2483 13.2064 15.4887 12.8909 15.6546C12.5754 15.8206 12.2242 15.907 11.8677 15.9064H5.50374L5.0873 17.052C4.96574 17.3846 4.92645 17.7417 4.9728 18.0927C5.01915 18.4438 5.14977 18.7784 5.35349 19.068C5.55721 19.3576 5.82798 19.5936 6.1427 19.7559C6.45742 19.9182 6.80674 20.0019 7.16082 19.9999H16.737C17.0933 20.0003 17.4443 19.9139 17.7596 19.7481C18.0749 19.5823 18.3451 19.3421 18.5466 19.0483C18.7481 18.7545 18.875 18.416 18.9161 18.0621C18.9573 17.7083 18.9116 17.3497 18.7829 17.0175L14.4907 5.89649C14.2764 5.36765 13.9103 4.91411 13.4386 4.59307C12.9669 4.27203 12.4107 4.09786 11.8401 4.09253Z"
        fill="#FF6B73"
      />
      <path
        d="M10.6302 4.37061C9.9596 4.69007 9.4379 5.25572 9.17359 5.94994L5.51208 15.9063H11.8795C12.235 15.9049 12.5848 15.817 12.8987 15.6503C13.2126 15.4836 13.4812 15.2429 13.6814 14.9492C13.8816 14.6555 14.0073 14.3175 14.0477 13.9643C14.0881 13.6112 14.042 13.2535 13.9133 12.9222L10.6302 4.37061Z"
        fill="#0D3073"
      />
    </>
  ),
});

const PlusOutline = createIcon({
  displayName: 'PlusOutline',
  viewBox: '0 0 24 24',
  path: (
    <>
      <rect opacity="0" width="24" height="24" fill="white" fillOpacity="0" />
      <path
        d="M6.00008 4.28574C5.06317 4.28574 4.2858 5.06311 4.2858 6.00002V18C4.2858 18.9369 5.06317 19.7143 6.00008 19.7143H18.0001C18.937 19.7143 19.7144 18.9369 19.7144 18V6.00002C19.7144 5.06311 18.937 4.28574 18.0001 4.28574H6.00008ZM6.00008 6.00002H18.0001V18H6.00008V6.00002ZM11.1429 7.71431V11.1429H7.71437V12.8572H11.1429V16.2857H12.8572V12.8572H16.2858V11.1429H12.8572V7.71431H11.1429Z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      />
    </>
  ),
});

const Share = createIcon({
  displayName: 'Share',
  viewBox: '0 0 24 24',
  path: (
    <>
      <rect opacity="0" width="24" height="24" fill="white" fillOpacity="0" />
      <path
        d="M11.788 1.80001C11.586 1.8032 11.3931 1.88402 11.2503 2.02537L8.13915 5.10228C8.0645 5.17316 8.00491 5.25806 7.96385 5.352C7.9228 5.44595 7.90111 5.54705 7.90005 5.64939C7.899 5.75173 7.91861 5.85324 7.95772 5.94799C7.99683 6.04275 8.05467 6.12883 8.12784 6.20119C8.20101 6.27356 8.28805 6.33076 8.38386 6.36945C8.47966 6.40813 8.58231 6.42752 8.68578 6.42648C8.78926 6.42544 8.89148 6.40399 8.98647 6.36338C9.08146 6.32278 9.1673 6.26384 9.23897 6.19001L11.0224 4.4262V14.1076C11.0209 14.2096 11.04 14.3108 11.0784 14.4054C11.1168 14.5 11.1739 14.5861 11.2463 14.6586C11.3186 14.7312 11.4049 14.7889 11.5 14.8282C11.595 14.8676 11.6971 14.8878 11.8002 14.8878C11.9032 14.8878 12.0053 14.8676 12.1004 14.8282C12.1955 14.7889 12.2817 14.7312 12.3541 14.6586C12.4264 14.5861 12.4835 14.5 12.5219 14.4054C12.5604 14.3108 12.5794 14.2096 12.5779 14.1076V4.4262L14.3614 6.19001C14.433 6.26384 14.5189 6.32278 14.6139 6.36338C14.7089 6.40399 14.8111 6.42544 14.9146 6.42648C15.018 6.42752 15.1207 6.40813 15.2165 6.36945C15.3123 6.33076 15.3993 6.27356 15.4725 6.20119C15.5457 6.12883 15.6035 6.04275 15.6426 5.94799C15.6817 5.85324 15.7013 5.75173 15.7003 5.64939C15.6992 5.54705 15.6775 5.44595 15.6365 5.352C15.5954 5.25806 15.5358 5.17316 15.4612 5.10228L12.3501 2.02537C12.2764 1.95249 12.1887 1.89499 12.0922 1.85628C11.9956 1.81757 11.8922 1.79843 11.788 1.80001ZM7.1335 7.95383C5.85408 7.95383 4.80017 8.99615 4.80017 10.2615V19.4922C4.80017 20.7576 5.85408 21.7999 7.1335 21.7999H16.4668C17.7463 21.7999 18.8002 20.7576 18.8002 19.4922V10.2615C18.8002 8.99615 17.7463 7.95383 16.4668 7.95383H14.9113V9.49228H16.4668C16.9063 9.49228 17.2446 9.82687 17.2446 10.2615V19.4922C17.2446 19.9269 16.9063 20.2615 16.4668 20.2615H7.1335C6.69404 20.2615 6.35573 19.9269 6.35573 19.4922V10.2615C6.35573 9.82687 6.69404 9.49228 7.1335 9.49228H8.68906V7.95383H7.1335Z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      />
    </>
  ),
});

export { AddToHomeScreen, BrandIcon, PlusOutline, Share };
