import type React from 'react';
import {
  type EditableFlight,
  type EditableItinerary,
  type EditableJourney,
  type FellowPassenger,
  type Flight,
} from '@airhelp/plus';
import {
  type GenerateInstance,
  type ReducerAction as JourneyReducerAction,
} from 'types/reducers';
import { updateFlightsDateBoundaries } from 'utils/journey/journey';

export enum EditableTypes {
  UPDATE_NAME = 'UPDATE_NAME',
  UPDATE_FLIGHT = 'UPDATE_FLIGHT',
  UPDATE_FLIGHT_DEPARTURE_DATE = 'UPDATE_FLIGHT_DEPARTURE_DATE',
  UPDATE_CONNECTING_FLIGHT_AIRPORT = 'UPDATE_CONNECTING_FLIGHT_AIRPORT',
  ADD_FLIGHT = 'ADD_FLIGHT',
  ADD_ITINERARY = 'ADD_ITINERARY',
  REMOVE_ITINERARY_BY_INDEX = 'REMOVE_ITINERARY_BY_INDEX',
  REMOVE_FLIGHT = 'REMOVE_FLIGHT',
  REMOVE_ALL_CONNECTING_FLIGHTS = 'REMOVE_ALL_CONNECTING_FLIGHTS',
  CLEAR_EMPTY_FLIGHTS = 'CLEAR_EMPTY_FLIGHTS',
  ADD_FELLOW_PASSENGER = 'ADD_FELLOW_PASSENGER',
  UPDATE_FELLOW_PASSENGER = 'UPDATE_FELLOW_PASSENGER',
  DELETE_FELLOW_PASSENGER = 'DELETE_FELLOW_PASSENGER',
  SET_APPLY_ADD_AIR_LUGGAGE = 'SET_APPLY_ADD_AIR_LUGGAGE',
}

export type Action =
  | JourneyReducerAction<
      EditableTypes.ADD_ITINERARY,
      undefined,
      { itineraryIndex?: number }
    >
  | JourneyReducerAction<
      EditableTypes.SET_APPLY_ADD_AIR_LUGGAGE,
      { applyAirLuggage: boolean },
      { itineraryIndex?: number }
    >
  | JourneyReducerAction<
      EditableTypes.UPDATE_NAME,
      { name?: string | null },
      { itineraryIndex?: number }
    >
  | JourneyReducerAction<
      EditableTypes.UPDATE_FLIGHT,
      EditableFlight,
      { itineraryIndex?: number }
    >
  | JourneyReducerAction<
      EditableTypes.UPDATE_FLIGHT_DEPARTURE_DATE,
      { departureDate: Date; id: number },
      { itineraryIndex?: number }
    >
  | JourneyReducerAction<
      EditableTypes.UPDATE_CONNECTING_FLIGHT_AIRPORT,
      EditableFlight,
      { itineraryIndex?: number }
    >
  | JourneyReducerAction<
      EditableTypes.ADD_FLIGHT,
      undefined,
      { itineraryIndex?: number }
    >
  | JourneyReducerAction<
      EditableTypes.REMOVE_ITINERARY_BY_INDEX,
      undefined,
      { itineraryIndex?: number }
    >
  | JourneyReducerAction<
      EditableTypes.REMOVE_FLIGHT,
      Flight,
      { itineraryIndex?: number }
    >
  | JourneyReducerAction<
      EditableTypes.REMOVE_ALL_CONNECTING_FLIGHTS,
      undefined,
      { itineraryIndex?: number }
    >
  | JourneyReducerAction<
      EditableTypes.CLEAR_EMPTY_FLIGHTS,
      undefined,
      { itineraryIndex?: number }
    >
  | JourneyReducerAction<
      EditableTypes.ADD_FELLOW_PASSENGER,
      FellowPassenger,
      { itineraryIndex?: number }
    >
  | JourneyReducerAction<
      EditableTypes.UPDATE_FELLOW_PASSENGER,
      FellowPassenger,
      { itineraryIndex?: number }
    >
  | JourneyReducerAction<
      EditableTypes.DELETE_FELLOW_PASSENGER,
      FellowPassenger,
      { itineraryIndex?: number }
    >;

const generateTempId = () => Math.floor(Math.random() * Date.now());
const getFlightIndex = (flights, action) =>
  flights.findIndex((f) => f.id === action.payload.id) as number;

const generateFlight: GenerateInstance<EditableFlight> = (
  minDate: string | null = null,
) => ({
  id: generateTempId(),
  departureAirport: null,
  departureAirportCode: null,
  arrivalAirport: null,
  arrivalAirportCode: null,
  airline: null,
  airlineIdentifier: null,
  flightNumber: null,
  localDepartureDate: null,
  metadata: {
    minDate,
    maxDate: null,
  },
});

const generateItinerary: GenerateInstance<EditableItinerary> = () => ({
  id: generateTempId(),
  flights: [generateFlight()],
  fellowPassengers: [],
});

export const generateEmptyJourney: GenerateInstance<EditableJourney> = () => ({
  id: generateTempId(),
  name: '',
  itineraries: [generateItinerary()],
  applyAirLuggage: false,
});

export const editableJourneyReducer: React.Reducer<EditableJourney, Action> = (
  journey,
  action,
) => {
  const itineraryIndex = action?.itineraryIndex || 0;
  const { itineraries } = journey;
  const itinerary = itineraries[itineraryIndex];

  const { flights, fellowPassengers } = itinerary;

  const handleAction = (acc: Action) => {
    switch (acc.type) {
      case EditableTypes.ADD_ITINERARY: {
        const isReturnFlight = flights.length > 0;

        if (isReturnFlight) {
          const lastFlightIndex = itineraries[0].flights.length - 1;
          const minFlightDate =
            itineraries[0].flights[lastFlightIndex].localDepartureDate;

          const newItinerary = {
            id: generateTempId(),
            flights: [generateFlight(minFlightDate)],
            fellowPassengers: [],
          };

          const newItineraries = [...itineraries, newItinerary];
          return {
            ...journey,
            itineraries: newItineraries,
          };
        }
        const newItineraries = [...itineraries, generateItinerary()];
        return {
          ...journey,
          itineraries: newItineraries,
        };
      }
      case EditableTypes.SET_APPLY_ADD_AIR_LUGGAGE: {
        const { applyAirLuggage } = acc.payload;

        return {
          ...journey,
          applyAirLuggage,
        };
      }
      case EditableTypes.UPDATE_NAME: {
        const { name } = acc.payload;

        return {
          ...journey,
          name,
        };
      }
      case EditableTypes.UPDATE_FLIGHT: {
        const newFlights = [...flights];
        newFlights[getFlightIndex(flights, action)] = acc.payload;

        const newItineraries = [...itineraries];
        newItineraries[itineraryIndex].flights = newFlights;

        return {
          ...journey,
          itineraries: newItineraries,
        };
      }
      case EditableTypes.UPDATE_FLIGHT_DEPARTURE_DATE: {
        const newFlights = [...flights];
        const flightIndex = getFlightIndex(flights, action);

        updateFlightsDateBoundaries(
          flightIndex,
          acc.payload.departureDate,
          newFlights,
        );

        newFlights[flightIndex].localDepartureDate = acc.payload.departureDate;
        const newItineraries = [...itineraries];
        newItineraries[itineraryIndex].flights = newFlights;

        return {
          ...journey,
          itineraries: newItineraries,
        };
      }
      case EditableTypes.UPDATE_CONNECTING_FLIGHT_AIRPORT: {
        const newFlights = [...flights];

        const updatedFlight = acc.payload;
        const updatedFlightIndex = getFlightIndex(flights, action);

        newFlights[updatedFlightIndex] = updatedFlight;

        const prevFlightIndex = updatedFlightIndex - 1;

        newFlights[prevFlightIndex] = {
          ...newFlights[prevFlightIndex],
          arrivalAirport: updatedFlight.departureAirport,
          arrivalAirportCode: updatedFlight.departureAirportCode,
        };

        const newItineraries = [...itineraries];
        newItineraries[itineraryIndex].flights = newFlights;

        return {
          ...journey,
          itineraries: newItineraries,
        };
      }
      case EditableTypes.ADD_FLIGHT: {
        const { itineraries: prevItineraries } = journey;

        return {
          ...journey,
          itineraries: prevItineraries.map((prevItinerary, index) => {
            const prevFlights = prevItinerary.flights;

            let newFlights = prevFlights.map((flight) => ({ ...flight }));

            if (index === itineraryIndex) {
              const lastFlight = newFlights.pop() as EditableFlight;

              const addedFlight = {
                ...generateFlight(),
                arrivalAirport: lastFlight?.arrivalAirport,
                arrivalAirportCode: lastFlight?.arrivalAirportCode,
              };

              const updatedLastFlight = {
                ...lastFlight,
                arrivalAirport: null,
                arrivalAirportCode: null,
              };

              newFlights = [...newFlights, updatedLastFlight, addedFlight];
            }

            return {
              ...prevItinerary,
              flights: newFlights,
            };
          }),
        };
      }
      case EditableTypes.REMOVE_ITINERARY_BY_INDEX: {
        const removedItineraryIndex = action.itineraryIndex;
        const newItineraries = [...itineraries];

        if (removedItineraryIndex !== undefined) {
          newItineraries.splice(removedItineraryIndex, 1);
        }

        return {
          ...journey,
          itineraries: newItineraries,
        };
      }
      case EditableTypes.REMOVE_FLIGHT: {
        const removedFlightIndex = getFlightIndex(flights, action);
        const removedFlight = acc.payload;
        const precedingFlights = [...flights.slice(0, removedFlightIndex)];
        const followingFlights = [...flights.slice(removedFlightIndex + 1)];

        let lastPrecedingFlight = precedingFlights.pop() as EditableFlight;

        lastPrecedingFlight = {
          ...lastPrecedingFlight,
          arrivalAirport:
            followingFlights[0]?.departureAirport ||
            removedFlight.arrivalAirport,
        };

        const newItineraries = [...itineraries];
        const newFlights = [
          ...precedingFlights,
          lastPrecedingFlight,
          ...followingFlights,
        ];

        newItineraries[itineraryIndex].flights = newFlights;

        return {
          ...journey,
          itineraries: newItineraries,
        };
      }
      case EditableTypes.REMOVE_ALL_CONNECTING_FLIGHTS: {
        const firstFlight = flights[0];
        const prevLastFlight = flights[flights.length - 1];

        const newFlights = [
          {
            ...firstFlight,
            arrivalAirport: prevLastFlight?.arrivalAirport,
            arrivalAirportCode: prevLastFlight?.arrivalAirportCode,
          },
        ];

        const newItineraries = [...itineraries];
        newItineraries[itineraryIndex].flights = newFlights;

        return {
          ...journey,
          itineraries: newItineraries,
        };
      }
      case EditableTypes.CLEAR_EMPTY_FLIGHTS: {
        const filteredFlights = flights.filter((flight) =>
          Boolean(flight.departureAirport),
        );

        if (filteredFlights.length === flights.length || flights.length === 1) {
          return {
            ...journey,
          };
        }
        const prevLastFlight = flights[flights.length - 1];
        const newFlights = filteredFlights.map((flight, index) => ({
          ...flight,
          arrivalAirport:
            filteredFlights[index + 1]?.departureAirport ||
            prevLastFlight.arrivalAirport,
          arrivalAirportCode:
            filteredFlights[index + 1]?.departureAirportCode ||
            prevLastFlight.arrivalAirportCode,
        }));

        const newItineraries = [...itineraries];
        newItineraries[itineraryIndex].flights = newFlights;

        return {
          ...journey,
          itineraries: newItineraries,
        };
      }
      case EditableTypes.ADD_FELLOW_PASSENGER: {
        const addedFellowPassenger = acc.payload;

        return {
          ...journey,
          itineraries: itineraries.map((itineraryItem, index) => {
            const isEditedItinerary = itineraryIndex === index;

            return {
              ...itineraryItem,
              fellowPassengers: isEditedItinerary
                ? [addedFellowPassenger, ...itineraryItem.fellowPassengers]
                : [...itineraryItem.fellowPassengers],
            };
          }),
        };
      }
      case EditableTypes.UPDATE_FELLOW_PASSENGER: {
        const editedFellowPassenger = acc.payload;
        const fellowPassenger = fellowPassengers.find(
          (passenger) => passenger.id === editedFellowPassenger.id,
        );
        const newItineraries = [...itineraries];

        if (!fellowPassenger) {
          newItineraries[itineraryIndex].fellowPassengers = [
            ...fellowPassengers,
          ];

          return {
            ...journey,
            itineraries: newItineraries,
          };
        }

        const updatedFellowPassenger = {
          ...fellowPassenger,
          ...editedFellowPassenger,
        };

        const fellowPassengersWithoutEdited = fellowPassengers.filter(
          (passenger) => passenger.id !== editedFellowPassenger.id,
        );

        newItineraries[itineraryIndex].fellowPassengers = [
          ...fellowPassengersWithoutEdited,
          updatedFellowPassenger,
        ];

        return {
          ...journey,
          itineraries: newItineraries,
        };
      }
      case EditableTypes.DELETE_FELLOW_PASSENGER: {
        const deletedFellowPassenger = acc.payload;
        const newFellowPassengersAfterDeleted = fellowPassengers.filter(
          (passenger) => passenger.id !== deletedFellowPassenger.id,
        );

        const newItineraries = [...itineraries];
        newItineraries[itineraryIndex].fellowPassengers =
          newFellowPassengersAfterDeleted;

        return {
          ...journey,
          itineraries: newItineraries,
        };
      }

      default:
        throw new Error(
          `Action of type "${action.type}" is missing or is not a function`,
        );
    }
  };

  return handleAction(action);
};
