import config from 'config';

export const airLuggagePolicy = (ipid: string) => {
  return `${config.airLuggageIpidVersion + ipid}.pdf`;
};

export const airPayoutPolicy = (ipid: string) => {
  return `${config.airPayoutIpidVersion + ipid}.pdf`;
};

export const policyUrl = (isAirPayout: boolean, ipidVersion: string) => {
  if (isAirPayout) {
    return `${config.airPayoutIpidVersion + ipidVersion}.pdf`;
  }
  return `${config.airLuggageIpidVersion + ipidVersion}.pdf`;
};
