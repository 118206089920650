import React from 'react';
import { generatePath, Navigate, useSearchParams } from 'react-router-dom';
import { track } from 'react-tracking';
import Succeeded from 'components/ProcessingPaymentView/Succeeded';
import { PAGE_PATHS } from 'config/routes';
import { PageLoader } from 'elements';
import useFetchMyProfile from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { type Offer } from 'models/Offers';
import {
  usePageTracking,
  useTrackCheckout,
  useTrackOnce,
} from 'utils/tracking/hooks';
import { useComputedOffers } from 'hooks/api/offers/useComputedOffers';
import { CommonParams, castBooleanParam, castStringParam } from 'utils/params';

const PaymentSucceededPage = () => {
  usePageTracking();

  const [searchParams] = useSearchParams();
  const { data: userProfile } = useFetchMyProfile();

  const {
    isLoading: isLoadingOffers,
    isError: isErrorOffers,
    data,
  } = useComputedOffers({
    enabled: Boolean(userProfile),
    params: {
      renew: castBooleanParam(searchParams, CommonParams.RENEW),
      upgrade: castBooleanParam(searchParams, CommonParams.UPGRADE),
      channel: castStringParam(searchParams, CommonParams.CHANNEL),
    },
  });

  const paymentIntentId = searchParams.get('payment_intent') as string;

  const { trackSubscriptionFunnel } = useTrackCheckout();

  useTrackOnce(
    (setTracked) => {
      if (paymentIntentId) {
        trackSubscriptionFunnel('Succeed');
        setTracked();
      }
    },
    [paymentIntentId, trackSubscriptionFunnel],
  );

  if (isLoadingOffers) {
    return <PageLoader />;
  }

  if (isErrorOffers) {
    return <Navigate to={generatePath(PAGE_PATHS.ERROR_PAGE)} />;
  }

  if (!data) {
    return null;
  }

  const offers = data.offers;
  const renewOffer = data.renewOffer as Offer;
  const upgradeOffer = data.upgradeOffer as Offer;

  return paymentIntentId ? (
    <Succeeded
      offers={offers}
      renewOffer={renewOffer}
      upgradeOffer={upgradeOffer}
    />
  ) : (
    <Navigate to={{ pathname: generatePath(PAGE_PATHS.HOME_PAGE) }} />
  );
};

export default track({ page_type: 'Membership.Buy.PaymentSucceeded' })(
  PaymentSucceededPage,
);
