import { capitalize } from 'lodash';
import type { UserProfile } from 'models/userProfile/UserProfile';

const getProductVariant = (userProfile: UserProfile) => {
  const policy = userProfile.policy;

  if (policy.membership?.active) {
    return 'Subscription';
  } else if (policy.trip?.active) {
    return 'Trip';
  }

  return '';
};

const getProductTier = (userProfile: UserProfile) => {
  const policy = userProfile.policy;

  if (policy.membership) {
    return capitalize(policy.membership.tier);
  } else if (policy?.trip?.tier) {
    return capitalize(policy.trip.tier);
  }

  return '';
};

export { getProductVariant, getProductTier };
