import { MembershipsPage } from 'pages';
import * as MembershipsPages from 'pages/Memberships';
import * as MembershipsBuyPages from 'pages/Memberships/Buy';

export const MembershipRoutes = {
  path: 'memberships',
  element: <MembershipsPage />,
  children: [
    {
      path: 'buy',
      element: <MembershipsPages.BuyPage />,
      children: [
        {
          index: true,
          element: <MembershipsBuyPages.IndexPage />,
        },
        {
          path: 'email',
          element: <MembershipsBuyPages.EmailPage />,
        },
        {
          path: 'checkout',
          element: <MembershipsBuyPages.CheckoutPage />,
        },
        {
          path: 'processing-payment',
          element: <MembershipsBuyPages.ProcessingPaymentPage />,
        },
        {
          path: 'payment-succeeded',
          element: <MembershipsBuyPages.PaymentSucceededPage />,
        },
      ],
    },
  ],
};
