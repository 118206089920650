import {
  Box,
  Flex,
  StackDivider,
  Text,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { type PropsWithChildren } from 'react';

export function Root({
  children,
  title,
}: PropsWithChildren<{ title: string }>) {
  return (
    <Box
      as="section"
      rounded="lg"
      backgroundColor="greyscale.100"
      p={{
        base: 4,
        md: 6,
      }}
    >
      <Text
        as="header"
        mb={4}
        color="primary.900"
        fontSize="lg"
        fontWeight="medium"
      >
        {title}
      </Text>
      {children}
    </Box>
  );
}

interface DetailsRowProps {
  label: string;
  value: string;
  onValueClick?: () => void;
}

export function Row({ label, value, onValueClick }: DetailsRowProps) {
  const ValueComponent = onValueClick ? (
    <Text
      as="button"
      fontWeight="medium"
      fontSize="sm"
      color="primary.500"
      cursor="pointer"
      onClick={onValueClick}
    >
      {value}
    </Text>
  ) : (
    <Text fontSize="sm" color="primary.900" fontWeight="medium">
      {value}
    </Text>
  );

  return (
    <Flex w="100%" justifyContent="space-between">
      <Text color="greyscale.600" fontSize="sm">
        {label}
      </Text>
      {ValueComponent}
    </Flex>
  );
}

export function DataGroup({
  children,
}: {
  children:
    | React.ReactElement<DetailsRowProps>
    | React.ReactElement<DetailsRowProps>[];
}) {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <VStack
      align="stretch"
      gap={{
        base: 4,
        md: 2,
      }}
      divider={
        isMobile ? undefined : <StackDivider borderColor="greyscale.300" />
      }
    >
      {children}
    </VStack>
  );
}
