import React from 'react';
import { OpenInNew } from '@airhelp/icons';
import { Button } from '@chakra-ui/react';

interface IComponent {
  onClick: () => void;
  label: string;
}

const GoToSiteButton = ({ onClick, label }: IComponent) => {
  return (
    <Button
      width="100%"
      size="md"
      onClick={onClick}
      rightIcon={<OpenInNew boxSize={6} />}
    >
      {label}
    </Button>
  );
};

export default GoToSiteButton;
