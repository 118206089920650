import config from 'config';
import { getDomainSuffix, isDomainSupported } from './domains';

function createCookieOptions(
  options = {},
  origin: string | undefined = undefined,
) {
  let cookieDomain = config.cookieOptions.domain;

  if (
    cookieDomain.endsWith('.airhelp.com') ||
    cookieDomain.endsWith('.airhelp-test.com')
  ) {
    const regionalSuffix = getDomainSuffix(
      origin ? origin : window.location.origin,
    );

    if (regionalSuffix && isDomainSupported(regionalSuffix)) {
      cookieDomain = cookieDomain.replace('.com', `.${regionalSuffix}`);
    }
  }

  return {
    ...config.cookieOptions,
    domain: cookieDomain,
    ...options,
  };
}

export { createCookieOptions };
