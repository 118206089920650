import React, { type ChangeEvent } from 'react';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { Input } from '@airhelp/react';
import { Box } from '@chakra-ui/react';
import { type TFunction } from 'i18next';
import paymentsFieldsConfig from 'utils/airBundle/paymentsFieldsConfig';
import { removeWhitespaces, validateIban } from 'utils/validations';

const DEFAULT_MAX_LENGTH_IBAN = 34;
const DEFAULT_MIN_LENGTH_IBAN = 0;

const removeWhitespceOnChange = (
  event: ChangeEvent<HTMLInputElement>,
  onChange: (value: string) => void,
) => {
  const value = removeWhitespaces(event?.target.value);
  value ? onChange(value) : onChange('');
};

const iban = ({
  form,
  t,
  countryCode,
}: {
  form: UseFormReturn;
  t: TFunction;
  countryCode: string;
}) => {
  const IBAN_MAX_LENGTH =
    paymentsFieldsConfig[countryCode]?.rules?.iban?.maxLength ||
    DEFAULT_MAX_LENGTH_IBAN;

  const IBAN_MIN_LENGTH =
    paymentsFieldsConfig[countryCode]?.rules?.iban?.minLength ||
    DEFAULT_MIN_LENGTH_IBAN;

  const ibanCountryCode =
    paymentsFieldsConfig[countryCode]?.ibanCountryCode || countryCode;

  return (
    <Controller
      name="iban"
      control={form.control}
      rules={{
        required: { value: true, message: t('errors.required') },
        validate: (value: string) => validateIban(value, t, ibanCountryCode),
        maxLength: {
          value: IBAN_MAX_LENGTH,
          message: t('errors.too_long', {
            attribute: t('common.en_iban'),
            max: IBAN_MAX_LENGTH,
          }),
        },
        minLength: {
          value: IBAN_MIN_LENGTH,
          message: t('errors.too_short', {
            attribute: t('common.en_iban'),
            min: IBAN_MIN_LENGTH,
          }),
        },
      }}
      defaultValue=""
      render={({
        field: { onChange, name, value },
        fieldState: { invalid, error },
      }) => (
        <Box width="100%" minHeight="106px">
          <Input
            isInvalid={invalid}
            errorMessage={error?.message || ''}
            name={name}
            label={t('common.en_iban')}
            value={value}
            placeholder={t('common.en_iban')}
            onChange={(event) => {
              removeWhitespceOnChange(event, onChange);
            }}
            data-testid="iban-number"
          />
        </Box>
      )}
    />
  );
};

export default iban;
