import { HStack, Text, Flex, Tag, TagLabel } from '@chakra-ui/react';

interface ChartLegendProps {
  freeOfCharge: boolean;
  eligibleForCompensation: boolean;
  originalCompensation: string;
  serviceFee: string;
  legalActionFee: string;
  perPassenger: string;
  totalCompensation: string;
}

interface LegendItemProps {
  freeOfCharge: boolean;
  label: string;
  value: string;
  identifierColor?: string;
}

export function ChartLegend({
  eligibleForCompensation,
  originalCompensation,
  serviceFee,
  legalActionFee,
  perPassenger,
  freeOfCharge,
  totalCompensation,
}: ChartLegendProps) {
  return (
    <Flex
      p={5}
      backgroundColor="greyscale.200"
      rounded="lg"
      gap={5}
      flexDirection="column"
      w="100%"
    >
      <HStack alignItems="start" justifyContent="space-between">
        <Text fontSize="sm" color="greyscale.600">
          Flight compensation
        </Text>
        <Text fontSize="sm" color="greyscale.600">
          {originalCompensation}
        </Text>
      </HStack>

      <LegendItem
        freeOfCharge={freeOfCharge}
        value={serviceFee}
        identifierColor={
          eligibleForCompensation ? 'primary.500' : 'greyscale.400'
        }
        label="Service fee"
      />

      <LegendItem
        freeOfCharge={freeOfCharge}
        value={legalActionFee}
        identifierColor={
          eligibleForCompensation ? 'primary.900' : 'greyscale.400'
        }
        label="Legal action fee"
      />

      <hr />
      <LegendItem
        freeOfCharge={false}
        value={totalCompensation}
        identifierColor={
          eligibleForCompensation ? 'success.400' : 'greyscale.400'
        }
        label="Total compensation"
      />

      <LegendItem
        freeOfCharge={false}
        label="Per passenger"
        value={perPassenger}
        identifierColor="greyscale.200"
      />
    </Flex>
  );
}

function LegendItem({
  freeOfCharge,
  label,
  value,
  identifierColor,
}: LegendItemProps) {
  return (
    <HStack alignItems="start" justifyContent="space-between">
      <Text
        fontSize="sm"
        color="greyscale.600"
        _before={
          identifierColor
            ? {
                content: '""',
                display: 'block',
                width: '16px',
                height: '16px',
                borderRadius: 'sm',
                backgroundColor: identifierColor,
                me: 2,
              }
            : undefined
        }
        display="flex"
        alignItems="center"
      >
        {label}
      </Text>
      {freeOfCharge ? (
        <Tag colorScheme="success" size="xs">
          <TagLabel>NO FEES</TagLabel>
        </Tag>
      ) : (
        <Text fontSize="sm" color="greyscale.600">
          {value}
        </Text>
      )}
    </HStack>
  );
}
