import { createEnv } from '@t3-oss/env-core';
import { z } from 'zod';

const booleanString = z.preprocess((val) => {
  return typeof val === 'boolean' ? val : val === 'true';
}, z.boolean());

function createProxiableUrlSchema(isProxyEnabled: boolean) {
  return isProxyEnabled ? z.string().min(1) : z.string().url();
}

function createProxyTargetUrlSchema(isProxyEnabled: boolean) {
  return isProxyEnabled ? z.string().url() : z.string().optional().default('');
}

export function parseEnv(env: Record<string, string>) {
  const isProxyEnabled = env.VITE_ENABLE_PROXYING === 'true';
  const proxiableUrl = createProxiableUrlSchema(isProxyEnabled);
  const proxyTargetUrl = createProxyTargetUrlSchema(isProxyEnabled);

  return createEnv({
    clientPrefix: 'VITE_',
    runtimeEnv: env,
    emptyStringAsUndefined: true,
    server: {
      NODE_ENV: z.string().optional().default('development'),
      SERVER_HOST: z.string().min(1).optional().default('127.0.0.1'),
      SERVER_PORT: z.string().optional().default('2301').transform(Number),
      PROXY_WEBAPP_BACKEND_URL: proxyTargetUrl,
      PROXY_AUTH_URL: proxyTargetUrl,
      PROXY_AHPLUS_API_URL: proxyTargetUrl,
      SENTRY_AUTH_TOKEN: z.string().optional().default(''),
    },
    client: {
      VITE_BASE_URL: z.string().default(''),
      VITE_ENABLE_PROXYING: booleanString.optional().default(false),
      VITE_CODE_VERSION: z.string().min(1).optional().default('local'),
      VITE_SENTRY_DSN_TOKEN: z
        .string()
        .min(1)
        .optional()
        .default('default-token'),
      VITE_AUTH_URL: proxiableUrl,
      VITE_AHPLUS_API_URL: proxiableUrl,
      VITE_WEBAPP_BACKEND_URL: proxiableUrl,
      VITE_WEBAPP_FRONTEND_URL: z.string().url(),
      VITE_CONTACT_URL: z.string().url(),
      VITE_HELP_URL: z.string().url(),
      VITE_PRICING_URL: z.string().url(),
      VITE_WEBSITE_URL: z.string().url(),
      VITE_AUTH_API_VERSION: z.string().min(1).optional().default(''),
      VITE_COOKIE_DOMAIN: z.string().min(1).optional().default('.airhelp.com'),
      VITE_GTM_ID: z.string().optional().default(''),
      VITE_OPTIMIZELY_ENABLED: booleanString,
      VITE_OPTIMIZELY_LOGS: booleanString,
      VITE_TRACKING_LOG_EVENTS: booleanString,
      VITE_ZOWIE_ENABLED: booleanString,
      VITE_ZOWIE_URL: z.string().min(1).optional().default(''),
      VITE_ZOWIE_AIRHELP_INSTANCEID: z.string().optional().default(''),
      VITE_STRIPE_PUBLISHABLE_KEY: z.string().optional().default(''),
      VITE_STRIPE_ACCOUNT_ID: z.string().optional().default(''),
      VITE_SUPPORTED_REGIONAL_DOMAINS: z
        .string()
        .transform((val) => val.split(',')),
      VITE_TRUSTPILOT_API_KEY: z.string().optional().default(''),
      VITE_TURNSTILE_SITE_KEY: z.string().optional().default(''),
      VITE_FEATURES_CLAIM_DETAILS: booleanString,
    },
  });
}

export type Env = ReturnType<typeof parseEnv>;
