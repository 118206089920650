import React from 'react';
import { useTranslation } from 'react-i18next';
import perkLogo from 'assets/images/perks/logo-rentalcars.svg';
import { useTrackEvent } from 'utils/tracking/hooks';
import PerkDrawerContent from '../PerkDrawerContent';

interface IComponent {
  code: string;
  discount: number;
  imageText: string;
  imageSrcMobile: string;
}

const TRACKING_PROVIDER_NAME = 'rentalcars';

const CarRental: React.FC<IComponent> = ({
  code,
  discount,
  imageText,
  imageSrcMobile,
}) => {
  const { t } = useTranslation();
  const { trackPageInteractions } = useTrackEvent();

  const benefits = [
    {
      content: t('dashboard.perks.car_rentals.free_cancellation_on_bookings'),
      key: 'free_cancellation',
    },
    {
      content: t('dashboard.perks.car_rentals.customer_support_in_languages'),
      key: 'customer_support',
    },
    {
      content: t('dashboard.perks.car_rentals.multiple_locations'),
      key: 'locations',
    },
    { content: t('dashboard.perks.car_rentals.no_fees'), key: 'no_fees' },
    {
      content: t('dashboard.perks.car_rentals.price_guarantee'),
      key: 'price_gurantee',
    },
    {
      content: t('dashboard.perks.car_rentals.flexible_rentals'),
      key: 'flexible_rentals',
    },
  ];

  const siteUrl =
    'https://www.rentalcars.com/?affiliateCode=airhelpge667&enabler=pbx1';

  const goToSite = () => {
    trackPageInteractions(
      'go to site',
      TRACKING_PROVIDER_NAME,
      'travel service banner',
    );

    window.open(siteUrl, '_blank');
  };

  const onCodeCopied = () => {
    trackPageInteractions(
      'promo code copy',
      TRACKING_PROVIDER_NAME,
      'travel service banner',
    );
  };

  return (
    <PerkDrawerContent
      heading={imageText}
      imageSrcMobile={imageSrcMobile}
      perkLogo={perkLogo}
      discount={discount}
      subHeading="dashboard.perks.car_rentals.percentage_off_your_car_rental"
      descriptionPrimary={t(
        'dashboard.perks.car_rentals.cancel_or_change_bookings',
        {
          discount,
        },
      )}
      descriptionSecondary={t(
        'dashboard.perks.car_rentals.find_perfect_wheels',
      )}
      benefits={benefits}
      code={code}
      onCodeCopied={onCodeCopied}
      promoCodeText={t('dashboard.perks.use_it_on', { url: 'rentalcars.com' })}
      goToSite={goToSite}
      buttonLabel={t('dashboard.perks.car_rentals.get_car_rental')}
    />
  );
};

export default CarRental;
