import { useTranslation, Trans } from 'react-i18next';
import { Flex, Text } from '@chakra-ui/react';
import { MoneyProfit, Luggage, Lounge } from '@airhelp/icons';
import { BenefitItem } from './BenefitItem';

interface TripProtectionProps {
  lostBagProtectionEnabled: boolean;
  loungeEnabled: boolean;
}
export function TripProtection({
  lostBagProtectionEnabled,
  loungeEnabled,
}: TripProtectionProps) {
  const { t } = useTranslation();

  return (
    <Flex direction="column" gap={4} pt={6}>
      <Text fontSize="md" color="primary.900" fontWeight="medium">
        <Trans
          i18nKey="special_offer.trip_protection.title"
          components={{
            span: (
              <Text
                as="span"
                fontSize="md"
                color="success.600"
                fontWeight="medium"
              />
            ),
          }}
        />
      </Text>
      <BenefitItem
        Icon={MoneyProfit}
        text={t('special_offer.trip_protection.for_disrupted_flight', {
          amount: 100,
        })}
      />
      <BenefitItem
        Icon={Luggage}
        text={t('special_offer.trip_protection.for_lost_bag', {
          amount: 100,
        })}
        variant={lostBagProtectionEnabled ? 'primary' : 'disabled'}
      />
      <BenefitItem
        Icon={Lounge}
        text={t('special_offer.trip_protection.comfy_lounge')}
        variant={loungeEnabled ? 'primary' : 'disabled'}
      />
    </Flex>
  );
}
