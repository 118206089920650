import React from 'react';
import { track } from 'react-tracking';
import { generatePath, Navigate } from 'react-router-dom';
import SetPasswordView from 'components/SetPasswordView';
import { usePageTracking } from 'utils/tracking';
import { PAGE_PATHS } from 'config/routes';

const SetPasswordPage: React.FC = () => {
  usePageTracking();

  const token = new URLSearchParams(location.search).get('token') as string;

  if (!token) {
    return <Navigate to={{ pathname: generatePath(PAGE_PATHS.HOME_PAGE) }} />;
  }

  return <SetPasswordView />;
};

export default track({ page_type: 'SetPassword' })(SetPasswordPage);
