import {
  fetchAirPayoutJourneys,
  type FetchAirPayoutJourneysResponse,
} from '@airhelp/plus';
import { type ApiQueryOptions } from 'hooks/api/queryHelper';
import { useProtectedQuery } from 'hooks/api/useProtectedQuery';
import { ahplusClient } from 'api/clients/clients';
import { transformToAirPayoutJourneyClass } from 'models/journey/AirPayoutJourney';

const useFetchAirPayoutJourney = ({
  journeyId,
  options,
}: {
  journeyId: number;
  options?: ApiQueryOptions<FetchAirPayoutJourneysResponse>;
}) => {
  return useProtectedQuery({
    queryKey: ['journey', 'air-payouts', { journeyId }],
    queryFn: () =>
      fetchAirPayoutJourneys({ client: ahplusClient, params: { journeyId } }),
    select: (data) =>
      data.map((airPayoutJourney) =>
        transformToAirPayoutJourneyClass(airPayoutJourney),
      ),
    ...options,
  });
};

export default useFetchAirPayoutJourney;
