import {
  AirLuggageStatus,
  type Itinerary,
  type AirLuggageJourney as IFetchAirLuggageJourneysResponse,
} from '@airhelp/plus';
import { Expose, plainToClass } from 'class-transformer';

export class AirLuggageJourney implements IFetchAirLuggageJourneysResponse {
  id: number;
  status: AirLuggageStatus;
  journeyId: number;
  availableItineraries: Itinerary[];
  payoutAllowed: boolean;
  payoutUpdateAllowed: boolean;
  name: string;
  payoutRequested: boolean;
  payoutAmount: number;
  maxBags: number;
  ipidVersion: string;
  transactionReference: string;

  @Expose()
  get showNotification(): boolean {
    return (
      this.status === AirLuggageStatus.ELIGIBLE ||
      this.status === AirLuggageStatus.PAYOUT_FAILED ||
      this.status === AirLuggageStatus.PAYOUT_RETURNED
    );
  }
}

export function transformToAirLuggageJourneyClass(
  airLuggageData: IFetchAirLuggageJourneysResponse,
): AirLuggageJourney {
  return plainToClass(AirLuggageJourney, airLuggageData);
}
