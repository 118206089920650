import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateConsents, type Consents } from '@airhelp/plus';
import { transformToConsentsClass } from 'models/userProfile/Consents';
import { ahplusClient } from 'api/clients/clients';
import { useMyProfile } from '../useFetchMyProfile/useFetchMyProfile';

const useUpdateConsents = () => {
  const queryClient = useQueryClient();
  const { userProfile } = useMyProfile();

  const updateConsentsMutation = useMutation({
    mutationFn: (params: Consents) =>
      updateConsents({
        client: ahplusClient,
        params,
      }),
    onSuccess: (data: { consents: Consents }) => {
      queryClient.setQueryData(['userProfile', 'consents'], data);

      const updatedConsents = transformToConsentsClass(data.consents);

      if (userProfile) {
        userProfile.consents.marketingConsent =
          updatedConsents.marketingConsent;
      }
    },
  });

  return updateConsentsMutation;
};

export default useUpdateConsents;
