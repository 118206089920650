import React from 'react';
import { useTranslation } from 'react-i18next';
import { Info } from '@airhelp/icons';
import {
  Modal,
  ModalOverlay,
  ModalBody,
  Button,
  ModalCloseButton,
  ModalContent,
  VStack,
  Box,
  IconButton,
  Heading,
  Text,
  Link,
} from '@chakra-ui/react';
import { ErrorBadge } from 'elements';
import { LinkTarget } from 'types/utils';

interface IComponent {
  handleDeleteClaimEnquiry: () => void;
  onCloseClick: () => void;
  isOpen: boolean;
  isLoading: boolean;
  isError: boolean;
  externalLink: string;
}

const DeleteModal: React.FC<IComponent> = ({
  isOpen,
  isLoading,
  isError,
  onCloseClick,
  handleDeleteClaimEnquiry,
  externalLink,
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onCloseClick} size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton
          borderRadius="sm"
          boxSize={2}
          top={{ base: 4, md: 6 }}
          right={{ base: 4, md: 6 }}
          backgroundColor="greyscale.300"
          sx={{
            svg: { boxSize: 2.5 },
            _hover: { backgroundColor: 'primary.200' },
            _active: {
              backgroundColor: 'primary.800',
              color: 'greyscale.200',
            },
          }}
          data-testid="btn-modal-close"
        />
        <ModalBody p={{ base: 4, md: 6 }} sx={{ overflow: 'auto' }}>
          <IconButton
            aria-label="info"
            icon={<Info w="auto" h={6} color="primary.500" />}
            size="lg"
            backgroundColor="greyscale.300"
            mb={4}
            pointerEvents="none"
          />
          <VStack spacing={0}>
            <Heading variant="h5" lineHeight="short" alignSelf="flex-start">
              {t('claims.want_to_delete_claim')}
            </Heading>
            <Text
              fontSize="md"
              my={4}
              whiteSpace="break-spaces"
              lineHeight="initial"
            >
              {t('claims.want_to_delete_claim_desc')}
            </Text>
            <Button
              onClick={handleDeleteClaimEnquiry}
              type="submit"
              variant="danger"
              width="100%"
              size="s"
              data-testid="btn-delete-claim-delete-modal"
              isLoading={isLoading}
              mb={2}
            >
              {t('claims.delete_claim')}
            </Button>
            <Button
              as={Link}
              onClick={onCloseClick}
              variant="secondary"
              size="s"
              width="100%"
              data-testid="btn-complete-claim-delete-modal"
              href={externalLink}
              target={LinkTarget.SELF}
              rel="noopener noreferrer"
              isExternal
            >
              {t('claims.complete_claim')}
            </Button>
            {isError ? (
              <Box width="100%">
                <ErrorBadge>{t('errors.something_went_wrong')}</ErrorBadge>
              </Box>
            ) : null}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DeleteModal;
