import { Box, HStack, Heading, Text, VStack } from '@chakra-ui/react';
import { AirplaneRight } from '@airhelp/icons';
import { type fetchClaim } from '@airhelp/webapp';
import { isCountryFlagCode, UNKNOWN_COUNTRY_CODE } from '@airhelp/flags';
import { type ComponentProps } from 'react';
import { FlightCheckpoint } from './FlightCheckpoint';

type Flights = Awaited<ReturnType<typeof fetchClaim>>['itinerary']['flights'];

export function TravelDetails({
  header,
  flights,
  i18n,
}: {
  header: string;
  flights: Flights;
  i18n: {
    connectingFlightsCountText: string;
    connectionSeperatorText: string;
  };
}) {
  return (
    <VStack
      backgroundColor="greyscale.300"
      spacing={2}
      rounded="2xl"
      h="full"
      px={2}
      pb={4}
      overflowY="auto"
    >
      <VStack spacing={0} p={2} align="start" w="full" pt={5} px={8}>
        <Heading
          variant="h5"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          minW={0}
          w="full"
        >
          {header}
        </Heading>
        <Text
          display="flex"
          alignItems="center"
          gap={1}
          fontSize="xs"
          color="greyscale.600"
          verticalAlign="middle"
        >
          <AirplaneRight />
          {i18n.connectingFlightsCountText} {flights.length}
        </Text>
      </VStack>
      {flights.map((flight, index) => {
        const variant: ComponentProps<typeof FlightCheckpoint>['variant'] =
          flight.disrupted ? 'disrupted' : 'on-time';
        const rawDepartureAirportCountryCode =
          flight.departureAirport.countryCode.toLowerCase();
        const rawArrivalAirportCountryCode =
          flight.arrivalAirport.countryCode.toLowerCase();
        const departureAirportCountryCode = isCountryFlagCode(
          rawDepartureAirportCountryCode,
        )
          ? rawDepartureAirportCountryCode
          : UNKNOWN_COUNTRY_CODE;
        const arrivalAirportCountryCode = isCountryFlagCode(
          rawArrivalAirportCountryCode,
        )
          ? rawArrivalAirportCountryCode
          : UNKNOWN_COUNTRY_CODE;
        const nextFlight = flights.at(index + 1);

        return (
          <>
            <FlightCheckpoint
              variant={variant}
              departureCountryCode={departureAirportCountryCode}
              arrivalCountryCode={arrivalAirportCountryCode}
              departureDate={new Date(flight.localDepartureDate)}
              departureAirportHeader={flight.departureAirport.city}
              departureAirportSubheader={flight.departureAirport.name}
              arrivalAirportHeader={flight.arrivalAirport.name}
              arrivalAirportSubheader={flight.arrivalAirport.name}
              flightNumber={flight.flightNumber}
              airlineName={flight.airline.name}
            />
            {nextFlight ? (
              <HStack alignItems="center" w="full" spacing={5} py={2}>
                <Box h={0.25} backgroundColor="greyscale.400" flex={1} />
                <Text fontSize="xs" color="greyscale.700">
                  {i18n.connectionSeperatorText}
                </Text>
                <Box h={0.25} backgroundColor="greyscale.400" flex={1} />
              </HStack>
            ) : null}
          </>
        );
      })}
    </VStack>
  );
}
