import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { InfoOutline, PlusMath } from '@airhelp/icons';
import {
  Heading as ChakraHeading,
  Button,
  useBreakpointValue,
  IconButton,
  Grid,
  GridItem,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { PAGE_PATHS } from 'config/routes';
import Tabs from 'elements/Tabs';
import { type ITab } from 'elements/Tabs/Tabs';
import { useTrackEvent } from 'utils/tracking/hooks';
import { pluralVersion } from 'utils/translations';
import { type UserPolicy } from 'models/userPolicy';

interface IComponent {
  userPolicy: UserPolicy;
}

const Heading = ({ userPolicy }: IComponent) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { trackCtaClick, trackPageInteractions } = useTrackEvent();
  const tabId = 'journey-list-tab';
  const required24hDepartureTime =
    userPolicy.benefits?.required24hDepartureTime;
  const canAddTrip = userPolicy.dashboard?.canAddTrip;
  const departureThresholdHours = userPolicy.benefits
    ? userPolicy.benefits.departureThresholdHours
    : 48;

  const isMobile = useBreakpointValue({ base: true, md: false });
  const { isOpen, onOpen, onToggle, onClose } = useDisclosure();

  const onAddTripButtonClick = () => {
    trackCtaClick('add trip button', 'journeys page');

    navigate(generatePath(PAGE_PATHS.FLIGHTS.NEW_PAGE), {
      state: {
        from: 'journeys',
      },
    });
  };

  const onTabChange = (tab: string) => {
    trackPageInteractions('clicked', `${tab.toLowerCase()} trips`);
  };

  const tripStatusTabs: ITab[] = [
    {
      name: 'Active',
      label: t('dashboard.trip_status.active'),
    },
    {
      name: 'Past',
      label: t('dashboard.trip_status.past'),
    },
  ];

  return (
    <Grid
      templateAreas={{
        base: '"heading button" "tabs tabs"',
        md: '"heading tabs button"',
      }}
      gridTemplateColumns="auto 1fr"
      width="100%"
      columnGap={7}
      rowGap={4}
    >
      <GridItem area="heading" alignItems="center">
        <ChakraHeading variant="h5" display="inline">
          {t('journeys.your_trips')}
        </ChakraHeading>
        {canAddTrip ? (
          <Tooltip
            label={t(
              pluralVersion(
                required24hDepartureTime
                  ? 'journeys.your_trips_tip'
                  : 'journeys.your_trips_benefits_tip',
                departureThresholdHours,
              ),
              { count: departureThresholdHours },
            )}
            isOpen={isOpen}
            hasArrow
            arrowPadding={6}
            bgColor="greyscale.800"
            borderRadius="md"
            p={4}
            shouldWrapChildren
          >
            <InfoOutline
              color="primary.500"
              ms={1}
              boxSize={4}
              height="auto"
              verticalAlign="text-top"
              cursor="pointer"
              onMouseEnter={onOpen}
              onMouseLeave={onClose}
              onClick={onToggle}
            />
          </Tooltip>
        ) : null}
      </GridItem>
      <GridItem area="button" justifySelf="flex-end">
        {canAddTrip ? (
          isMobile ? (
            <IconButton
              icon={<PlusMath fontSize="md" />}
              onClick={onAddTripButtonClick}
              isRound
              aria-label="add trip"
              size="sm"
              _hover={{ bgColor: 'primary.600' }}
            />
          ) : (
            <Button
              size="s"
              onClick={onAddTripButtonClick}
              data-testid="btn-add-trip"
            >
              <PlusMath me={2} fontSize="lg" />
              {t('journeys.add_trip')}
            </Button>
          )
        ) : null}
      </GridItem>
      <GridItem
        area="tabs"
        justifySelf={{ base: 'flex-start', md: 'flex-end' }}
      >
        <Tabs tabs={tripStatusTabs} onTabChange={onTabChange} tabId={tabId} />
      </GridItem>
    </Grid>
  );
};

export default Heading;
