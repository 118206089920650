import { Button, Image } from '@chakra-ui/react';
import React from 'react';
import { useTrackEvent } from 'utils/tracking/hooks';

interface IComponent {
  url: string;
  iconSrc: string;
  label: string;
  eventLabel: string;
}

const AddToWalletButton: React.FC<IComponent> = ({
  url,
  label,
  eventLabel,
  iconSrc,
}) => {
  const { trackCtaClick } = useTrackEvent();

  return (
    <Button
      as="a"
      href={url}
      width={{ base: '100%', md: 'auto' }}
      onClick={() => {
        trackCtaClick(eventLabel, 'lounges pass page');
      }}
      fontSize="sm"
      bgColor="greyscale.900"
      whiteSpace="nowrap"
      leftIcon={<Image src={iconSrc} alt="wallet icon" boxSize={6} />}
    >
      {label}
    </Button>
  );
};

export default AddToWalletButton;
