import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { StripePaymentStatues } from '@airhelp/plus';
import { type StripeTestPaymentStatuses } from 'types/payments';
import Failed from './Failed';
import LongerProcess from './LongerProcess';
import Processing from './Processing';

interface IComponent {
  paymentStatus: StripePaymentStatues | StripeTestPaymentStatuses; // needed for test suites
  stripePaymentError: boolean;
  seconds: number;
  tryAgainPath: string | undefined;
  succeededPath: string | undefined;
  onPaymentFailed?: () => void;
  noLayoutWrap?: boolean;
}

const PaymentStatusView: React.FC<IComponent> = ({
  paymentStatus,
  stripePaymentError,
  seconds,
  tryAgainPath,
  succeededPath,
  onPaymentFailed,
  noLayoutWrap,
}) => {
  const [searchParams] = useSearchParams();

  if (seconds > 60) {
    return <LongerProcess noLayoutWrap={noLayoutWrap} />;
  }
  if (stripePaymentError) {
    return (
      <Failed
        tryAgainPath={tryAgainPath}
        onPaymentFailed={onPaymentFailed}
        noLayoutWrap={noLayoutWrap}
      />
    );
  }

  switch (paymentStatus) {
    case StripePaymentStatues.SUCCEEDED:
      return (
        <Navigate
          to={{ pathname: succeededPath, search: searchParams.toString() }}
          replace
        />
      );

    default:
      return <Processing noLayoutWrap={noLayoutWrap} />;
  }
};
export default PaymentStatusView;
