import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { type ProductName } from '@airhelp/plus';
import { isSmartAnnualMembership } from 'components/Membership/membershipHelper';
import StripeCheckoutWidget from 'components/StripeCheckoutWidget';
import { useLayout } from 'contexts/LayoutContext/LayoutContext';
import useEffectOnce from 'hooks/useEffectOnce';
import { type Offer } from 'models/Offers';
import { usePreferencesStore } from 'stores/preferences';
import {
  formatValueToCurrency,
  getLocalPriceValue,
} from 'stores/preferences/currencies';
import { getDashboardUrl } from 'utils/sites';

interface PageParams extends Record<string, string | undefined> {
  token: string;
}

const CheckoutFormView = ({
  productName,
  offers,
}: {
  productName: ProductName;
  offers: Record<string, Offer>;
}) => {
  const { setActiveStep } = useLayout();
  const { t } = useTranslation();
  const { token } = useParams<PageParams>();
  const [searchParams] = useSearchParams();
  const currency = usePreferencesStore((state) => state.currency);

  const product = offers[productName];
  const isSmartPlan = isSmartAnnualMembership(productName);

  const annualMembership = isSmartPlan;
  const annualPlanCopy = isSmartPlan
    ? t('pricing.plans.smart')
    : t('pricing.plans.essential');

  const planTypeCopy = annualMembership
    ? annualPlanCopy
    : t('pricing.plans.complete');

  const baseCallbackUrl = `${getDashboardUrl()}/trips/${token}/upgrade/processing-payment`;

  const callbackUrl = new URL(
    `${baseCallbackUrl}?product-name=${JSON.parse(JSON.stringify(product))}`,
    window.location.origin,
  );

  callbackUrl.search = searchParams.toString();

  const localPrice = getLocalPriceValue(product?.prices, currency);

  useEffectOnce(() => {
    setActiveStep(2);
  });

  return (
    <Box maxWidth={{ base: '100%', lg: '700px' }}>
      <Heading variant="h2" mb={5} data-testid="card-details">
        {t('payment.checkout.card_details')}
      </Heading>
      <Text fontSize="lg" fontWeight="normal">
        {t(
          `payment.checkout.${annualMembership ? 'getting_description' : 'upgrade_description'}`,
          {
            value: formatValueToCurrency(localPrice, currency),
            plan: planTypeCopy,
          },
        )}
      </Text>
      <Text fontSize="lg" fontWeight="normal">
        {t('payment.checkout.plan_will_be_active')}
      </Text>
      <Flex mt={14}>
        <StripeCheckoutWidget
          productName={productName}
          callbackUrl={callbackUrl.toString()}
          requireTerms
          recurring={annualMembership}
          tripUuid={token}
        />
      </Flex>
    </Box>
  );
};

export default CheckoutFormView;
