import config from 'config';
import useFeatureFlags from '../api/useFeatureFlags';
import useFetchMyProfile from '../api/userProfiles/useFetchMyProfile/useFetchMyProfile';

export function useClaimDetails() {
  const { data: userProfile, isPending: isUserProfileLoading } =
    useFetchMyProfile();
  const { isLoading: isFeatureFlagLoading, isEnabled: isFeatureEnabled } =
    useIsClaimDetailsFeature();

  return {
    isEnabled: isFeatureEnabled && !!userProfile,
    userProfile,
    isLoading: isUserProfileLoading || isFeatureFlagLoading,
  };
}

export function useIsClaimDetailsFeature() {
  const { data: featureFlags, isPending } = useFeatureFlags();

  return {
    isLoading: isPending,
    isEnabled:
      (!isPending && featureFlags?.new_claim_details) ||
      config.claimDetailsViewForceEnabled,
  };
}
