import React from 'react';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Input } from '@airhelp/react';
import { Box, Flex } from '@chakra-ui/react';
import { getCountriesPayoutSelectOptions } from 'utils/airBundle/airBundle';
import { addressFieldMaxLength } from 'utils/validations';
import { type Option } from 'types/select';

const AddressFields: React.FC<{ form: UseFormReturn }> = ({ form }) => {
  const { t, i18n } = useTranslation();
  const { control, formState, resetField, clearErrors } = form;
  const { touchedFields } = formState;
  const payoutCountriesOptions = getCountriesPayoutSelectOptions(i18n.language);

  const handleCountryOfResidenceSelect = (
    item: Option,
    onChange: (item: Option) => void,
  ) => {
    onChange(item);
    clearErrors();

    Object.keys(touchedFields).forEach((fieldName) => {
      resetField(fieldName);
    });
  };

  return (
    <>
      <Controller
        name="residence"
        control={control}
        rules={{ required: { value: true, message: t('errors.required') } }}
        defaultValue=""
        render={({
          field: { onChange, name, value },
          fieldState: { invalid, error },
        }) => (
          <Box width="100%" minHeight="108px">
            <Autocomplete
              name={name}
              isInvalid={invalid}
              errorMessage={error?.message || ''}
              label={t('common.country_of_residence')}
              value={value}
              options={payoutCountriesOptions}
              placeholder={t('common.current_country_of_residence')}
              isSearchable
              handleChange={(val) => {
                handleCountryOfResidenceSelect(val as Option, onChange);
              }}
              dataTestId="country-of-residence"
            />
          </Box>
        )}
      />

      <Flex
        display={{ base: 'inline-block', xlg: 'flex' }}
        flexDirection={{ base: 'column', xlg: 'row' }}
        width="100%"
      >
        <Controller
          name="state"
          control={control}
          rules={{
            maxLength: {
              value: addressFieldMaxLength,
              message: t('errors.too_long', {
                attribute: t('form.state_or_province'),
                max: addressFieldMaxLength,
              }),
            },
          }}
          defaultValue=""
          render={({
            field: { onChange, name, value },
            fieldState: { invalid, error },
          }) => (
            <Box w="100%" minHeight="106px" me={{ base: 0, md: 3 }}>
              <Input
                isInvalid={invalid}
                errorMessage={error?.message || ''}
                name={name}
                label={`${t('form.state_or_province')} ${t('optional')}`}
                value={value}
                placeholder={t('form.state_or_province')}
                onChange={onChange}
                maxLength={addressFieldMaxLength}
                data-testid="state"
              />
            </Box>
          )}
        />
        <Controller
          name="zipCode"
          control={control}
          rules={{
            maxLength: {
              value: addressFieldMaxLength,
              message: t('errors.too_long', {
                attribute: t('form.zip_or_postal_code'),
                max: addressFieldMaxLength,
              }),
            },
          }}
          defaultValue=""
          render={({
            field: { onChange, name, value },
            fieldState: { invalid, error },
          }) => (
            <Box w="100%" minHeight="106px">
              <Input
                isInvalid={invalid}
                errorMessage={error?.message || ''}
                name={name}
                label={`${t('form.zip_or_postal_code')} ${t('optional')}`}
                value={value}
                placeholder={t('form.zip_or_postal_code')}
                onChange={onChange}
                maxLength={addressFieldMaxLength}
                data-testid="zip-code"
              />
            </Box>
          )}
        />
      </Flex>
      <Controller
        name="city"
        control={control}
        rules={{
          maxLength: {
            value: addressFieldMaxLength,
            message: t('errors.too_long', {
              attribute: t('form.city'),
              max: addressFieldMaxLength,
            }),
          },
        }}
        defaultValue=""
        render={({
          field: { onChange, name, value },
          fieldState: { invalid, error },
        }) => (
          <Box w="100%" minHeight="106px">
            <Input
              isInvalid={invalid}
              errorMessage={error?.message || ''}
              name={name}
              label={`${t('form.city')} ${t('optional')}`}
              value={value}
              placeholder={t('form.city')}
              onChange={onChange}
              maxLength={addressFieldMaxLength}
              data-testid="city"
            />
          </Box>
        )}
      />
      <Controller
        name="streetAddress1"
        control={control}
        rules={{
          required: { value: true, message: t('errors.required') },
          pattern: { value: /^\S+/, message: t('errors.required') },
          maxLength: {
            value: addressFieldMaxLength,
            message: t('errors.too_long', {
              attribute: t('form.street_address_line'),
              max: addressFieldMaxLength,
            }),
          },
        }}
        defaultValue=""
        render={({
          field: { onChange, name, value },
          fieldState: { invalid, error },
        }) => (
          <Box width="100%" minHeight="106px">
            <Input
              isInvalid={invalid}
              errorMessage={error?.message || ''}
              name={name}
              label={t('form.street_address_line', { number: 1 })}
              value={value}
              placeholder={t('form.street_address_line', { number: 1 })}
              onChange={onChange}
              maxLength={addressFieldMaxLength}
              data-testid="street-address-1"
            />
          </Box>
        )}
      />
      <Controller
        name="streetAddress2"
        control={control}
        rules={{
          maxLength: {
            value: addressFieldMaxLength,
            message: t('errors.too_long', {
              attribute: t('form.street_address_line', { number: 2 }),
              max: addressFieldMaxLength,
            }),
          },
        }}
        defaultValue=""
        render={({
          field: { onChange, name, value },
          fieldState: { invalid, error },
        }) => (
          <Box width="100%" minHeight="106px">
            <Input
              isInvalid={invalid}
              errorMessage={error?.message || ''}
              name={name}
              label={`${t('form.street_address_line', { number: 2 })} ${t('optional')}`}
              value={value}
              placeholder={t('form.street_address_line', { number: 2 })}
              onChange={onChange}
              maxLength={addressFieldMaxLength}
              data-testid="street-address-2"
            />
          </Box>
        )}
      />
    </>
  );
};

export default AddressFields;
