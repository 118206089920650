import * as TripsPages from 'pages/Trips';

export const TripsRoutes = {
  path: 'trips/:token',
  children: [
    {
      id: 'activate',
      path: 'activate',
      element: <TripsPages.ActivatePage />,
    },
    {
      id: 'upgrade',
      path: 'upgrade/*',
      element: <TripsPages.TripsUpgradePage />,
    },
  ],
};
