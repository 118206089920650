import React from 'react';
import { Outlet } from 'react-router-dom';
import { track } from 'react-tracking';
import Layout from 'components/LoungeFinder/Layout';

const LoungeFinderPage: React.FC = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default track({ page_type: 'LoungeFinder' })(LoungeFinderPage);
