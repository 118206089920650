import React from 'react';
import { useLocation, Link as ReactLink } from 'react-router-dom';
import { shadowsMd } from '@airhelp/design-tokens/dist/js/design-tokens';
import { Link, VStack } from '@chakra-ui/react';
import { type AirportLounge } from 'models/AirportLounge';

interface IComponent {
  lounges: AirportLounge[];
  activeLoungeCode: string;
}

const SideNavigation: React.FC<IComponent> = ({
  lounges,
  activeLoungeCode,
}) => {
  const { search } = useLocation();

  return (
    <VStack
      display={{ base: 'none', lg: 'block' }}
      w={{ base: 'auto', lg: '270px' }}
      bg="greyscale.100"
      p={4}
      spacing={0}
      borderRadius="xl"
      boxShadow={shadowsMd}
    >
      {lounges.map(({ airportCode, loungeCode, loungeName }) => {
        return (
          <Link
            as={ReactLink}
            fontSize="sm"
            display="block"
            w="100%"
            borderRadius="md"
            color="primary.900"
            px={3}
            py={3}
            textAlign="left"
            fontWeight={loungeCode === activeLoungeCode ? 'bold' : 'regular'}
            m={0}
            to={{
              pathname: `/lounge-finder/airport/${airportCode}/lounge/${loungeCode}`,
              search,
            }}
            key={`lounge-${loungeCode}`}
            _focus={{ border: 0 }}
            _hover={{ bg: 'primary.500', color: 'greyscale.100' }}
            _active={{ bg: 'primary.500', color: 'greyscale.100' }}
            _visited={{}}
          >
            {loungeName}
          </Link>
        );
      })}
    </VStack>
  );
};

export default SideNavigation;
