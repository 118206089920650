import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoOutline } from '@airhelp/icons';
import { Text, Tooltip, VStack, useDisclosure } from '@chakra-ui/react';
import { useTrackEvent } from 'utils/tracking/hooks';

const NoLoungePassesTooltip = () => {
  const { t } = useTranslation();
  const { trackPageInteractions } = useTrackEvent();
  const { isOpen, onOpen, onToggle, onClose } = useDisclosure();

  const onTooltipOpen = () => {
    trackPageInteractions('showed', 'dont have lounges tooltip');
    onOpen();
  };

  return (
    <Tooltip
      hasArrow
      isOpen={isOpen}
      label={
        <VStack p={4} spacing={6}>
          <Text color="greyscale.200" fontSize="sm">
            {t('lounges.tooltip.p_1')}
          </Text>
          <Text color="greyscale.200" fontSize="sm">
            {t('lounges.tooltip.p_2')}
          </Text>
        </VStack>
      }
      placement="bottom"
      arrowPadding={6}
      bgColor="greyscale.800"
      borderRadius="md"
      p={4}
    >
      <InfoOutline
        ms="2px"
        color="primary.500"
        boxSize={4}
        cursor="pointer"
        onMouseEnter={onTooltipOpen}
        onMouseLeave={onClose}
        onClick={onToggle}
      />
    </Tooltip>
  );
};

export default NoLoungePassesTooltip;
