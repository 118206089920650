import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { type AirPayoutJourney } from 'models/journey/AirPayoutJourney';
import useGetAirPayoutStatusBadge from 'components/Journey/AirPayout/getAirPayoutStatusBadge';
import { NotificationDot } from 'elements/PulsatingNotificationDot/PulsatingNotificationDot';
import AirBundleTagStatus from 'components/Journey/AirBundle/AirBundleTagStatus';
import { airBundleTableStyle } from '../AirBundleThead';
import { useGetAirPayoutTooltipStatus } from './useGetAirPayoutTooltipStatus';
import AirPayoutAction from './AirPayoutAction';

const AirPayoutInsuranceViewItem = ({
  airPayoutJourney,
  lastElement,
  journeyId,
}: {
  airPayoutJourney: AirPayoutJourney;
  lastElement: boolean;
  journeyId: number;
}) => {
  const { t } = useTranslation();

  const { status, showNotification } = airPayoutJourney;

  const tooltipLabel = useGetAirPayoutTooltipStatus(airPayoutJourney);
  const boxTag = useGetAirPayoutStatusBadge(status);

  return (
    <>
      {/* DesktopView */}
      <Flex
        alignItems="center"
        display={{ base: 'none', md: 'grid' }}
        gridTemplateColumns="repeat(3, 1fr)"
        py={4}
        pb={lastElement ? 0 : 4}
        borderBottom={lastElement ? 'none' : '1px solid'}
        borderColor="greyscale.400"
      >
        <Flex alignItems="center">
          {showNotification ? <NotificationDot /> : null}
          <Text {...airBundleTableStyle.td} ms={showNotification ? 2 : 0}>
            {airPayoutJourney.name}
          </Text>
        </Flex>
        <AirBundleTagStatus
          statusBox={boxTag}
          tooltipLabel={tooltipLabel}
          dataTestId="status-tag-airpayout"
        />
        <Text
          {...airBundleTableStyle.td}
          color="greyscale.500"
          fontWeight="normal"
          textAlign="right"
        >
          <AirPayoutAction
            airPayoutJourney={airPayoutJourney}
            journeyId={journeyId}
          />
        </Text>
      </Flex>
      {/* MobileView */}
      <Flex
        display={{ base: 'flex', md: 'none' }}
        flexDirection="column"
        background="greyscale.300"
        borderRadius="lg"
        p={4}
        mb={2}
      >
        <Flex alignItems="center" pb={3}>
          {showNotification ? <NotificationDot /> : null}
          <Text {...airBundleTableStyle.td} ms={showNotification ? 2 : 0}>
            {airPayoutJourney.name}
          </Text>
        </Flex>
        <Flex justifyContent="space-between" pb={4}>
          <Text {...airBundleTableStyle.th}>{t('common.status')}</Text>
          <AirBundleTagStatus
            statusBox={boxTag}
            tooltipLabel={tooltipLabel}
            dataTestId="status-tag-airpayout"
          />
        </Flex>
        <Flex justifyContent="space-between" pb={4}>
          <Text {...airBundleTableStyle.th}>{t('common.action')}</Text>
          <Text
            {...airBundleTableStyle.td}
            color="greyscale.500"
            fontWeight="normal"
          >
            <AirPayoutAction
              airPayoutJourney={airPayoutJourney}
              journeyId={journeyId}
            />
          </Text>
        </Flex>
      </Flex>
    </>
  );
};

export default AirPayoutInsuranceViewItem;
