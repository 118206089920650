import { useEffect } from 'react';

export const useTrackOnce = (
  callback: (setTracked: () => void) => void,
  effectDependencies: unknown[],
) => {
  useEffect(() => {
    let tracked = false;

    const setTracked = () => {
      tracked = true;
    };

    if (!tracked) {
      callback(setTracked);
    }
  }, effectDependencies);
};
