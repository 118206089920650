import React from 'react';
import { Button } from '@chakra-ui/react';
import { type ITab } from '../Tabs';

interface IComponent {
  tab: ITab;
  active: boolean;
  handleClick: (name: string) => void;
  tabId: string;
}

const Tab: React.FC<IComponent> = ({ tab, active, handleClick, tabId }) => {
  return (
    <Button
      size="s"
      textColor={active ? 'primary.600' : 'greyscale.500'}
      backgroundColor={active ? 'primary.200' : 'greyscale.100'}
      borderRadius={50}
      onClick={() => {
        handleClick(tab.name);
      }}
      data-testid={`${tab.name}-${tabId}`}
      _hover={
        active
          ? { textColor: 'primary.700', backgroundColor: 'primary.300' }
          : { textColor: 'greyscale.600', backgroundColor: 'primary.100' }
      }
    >
      {tab.label}
    </Button>
  );
};

export default Tab;
