import { Transform } from 'class-transformer';

export function TransformStringToEnum<E extends Record<string, string>>(
  enumType: E,
) {
  return Transform(({ value }) => {
    const enumValue = Object.values(enumType).find((v) => v === value);

    // If the value is not found, we will return the original and let the validation handle it
    return enumValue ?? value;
  });
}
