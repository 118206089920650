import { AirBundleDocumentFile, AirBundleDocumentParam } from '@airhelp/plus';
import { type TranslationKey } from 'types/i18n';

export interface AirPayoutDocument {
  name: string;
  tKey: TranslationKey;
}

export const AIR_LUGGAGE_DOCUMENTS: { name: string; tKey: TranslationKey }[] = [
  {
    name: `${AirBundleDocumentFile.PIR}File`,
    tKey: 'air_luggage.luggage_details.documents.pir_reference',
  },
  {
    name: `${AirBundleDocumentFile.BOARDING_OR_BOOKING}File`,
    tKey: 'air_luggage.luggage_details.documents.boarding_pass_or_booking_reference',
  },
  {
    name: `${AirBundleDocumentFile.PASSPORT}File`,
    tKey: 'air_luggage.luggage_details.documents.passport_or_id',
  },
  {
    name: `${AirBundleDocumentFile.BAGGAGE_TAG}File`,
    tKey: 'air_luggage.luggage_details.documents.baggage_tag',
  },
];

export const airLuggageDocumentMappings = [
  {
    fileKey: `${AirBundleDocumentFile.PASSPORT}File`,
    documentType: AirBundleDocumentParam.PASSPORT,
  },
  {
    fileKey: `${AirBundleDocumentFile.PIR}File`,
    documentType: AirBundleDocumentParam.PIR,
  },
  {
    fileKey: `${AirBundleDocumentFile.BOARDING_OR_BOOKING}File`,
    documentType: AirBundleDocumentParam.BOARDING_OR_BOOKING,
  },
  {
    fileKey: `${AirBundleDocumentFile.BAGGAGE_TAG}File`,
    documentType: AirBundleDocumentParam.BAGGAGE_TAG,
  },
];
