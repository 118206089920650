import React, { type ChangeEvent, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { type AirportOption, Input } from '@airhelp/react';
import { Box, Button, Flex, Stack, Text } from '@chakra-ui/react';
import { compact } from 'lodash';
import ContentBox from 'components/Flights/New/ContentBox';
import { EditedJourneyContext } from 'contexts';
import AirportAutocomplete from 'elements/AirportAutocomplete';
import { EditableTypes } from 'reducers/editableJourney';
import { MAX_LENGTH_JOURNEY_TITLE } from 'utils/journey/journey';

interface IComponent {
  onNextStep: () => void;
}

const RouteDestinationView: React.FC<IComponent> = ({ onNextStep }) => {
  const { t } = useTranslation();

  const { journey, dispatch, itineraryIndex, isReturnTrip, editedItinerary } =
    useContext(EditedJourneyContext);
  const { flights } = editedItinerary;
  const firstFlight = flights[0];
  const lastFlight = flights[flights.length - 1];

  const handleDepartureChange = (
    airportOption: AirportOption | null,
    formOnChange: (airport) => void,
  ) => {
    const airport = airportOption?.value ?? null;

    formOnChange(airport);

    dispatch({
      type: EditableTypes.UPDATE_FLIGHT,
      itineraryIndex,
      payload: {
        ...firstFlight,
        departureAirport: airport,
        departureAirportCode: airport?.iata || '',
      },
    });
  };

  const handleArrivalChange = (
    airportOption: AirportOption | null,
    formOnChange: (airport) => void,
  ) => {
    const airport = airportOption?.value ?? null;

    formOnChange(airport);

    dispatch({
      type: EditableTypes.UPDATE_FLIGHT,
      itineraryIndex,
      payload: {
        ...lastFlight,
        arrivalAirport: airport,
        arrivalAirportCode: airport?.iata || '',
      },
    });
  };

  const handleNameChange = (
    tripName: string | null,
    formOnChange: (tripName) => void,
  ) => {
    formOnChange(tripName ? tripName : '');

    dispatch({
      type: EditableTypes.UPDATE_NAME,
      itineraryIndex,
      payload: { name: tripName },
    });
  };

  const onSubmit = () => {
    onNextStep();
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onSubmit' });

  const formHasErrors = Object.keys(errors).length > 0;
  const showTripNameField = !isReturnTrip;

  const disabledDepartureIatas = compact([
    lastFlight.arrivalAirport?.iata,
    journey.itineraries[0].flights[0].departureAirportCode,
  ]);

  const disabledArrivalIatas = compact([firstFlight.departureAirport?.iata]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ContentBox header={t('funnels.add_new_flight.airports_step.header')}>
        <Stack spacing={{ base: 4 }}>
          {/* trip name */}
          {showTripNameField ? (
            <Box maxW={{ base: '100%' }} width="100%">
              <Controller
                name="tripName"
                control={control}
                defaultValue={journey.name}
                rules={{
                  maxLength: {
                    value: MAX_LENGTH_JOURNEY_TITLE,
                    message: t('errors.too_long', {
                      attribute: t(
                        'funnels.add_new_flight.airports_step.name_your_trip',
                      ),
                      max: MAX_LENGTH_JOURNEY_TITLE,
                    }),
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid, error },
                }) => (
                  <>
                    <Input
                      isInvalid={invalid}
                      errorMessage={error?.message || ''}
                      size="lg"
                      label={`${t('funnels.add_new_flight.airports_step.name_your_trip')} ${t('optional')}`}
                      value={value}
                      type="text"
                      placeholder={t(
                        'funnels.add_new_flight.airports_step.name_your_trip_placeholder',
                      )}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleNameChange(event.target.value, onChange);
                      }}
                      data-testid="trip-name"
                    />
                    {!error?.message && (
                      <Text
                        fontSize="sm"
                        color="greyscale.600"
                        mt={{ base: 2 }}
                      >
                        {t('common.max_characters', { max: 40 })}
                      </Text>
                    )}
                  </>
                )}
              />
            </Box>
          ) : null}
          {/* departure and arrival airports */}
          <Flex
            flexDirection={{ base: 'column', md: 'row' }}
            justifyContent={{ base: 'initial', md: 'space-between' }}
          >
            <Box
              maxW={{ base: '100%', md: '300px' }}
              width="100%"
              me={{ base: 0, md: 2 }}
              mb={{ base: 6, md: 0 }}
            >
              <Controller
                name="departureAirport"
                control={control}
                rules={{
                  required: { value: true, message: t('errors.required') },
                }}
                defaultValue={firstFlight.departureAirport}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid, error },
                }) => (
                  <AirportAutocomplete
                    defaultAirport={value}
                    handleChange={(airport) => {
                      handleDepartureChange(airport, onChange);
                    }}
                    label={t(
                      'funnels.add_new_flight.airports_step.departure_airport',
                    )}
                    isInvalid={invalid}
                    errorMessage={error?.message || ''}
                    dataTestId="departure-airport"
                    disabledIatas={disabledDepartureIatas}
                  />
                )}
              />
            </Box>
            <Box
              maxW={{ base: '100%', md: '300px' }}
              width="100%"
              ms={{ base: 0, md: 2 }}
            >
              <Controller
                name="arrivalAirport"
                control={control}
                rules={{
                  required: { value: true, message: t('errors.required') },
                }}
                defaultValue={lastFlight.arrivalAirport}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid, error },
                }) => (
                  <AirportAutocomplete
                    defaultAirport={value}
                    handleChange={(airport) => {
                      handleArrivalChange(airport, onChange);
                    }}
                    label={t(
                      'funnels.add_new_flight.airports_step.final_destination',
                    )}
                    isInvalid={invalid}
                    errorMessage={error?.message || ''}
                    dataTestId="arrival-airport"
                    disabledIatas={disabledArrivalIatas}
                  />
                )}
              />
            </Box>
          </Flex>
        </Stack>
      </ContentBox>

      {/* form actions */}
      <Stack
        direction={{ base: 'column', md: 'row-reverse' }}
        mt={6}
        justifyContent="end"
        spacing={5}
      >
        <Button
          type="submit"
          minWidth={{ base: '100%', md: '80px' }}
          size="s"
          isDisabled={formHasErrors}
          data-testid="form-action-continue"
        >
          {t('common.continue')}
        </Button>
      </Stack>
    </form>
  );
};

export default RouteDestinationView;
