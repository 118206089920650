import React from 'react';
import { generatePath, Navigate, useParams } from 'react-router-dom';
import { track } from 'react-tracking';
import AnnualView from 'components/Trips/Annual/AnnualView';
import { PAGE_PATHS } from 'config/routes';
import { PageLoader } from 'elements';
import { usePageTracking } from 'utils/tracking';
import { useComputedOffers } from 'hooks/api/offers/useComputedOffers';
import { CommonParams, castBooleanParam, castStringParam } from 'utils/params';

const AnnualPage: React.FC = () => {
  usePageTracking();
  const { token } = useParams();
  const searchParams = new URLSearchParams(location.search);

  const {
    isLoading: isLoadingOffers,
    isError: isErrorOffers,
    data: offersData,
  } = useComputedOffers({
    params: {
      renew: castBooleanParam(searchParams, CommonParams.RENEW),
      upgrade: castBooleanParam(searchParams, CommonParams.UPGRADE),
      channel: castStringParam(searchParams, CommonParams.CHANNEL),
    },
  });

  if (isLoadingOffers) {
    return <PageLoader />;
  }

  if (isErrorOffers || !token) {
    return <Navigate to={generatePath(PAGE_PATHS.ERROR_PAGE)} />;
  }

  if (!offersData) {
    return null;
  }

  const offers = offersData.offers;

  return <AnnualView offers={offers} token={token} />;
};

export default track({ page_type: 'Trips.Upgrade.Annual' })(AnnualPage);
