import React, { useEffect, useState } from 'react';
import { generatePath, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StripePaymentStatues } from '@airhelp/plus';
import usePaymentStatus from 'hooks/api/payments/usePaymentStatus';
import { PAGE_PATHS } from 'config/routes';
import { sentryUtils } from 'utils';
import ProcessingComponent from './ProcessingComponent';

const CHECKING_TIME_STAGE_1 = 2; // 2s
const CHECKING_TIME_STAGE_2 = 10; // 10s

const CardProcessingView = ({ setupIntentId }: { setupIntentId: string }) => {
  const { t } = useTranslation();

  const usePayment = usePaymentStatus();

  const [seconds, setSeconds] = useState(0);
  const [isStatusChecking, setIsStatusChecking] = useState(true);
  const [checkingStatusFrequency, setCheckingStatusFrequency] =
    useState<number>(CHECKING_TIME_STAGE_1);
  const [paymentStatus, setPaymentStatus] = useState<StripePaymentStatues>(
    StripePaymentStatues.REQUIRES_PAYMENT_METHOD,
  );
  const [stripePaymentError, setStripePaymentError] = useState(false);

  const processPayment = async () => {
    try {
      const response = await usePayment.mutateAsync({
        id: setupIntentId,
      });
      const { status, errorCode } = response;

      if (status === StripePaymentStatues.SUCCEEDED) {
        setIsStatusChecking(false);
        setPaymentStatus(StripePaymentStatues.SUCCEEDED);
      }

      if (errorCode) {
        setIsStatusChecking(false);
        setStripePaymentError(true);
      }
    } catch (error) {
      setIsStatusChecking(false);
      setStripePaymentError(true);
      sentryUtils.captureException(error);
    }
  };

  useEffect(() => {
    if (isStatusChecking) {
      const intervalId = setInterval(() => {
        setSeconds((secondsValue) => secondsValue + 1);

        if (seconds > 0 && seconds % checkingStatusFrequency === 0) {
          processPayment();
        }

        if (seconds === CHECKING_TIME_STAGE_2) {
          setCheckingStatusFrequency(CHECKING_TIME_STAGE_2);
        }

        if (seconds > 60) {
          setIsStatusChecking(false);
        }
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [isStatusChecking, checkingStatusFrequency, seconds]);

  if (seconds > 60) {
    return (
      <ProcessingComponent
        title={t('payment_management.processing.payment_processing')}
        description={t(
          'payment_management.processing.still_processing_description',
        )}
      />
    );
  }
  if (stripePaymentError) {
    return (
      <ProcessingComponent
        title={t('errors.something_went_wrong')}
        description={t('payment_management.processing.error_description')}
        isError
      />
    );
  }

  switch (paymentStatus) {
    case StripePaymentStatues.SUCCEEDED:
      return (
        <Navigate
          to={{
            pathname: generatePath(PAGE_PATHS.MY_ACCOUNT.BILLING.BILLING_PAGE),
          }}
          replace
        />
      );

    default:
      return (
        <ProcessingComponent
          title={t('payment_management.processing.payment_processing')}
          description={t('payment_management.processing.processed_description')}
          isProcessing
        />
      );
  }
};

export default CardProcessingView;
