import { useEffect, useState } from 'react';

const useDebouncedValue = (value: string, timeout = 300) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const to = setTimeout(() => {
      setDebouncedValue(value);
    }, timeout);

    return () => {
      clearTimeout(to);
    };
  }, [value, timeout]);

  return debouncedValue;
};

export default useDebouncedValue;
