import React from 'react';
import { Box, ListItem } from '@chakra-ui/react';
import { type Benefit } from './types';

interface IComponent {
  benefit: Benefit;
}

const BenefitItem: React.FC<IComponent> = ({ benefit }) => {
  return (
    <ListItem display="flex" alignItems="flex-start" color="primary.900">
      <Box display="inline-block" verticalAlign="baseline" ms={3}>
        - {benefit.text}
      </Box>
    </ListItem>
  );
};

export default BenefitItem;
