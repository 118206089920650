import {
  Box,
  type HTMLChakraProps,
  Text,
  VStack,
  Drawer,
  type DrawerProps,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
  type BoxProps,
  useBreakpointValue,
  useMediaQuery,
  type TextProps,
  Link,
  Heading,
} from '@chakra-ui/react';
import { LinkGenerator } from '@airhelp/react';
import referralDrawerBg from 'assets/images/referral-drawer-bg.png';
import GiftCard from 'components/GiftCard/GiftCard';

export default function ReferralCard({
  variant = 'box',
  referralLink,
  i18n,
}: {
  variant?: 'box' | 'section';
  referralLink: string;
  i18n: {
    header: string;
    subheader: string;
    modal: {
      header: string;
      subheader: string;
      actionText: string;
      copiedActionText: string;
      readMore: string;
      readMoreLink: string;
    };
  };
}) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <>
      {variant === 'box' && (
        <ReferralBox i18n={i18n} cursor="pointer" onClick={onOpen} />
      )}
      {variant === 'section' && (
        <ReferralSection i18n={i18n} cursor="pointer" onClick={onOpen} />
      )}
      <ReferralDrawer
        i18n={i18n.modal}
        referralLink={referralLink}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
}

function ReferralSection({
  i18n,
  ...props
}: BoxProps & {
  i18n: {
    header: string;
    subheader: string;
  };
}) {
  const [isExtraSmallScreen] = useMediaQuery('(max-width: 400px)');
  return (
    <Box
      display={{ base: 'flex', lg: 'none' }}
      p={6}
      flexDir="column"
      pos="relative"
      isolation="isolate"
      backgroundColor="greyscale.300"
      rounded="xl"
      border="1px solid white"
      overflow="hidden"
      {...props}
    >
      <ReferralCardHeader
        text={i18n.header}
        as="h3"
        fontSize={{ base: 'md', sm: 'lg' }}
        maxW="40%"
        minW="200px"
        sx={{
          'text-wrap': 'balance',
        }}
      />
      <Box py={{ base: 4, md: 6 }} />
      <ReferralCardSubheader fontSize={{ base: 'sm', sm: 'md' }}>
        {i18n.subheader}
      </ReferralCardSubheader>

      <GiftCard
        pos="absolute"
        w={isExtraSmallScreen ? 120 : { base: 180, sm: 200 }}
        bottom={{
          base: 0,
          sm: '50%',
        }}
        right={0}
        transform={{
          base: 'rotate(-40deg)',
          sm: 'translateY(50%) rotate(0)',
        }}
        mr={{ base: 0, sm: 4, lg: 0 }}
        zIndex="-1"
      />
      <AirhelpLogoSkeleton
        zIndex="-2"
        transform="translate(10%, -25%)"
        pos="absolute"
        w="70%"
        top={0}
        right={0}
      />
    </Box>
  );
}

function ReferralDrawer({
  isOpen,
  onClose,
  i18n,
  referralLink,
}: {
  isOpen: boolean;
  onClose: () => void;
  referralLink: string;
  i18n: {
    header: string;
    subheader: string;
    actionText: string;
    copiedActionText: string;
    readMore: string;
    readMoreLink: string;
  };
}) {
  const placement: DrawerProps['placement'] =
    useBreakpointValue({ base: 'bottom', lg: 'end' }) ?? 'end';
  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement={placement}>
      <DrawerOverlay />
      <DrawerContent
        isolation="isolate"
        m={{
          base: 0,
          lg: 4,
        }}
        pt={{ base: 4, lg: 0 }}
        bg="transparent"
        pos="relative"
        minW={{ lg: 500 }}
      >
        <Box
          h="full"
          w="full"
          backgroundImage={`url('${referralDrawerBg}')`}
          backgroundSize="cover"
          borderTopStartRadius="xl"
          borderTopEndRadius="xl"
          borderBottomEndRadius={{ base: 0, lg: 'xl' }}
          borderBottomStartRadius={{ base: 0, lg: 'xl' }}
          overflow="hidden"
        >
          <VStack
            h="full"
            w="full"
            justifyContent="center"
            alignItems="stretch"
            textAlign="center"
            mx="auto"
            px={{ base: 4, md: 0 }}
            pt={60}
            pb={32}
            spacing={5}
            maxW="400px"
            pos="relative"
            zIndex={5}
          >
            <Box py={10} pos="relative" w="full">
              <GiftCard
                pos="absolute"
                w="70%"
                boxShadow="30px 20px 15px 0px rgba(0, 0, 0, 0.15)"
                top={0}
                transform="translate(-25%, -95%) rotate(-15deg)"
                zIndex={3}
                left={0}
              />
              <GiftCard
                pos="absolute"
                right={0}
                zIndex={2}
                w="70%"
                boxShadow="20px 20px 15px 0px rgba(0, 0, 0, 0.15)"
                top="0"
                transform="translate(20%, -95%) perspective(600px) rotateX(20deg) rotateZ(15deg)"
              />

              <AirhelpLogoSkeleton
                w="125%"
                pos="absolute"
                top={0}
                left="50%"
                transform="translate(-50%, -87%)"
              />
            </Box>
            <ReferralCardHeader
              lineHeight={1.2}
              fontSize="3xl"
              text={i18n.header}
            />
            <Text fontSize="md" color="primary.900" mb={1} fontWeight={500}>
              {i18n.subheader}
            </Text>
            <LinkGenerator
              link={referralLink}
              beforeCopyText={i18n.actionText}
              afterCopyText={i18n.copiedActionText}
            />
            <Link isExternal href={i18n.readMoreLink}>
              <Text color="greyscale.600" fontSize="sm">
                {i18n.readMore}
              </Text>
            </Link>
          </VStack>
        </Box>
      </DrawerContent>
    </Drawer>
  );
}

function ReferralBox({
  i18n,
  ...props
}: BoxProps & { i18n: { header: string; subheader: string } }) {
  return (
    <Box
      display={{ base: 'none', lg: 'block' }}
      isolation="isolate"
      w="full"
      h="full"
      aspectRatio={1.4}
      pos="relative"
      overflow="hidden"
      rounded="lg"
      p={4}
      {...props}
    >
      <VStack zIndex="3" gap={1} pos="relative" alignItems="flex-start">
        <ReferralCardSubheader>{i18n.subheader}</ReferralCardSubheader>
        <ReferralCardHeader text={i18n.header} textAlign="left" />
      </VStack>
      <Box
        w="full"
        h="full"
        background="linear-gradient(173deg, rgba(255, 255, 255, 0.00) 68.06%, #F1F4F8 105.97%)"
        pos="absolute"
        top={0}
        left={0}
        zIndex="2"
      />
      <GiftCard
        w="80%"
        zIndex={1}
        position="absolute"
        right={0}
        bottom={0}
        transform="rotate(-20deg) translateX(-2%)  translateY(15%)"
      />
      <Box
        w="full"
        h="full"
        background="linear-gradient(180deg, #FFF 0%, #D6EAFF 100%)"
        pos="absolute"
        top={0}
        left={0}
      />
    </Box>
  );
}

function ReferralCardHeader({
  text,
  sx,
  ...props
}: TextProps & { text: string }) {
  return (
    <Heading
      color="primary.900"
      lineHeight={1.4}
      fontSize="md"
      fontWeight="bold"
      sx={{ '& span': { color: 'secondary.500' }, ...sx }}
      {...props}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
}

function ReferralCardSubheader({ children, ...props }: TextProps) {
  return (
    <Text color="greyscale.600" fontSize="xs" {...props}>
      {children}
    </Text>
  );
}

function AirhelpLogoSkeleton(props: HTMLChakraProps<'svg'>) {
  return (
    <Box
      as="svg"
      aspectRatio={0.91}
      viewBox="0 0 557 509"
      color="white"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M310 109.571C320.777 105.328 332.534 103.068 344.508 103.002C361.598 102.906 378.316 107.283 392.431 115.547C406.545 123.811 417.385 135.57 423.503 149.255L550.577 431.781C554.397 440.217 555.761 449.325 554.551 458.316C553.341 467.306 549.594 475.908 543.633 483.376C537.673 490.845 529.678 496.954 520.344 501.176C511.009 505.398 500.614 507.604 490.061 507.604H206.321C195.839 507.617 185.509 505.464 176.204 501.327C166.899 497.19 158.893 491.191 152.866 483.838C146.838 476.485 142.965 467.994 141.576 459.086C140.186 450.177 141.321 441.112 144.883 432.659L157.621 403"
        stroke="currentColor"
        strokeWidth="2.67948"
      />
      <path
        d="M126.453 47.6619C132.243 34.1914 142.701 22.519 156.471 14.2287C170.243 5.93768 186.654 1.43538 203.516 1.34125C220.377 1.24711 236.856 5.56579 250.754 13.703C264.65 21.8396 275.286 33.3955 281.28 46.8017L281.281 46.8044L408.355 329.331L408.356 329.334C412.075 337.546 413.399 346.401 412.224 355.137C411.048 363.874 407.404 372.251 401.586 379.541C395.767 386.832 387.948 392.815 378.791 396.955C369.635 401.096 359.43 403.265 349.061 403.265H65.3206H65.319C55.0202 403.277 44.8762 401.161 35.7482 397.103C26.6203 393.044 18.7878 387.169 12.9018 379.989C7.01695 372.81 3.2503 364.539 1.89955 355.879C0.549162 347.222 1.64973 338.41 5.11614 330.183C5.11658 330.181 5.11703 330.18 5.11747 330.179L126.453 47.6619ZM126.453 47.6619L125.222 47.1329L126.453 47.6619Z"
        stroke="currentColor"
        strokeWidth="2.67948"
      />
    </Box>
  );
}
