import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { track } from 'react-tracking';
import { type AirportOption } from '@airhelp/react';
import AirportSearchView from 'components/LoungeFinder/AirportSearchView';
import { usePageTracking } from 'utils/tracking';

const IndexPage: React.FC = () => {
  usePageTracking();
  const { search } = useLocation();
  const [selectedAirport, setSelectedAirport] = useState<AirportOption | null>(
    null,
  );

  if (selectedAirport) {
    return (
      <Navigate
        replace
        to={{ pathname: `airport/${selectedAirport.value.iata}`, search }}
      />
    );
  }
  return <AirportSearchView handleAirportSelect={setSelectedAirport} />;
};

export default track({ page_type: 'LoungeFinder.Index' })(IndexPage);
