import { type Offer } from 'models/Offers';
import { type CheckoutData } from 'utils/tracking/reducers/checkoutDataReducer';

export const productTierForOffer = (offer: Offer | null) => {
  if (!offer) {
    return '';
  }
  if (offer.name.includes('essential')) {
    return 'Essential';
  } else if (offer.name.includes('complete')) {
    return 'Complete';
  } else if (offer.name.includes('pro')) {
    return 'Pro';
  } else if (offer.name.includes('smart')) {
    return 'Smart';
  } else if (offer.name.includes('lite')) {
    return 'Lite';
  }
  return '';
};

export const productTierForChannel = (channel: string) => {
  if (channel.includes('essential')) {
    return 'Essential';
  } else if (channel.includes('complete')) {
    return 'Complete';
  }
  return '';
};

export const productTier = (tier: string) => {
  if (tier.includes('pro')) {
    return 'Pro';
  } else if (tier.includes('smart')) {
    return 'Smart';
  } else if (tier.includes('lite')) {
    return 'Lite';
  }
  return '';
};

export const createCheckoutEcommerce = (checkoutData: CheckoutData) => {
  const {
    productDetails,
    paymentMethod,
    promoCode,
    brand,
    transactionId,
    promoCodeDetails,
  } = checkoutData;
  const price = promoCodeDetails?.discountedPrice;
  const discount = promoCodeDetails?.discountValue;
  const productPrice = promoCodeDetails?.productPrice || 0;
  const value = price || productPrice;

  return {
    transaction_id: transactionId || '',
    currency: promoCodeDetails?.currency || '',
    value,
    coupon: promoCode || '',
    payment_type: paymentMethod || '',
    items: [
      {
        item_id: productDetails?.name || '',
        item_name: 'AirHelpPlus Membership',
        affiliation: brand || '',
        coupon: promoCode || '',
        discount: discount || 0.0,
        index: 1,
        item_brand: 'AirHelp',
        item_category: '1YearMembership',
        item_category2: 'Subscription',
        item_variant: productTierForOffer(productDetails),
        price: value,
        quantity: 1,
      },
    ],
  };
};
