import React from 'react';
import { Text } from '@chakra-ui/react';

interface IComponent {
  title: string;
  content: string;
}

const Section: React.FC<IComponent> = ({ title, content }) => {
  return (
    <>
      <Text mb={4} fontSize="2xl" fontWeight="bold">
        {title}
      </Text>
      <Text mb={10}>{content}</Text>
    </>
  );
};

export default Section;
