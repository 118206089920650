import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { PAGE_PATHS } from 'config/routes';
import ChipGroup from 'elements/ChipGroup/ChipGroup';

enum ClaimTabs {
  PROGRESS = 'progress',
  COMPENSATION = 'compensation',
  DOCUMENTS = 'documents',
  DETAILS = 'details',
  HISTORY = 'history',
}

interface NavigationItem {
  eventName?: string;
  key: string;
  label: string;
  isActive: boolean;
  path: string;
  showNotification: boolean;
}

interface NavigationProps {
  showDocumentNotification: boolean;
}

export function Navigation({ showDocumentNotification }: NavigationProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { id: claimId } = useParams();
  const tabs: NavigationItem[] = [
    {
      key: ClaimTabs.PROGRESS,
      label: 'Claim Progress',
      isActive: location.pathname.endsWith(`${claimId}`),
      path: PAGE_PATHS.CLAIM.INDEX_PAGE,
      showNotification: false,
    },
    {
      key: ClaimTabs.COMPENSATION,
      label: 'Compensation',
      isActive: location.pathname.includes(ClaimTabs.COMPENSATION),
      path: PAGE_PATHS.CLAIM.COMPENSATION_PAGE,
      showNotification: false,
    },
    {
      key: ClaimTabs.DOCUMENTS,
      label: 'Documents',
      isActive: location.pathname.includes(ClaimTabs.DOCUMENTS),
      path: PAGE_PATHS.CLAIM.DOCUMENTS_PAGE,
      showNotification: showDocumentNotification,
    },
    {
      key: ClaimTabs.DETAILS,
      label: 'Details',
      isActive: location.pathname.includes(ClaimTabs.DETAILS),
      path: PAGE_PATHS.CLAIM.DETAILS_PAGE,
      showNotification: false,
    },
    {
      key: ClaimTabs.HISTORY,
      label: 'History',
      isActive: location.pathname.includes(ClaimTabs.HISTORY),
      path: PAGE_PATHS.CLAIM.HISTORY_PAGE,
      showNotification: false,
    },
  ];

  const onClick = (path: string) => {
    navigate(
      generatePath(path, {
        id: claimId,
      }),
    );
  };

  return (
    <ChipGroup
      maxWidth="100%"
      items={tabs}
      onItemClick={(item) => {
        onClick(item.path);
      }}
      testId="claim-navigation"
    />
  );
}
