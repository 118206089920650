import React from 'react';
import { VStack } from '@chakra-ui/react';
import { PageLoader } from 'elements';
import { type Journey } from 'models/journey/Journey';
import useFetchAirPayoutJourney from 'hooks/api/airBundle/useFetchAirPayoutJourney';
import useFetchAirLuggageJourney from 'hooks/api/airBundle/useFetchAirLuggageJourney';
import AirLuggageInsuranceView from './AirLuggageInsuranceView';
import AirPayoutInsuranceView from './AirPayoutInsuranceView';

const InsuranceView = ({ journey }: { journey: Journey }) => {
  const { hasAirLuggage, hasAirPayout } = journey;

  const {
    data: airPayoutJourneys,
    isPending: isPendingAirPayout,
    isFetching: isFetchingAirPayout,
  } = useFetchAirPayoutJourney({
    journeyId: journey.id,
    options: {
      enabled: hasAirPayout,
    },
  });

  const {
    data: airLuggageJourneys,
    isPending: isPendingAirLuggage,
    isFetching: isFetchingAirLuggage,
  } = useFetchAirLuggageJourney({
    journeyId: journey.id,
    options: {
      enabled: hasAirLuggage,
    },
  });
  if (
    (isPendingAirPayout || isFetchingAirPayout) &&
    (isPendingAirLuggage || isFetchingAirLuggage)
  ) {
    return <PageLoader />;
  }

  return (
    <VStack spacing={4} mb={4}>
      {airPayoutJourneys ? (
        <AirPayoutInsuranceView
          journey={journey}
          airPayoutJourneys={airPayoutJourneys}
        />
      ) : null}
      {airLuggageJourneys ? (
        <AirLuggageInsuranceView
          journey={journey}
          airLuggageJourneys={airLuggageJourneys}
        />
      ) : null}
    </VStack>
  );
};

export default InsuranceView;
