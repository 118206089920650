import React from 'react';

export const composeProviders =
  (...Providers) =>
  (Child) =>
  (props) =>
    Providers.reverse().reduce(
      (acc, [Provider, providerProps]) => {
        return <Provider {...providerProps}>{acc}</Provider>;
      },
      <Child {...props} />,
    );
