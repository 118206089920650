import { JourneyPage } from 'pages';
import * as JourneyPages from 'pages/Journey';

export const JourneyRoutes = {
  path: 'journey/:id',
  element: <JourneyPage />,
  children: [
    {
      index: true,
      element: <JourneyPages.IndexPage />,
    },
    { path: 'flights', element: <JourneyPages.FlightsPage /> },
    {
      path: 'insurance',
      element: <JourneyPages.InsurancePage />,
    },
  ],
};
