import cookie from 'react-cookies';
import { type UseQueryResult } from '@tanstack/react-query';
import { fetchLoungePasses } from '@airhelp/plus';
import { TURNSTILE_COOKIE } from 'config/cookies';
import { useProtectedQuery } from 'hooks/api/useProtectedQuery';
import { type LoungePass, transformToLoungePassClass } from 'models/LoungePass';
import { resetTurnstileToken } from 'utils/turnstile';
import { ahplusClient } from 'api/clients/clients';
import { type ApiQueryOptions } from 'hooks/api/queryHelper';

interface FetchLoungePassesResponse {
  loungePasses: LoungePass[];
}

const useFetchLoungePasses = (
  options?: ApiQueryOptions<FetchLoungePassesResponse, LoungePass[]>,
): UseQueryResult<LoungePass[]> => {
  const token = cookie.load(TURNSTILE_COOKIE);

  return useProtectedQuery<FetchLoungePassesResponse, LoungePass[]>({
    queryKey: ['userProfile', 'loungePasses'],
    queryFn: () =>
      fetchLoungePasses({ client: ahplusClient, params: { token } }),
    select: (data) =>
      data.loungePasses.map((loungePassData) =>
        transformToLoungePassClass(loungePassData),
      ),
    onSuccess: () => {
      resetTurnstileToken();
    },
    ...options,
  });
};

export default useFetchLoungePasses;
