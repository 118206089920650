import React, { useEffect, useState } from 'react';
import { StripePaymentStatues } from '@airhelp/plus';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import usePaymentStatus from 'hooks/api/payments/usePaymentStatus';
import { sentryUtils } from 'utils';
import PaymentStatusView from './PaymentStatusView';

const CHECKING_TIME_STAGE_1 = 2; // 2s
const CHECKING_TIME_STAGE_2 = 10; // 10s

const ProcessingPaymentView = ({
  paymentIntentId,
  succeededPath,
  tryAgainPath,
  onPaymentFailed,
  noLayoutWrap,
}: {
  paymentIntentId: string;
  succeededPath?: string;
  tryAgainPath?: string;
  onPaymentFailed?: () => void;
  noLayoutWrap?: boolean;
}) => {
  const { reloadProfile } = useMyProfile();
  const usePayment = usePaymentStatus();

  const [seconds, setSeconds] = useState(0);
  const [isStatusChecking, setIsStatusChecking] = useState(true);
  const [checkingStatusFrequency, setCheckingStatusFrequency] =
    useState<number>(CHECKING_TIME_STAGE_1);
  const [paymentStatus, setPaymentStatus] = useState<StripePaymentStatues>(
    StripePaymentStatues.REQUIRES_PAYMENT_METHOD,
  );
  const [stripePaymentError, setStripePaymentError] = useState(false);

  const processPayment = async () => {
    try {
      const response = await usePayment.mutateAsync({
        id: paymentIntentId,
      });
      const { status, errorCode } = response;

      if (status === StripePaymentStatues.SUCCEEDED) {
        await reloadProfile();
        setIsStatusChecking(false);
        setPaymentStatus(StripePaymentStatues.SUCCEEDED);
      }

      if (errorCode) {
        if (onPaymentFailed) {
          onPaymentFailed();
        }
        setIsStatusChecking(false);
        setStripePaymentError(true);
      }
    } catch (error) {
      if (onPaymentFailed) {
        onPaymentFailed();
      }
      setIsStatusChecking(false);
      setStripePaymentError(true);
      sentryUtils.captureException(error);
    }
  };

  useEffect(() => {
    if (isStatusChecking) {
      const intervalId = setInterval(() => {
        setSeconds((secondsValue) => secondsValue + 1);

        if (seconds > 0 && seconds % checkingStatusFrequency === 0) {
          processPayment();
        }

        if (seconds === CHECKING_TIME_STAGE_2) {
          setCheckingStatusFrequency(CHECKING_TIME_STAGE_2);
        }

        if (seconds > 60) {
          setIsStatusChecking(false);
        }
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [isStatusChecking, checkingStatusFrequency, seconds]);

  return (
    <PaymentStatusView
      paymentStatus={paymentStatus}
      stripePaymentError={stripePaymentError}
      seconds={seconds}
      tryAgainPath={tryAgainPath}
      succeededPath={succeededPath}
      onPaymentFailed={onPaymentFailed}
      noLayoutWrap={noLayoutWrap}
    />
  );
};

export default ProcessingPaymentView;
