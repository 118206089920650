import React, { useCallback } from 'react';
import { generatePath, Navigate, useSearchParams } from 'react-router-dom';
import { track } from 'react-tracking';
import { PAGE_PATHS } from 'config/routes';
import { usePageTracking } from 'utils/tracking';

const RedirectPage: React.FC = () => {
  usePageTracking();

  const findRedirectionTarget = useCallback(
    (searchParams: URLSearchParams, target?: string | null): string => {
      switch (target) {
        case null:
          return generatePath(PAGE_PATHS.HOME_PAGE);
        case 'claims':
          return generatePath(PAGE_PATHS.CLAIMS_PAGE);
        case 'lounges':
          return generatePath(PAGE_PATHS.LOUNGES_PAGE);
        case 'lounges_passes':
          return generatePath(PAGE_PATHS.LOUNGES.PASSES_PAGE);
        case 'trips_activate':
          return generatePath(PAGE_PATHS.TRIPS.ACTIVATE_PAGE, {
            token: searchParams.get('uuid') || '',
          });
        case 'memberships_renew':
          return generatePath(PAGE_PATHS.MEMBERSHIPS.BUY.CHECKOUT_PAGE);
        case 'membership_essential_smart_upgrade':
          return generatePath(PAGE_PATHS.MY_ACCOUNT.SPECIAL_OFFER_PAGE);
        case 'air_luggage':
          return generatePath(PAGE_PATHS.JOURNEY.AIR_LUGGAGE.PAYOUT_PAGE, {
            id: searchParams.get('journey_id') || '',
          });
        case 'air_payout':
          return generatePath(PAGE_PATHS.JOURNEY.AIR_PAYOUT.PAYOUT_PAGE, {
            id: searchParams.get('journey_id') || '',
          });
        case 'air_luggage_update':
          return generatePath(
            PAGE_PATHS.JOURNEY.AIR_LUGGAGE.PAYOUT_UPDATE_PAGE,
            {
              id: searchParams.get('journey_id') || '',
            },
          );
        case 'air_payout_update':
          return generatePath(
            PAGE_PATHS.JOURNEY.AIR_PAYOUT.PAYOUT_UPDATE_PAGE,
            {
              id: searchParams.get('journey_id') || '',
            },
          );
        case 'sunset_complete':
          return generatePath(PAGE_PATHS.MEMBERSHIPS.BUY.CHECKOUT_PAGE);
        default:
          return generatePath(PAGE_PATHS.HOME_PAGE);
      }
    },
    [],
  );

  const [searchParams] = useSearchParams();

  const target = searchParams.get('target');
  const redirectionTarget = findRedirectionTarget(searchParams, target);

  const redirectionSearch = new URLSearchParams(searchParams.toString());
  redirectionSearch.delete('target');

  return (
    <Navigate
      to={{
        pathname: redirectionTarget,
        search: redirectionSearch.toString(),
      }}
      replace
    />
  );
};

export default track({ page_type: 'Redirect' })(RedirectPage);
