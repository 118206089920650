import React, { type PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Airhelp } from '@airhelp/logos';
import { Box, Center, Flex, Text } from '@chakra-ui/react';
import { getYear } from 'date-fns';
import LanguageSwitcher from 'elements/LanguageSwitcher';

interface IComponent extends PropsWithChildren {
  cardView?: boolean;
}

const AuthenticationView: React.FC<IComponent> = ({ children, cardView }) => {
  const { t } = useTranslation();
  const copyrightDate = getYear(new Date());

  return (
    <Flex
      width="100%"
      minHeight="calc(100% - 21px)"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      position="relative"
    >
      <Flex padding={4} height="100%" width="100%" justifyContent="center">
        <Box
          padding={{ base: 4, lg: 8 }}
          width="100%"
          maxW={{ base: '100%', sm: '384px' }}
          backgroundColor={cardView ? 'greyscale.100' : 'greyscale.300'}
          borderRadius="xl"
          mb={8}
        >
          <Center>
            <Airhelp width="120px" height="auto" mb={12} />
          </Center>
          {children}
        </Box>
      </Flex>
      <Flex
        position="absolute"
        bottom={0}
        px={{ base: 4, sm: 0 }}
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        maxW={{ base: '100%', sm: '384px' }}
      >
        <Box my={-4} mx={-3}>
          <LanguageSwitcher />
        </Box>
        <Text fontSize="sm" color="greyscale.500">
          {t('common.en_copyright_short', { date: copyrightDate })}
        </Text>
      </Flex>
    </Flex>
  );
};

export default AuthenticationView;
