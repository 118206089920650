import React from 'react';
import { track } from 'react-tracking';
import NotFoundView from 'components/NotFoundView';
import { usePageTracking } from 'utils/tracking';

const NotFoundPage: React.FC = () => {
  usePageTracking();

  return <NotFoundView />;
};

export default track({ page_type: 'NotFound' })(NotFoundPage);
