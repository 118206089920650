import React, {
  useState,
  useEffect,
  useReducer,
  type PropsWithChildren,
  useRef,
  createContext,
} from 'react';
import cookie from 'react-cookies';
import { useSearchParams } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import {
  type EditableItinerary,
  type EditableJourney,
  type Journey,
} from '@airhelp/plus';
import { type HTTPClientError, isHTTPClientError } from '@airhelp/http-client';
import { TURNSTILE_COOKIE } from 'config/cookies';
import {
  editableJourneyReducer,
  generateEmptyJourney,
  type Action,
} from 'reducers/editableJourney';
import { ERROR_CODES } from 'types/api';
import { sentryUtils } from 'utils';
import { resetTurnstileToken } from 'utils/turnstile';
import useAddJourney from 'hooks/api/journey/useAddJourney';
import { sanitizeJourney } from './journeys';

const DEFAULT_ROUTE_FROM = 'dashboard';

interface IEditedJourneyContext {
  journey: EditableJourney;
  editedItinerary: EditableItinerary;
  itineraryIndex: number;
  fromRoute: string;
  isReturnTrip: boolean;
  dispatch: React.Dispatch<Action>;
  saveJourney: () => Promise<SaveJourneyResponse>;
  previousItineraryIndex: number | null;
  setPreviousItineraryIndex: React.Dispatch<
    React.SetStateAction<number | null>
  >;
}

const defaultContextJourney = generateEmptyJourney();

export const DefaultEditedJourneyContext = {
  journey: defaultContextJourney,
  editedItinerary: defaultContextJourney.itineraries[0],
  itineraryIndex: 0,
  isReturnTrip: false,
  fromRoute: DEFAULT_ROUTE_FROM,
  dispatch: () => null,
  saveJourney: async () =>
    Promise.resolve({ errors: null, journey: null, errorCode: null }),
  previousItineraryIndex: 0,
  setPreviousItineraryIndex: () => null,
};

export const EditedJourneyContext = createContext<IEditedJourneyContext>(
  DefaultEditedJourneyContext,
);

interface IComponent extends PropsWithChildren {
  editedJourney?: EditableJourney;
}

interface SaveJourneyResponse {
  journey: Journey | null;
  errors: HTTPClientError | null;
  errorCode: ERROR_CODES | null;
}

const EditedJourneyContextProvider: React.FC<IComponent> = ({
  children,
  editedJourney,
}) => {
  const [previousItineraryIndex, setPreviousItineraryIndex] = useState<
    number | null
  >(null);
  const initialJourney = editedJourney || generateEmptyJourney();
  const turnstileToken = cookie.load(TURNSTILE_COOKIE);
  const addJourney = useAddJourney();
  const [journey, dispatch] = useReducer(
    editableJourneyReducer,
    initialJourney,
  );

  const tracking = useTracking();

  const [searchParams] = useSearchParams();
  const fromRoute = searchParams.get('src') ?? DEFAULT_ROUTE_FROM;
  const itineraryIndexFromParams = searchParams.get('itineraryIndex');
  const itineraryIndex = itineraryIndexFromParams
    ? parseInt(itineraryIndexFromParams)
    : 0;
  const isReturnTrip = itineraryIndex > 0;

  const editedItinerary =
    journey.itineraries[itineraryIndex] || initialJourney.itineraries[0];

  const saveJourney = async (): Promise<SaveJourneyResponse> => {
    const journeyData = sanitizeJourney(journey);
    try {
      const response = await addJourney.mutateAsync({
        token: turnstileToken,
        payload: journeyData,
      });

      resetTurnstileToken();
      tracking.trackEvent({ name: 'tripAdded' });

      return { journey: response, errors: null, errorCode: null };
    } catch (error) {
      const tags = { action: 'addJourney' };
      sentryUtils.captureException(error, tags);
      resetTurnstileToken();
      if (isHTTPClientError(error)) {
        const errorResponse = error.json;

        return {
          journey: null,
          errors: errorResponse.errors,
          errorCode: errorResponse.error_code || 'UNKNOWN_ERROR',
        };
      }
      return {
        journey: null,
        errors: error as HTTPClientError,
        errorCode: ERROR_CODES.UNKNOWN,
      };
    }
  };

  const journeyUpdateRef = useRef<EditableJourney | null>(null);

  useEffect(() => {
    if (journeyUpdateRef.current !== journey) {
      journeyUpdateRef.current = journey;
    }
  }, [journey.itineraries]);

  return (
    <EditedJourneyContext.Provider
      value={{
        journey,
        editedItinerary,
        itineraryIndex,
        isReturnTrip,
        fromRoute,
        saveJourney,
        dispatch,
        previousItineraryIndex,
        setPreviousItineraryIndex,
      }}
    >
      {children}
    </EditedJourneyContext.Provider>
  );
};

export default EditedJourneyContextProvider;
