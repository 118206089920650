import { useState, useEffect } from 'react';
import { differenceInMinutes } from 'date-fns';
import cookie from 'react-cookies';
import { UPGRADE_MODAL_DISMISSED_COOKIE } from 'config/cookies';
import { useTrackEvent } from 'utils/tracking/hooks';

interface UseUpgradeModalProps {
  canLoadBanner: boolean;
}

const DAY_IN_MINUTES = 24 * 60;

const useUpgradeModal = ({ canLoadBanner }: UseUpgradeModalProps) => {
  const [open, setOpen] = useState(false);
  const { trackPageInteractions } = useTrackEvent();

  const trackUpgradeShow = () => {
    trackPageInteractions('show', 'essential to smart pop up', 'dashboard');
  };

  const checkModalInitialState = () => {
    if (canLoadBanner) {
      const dismissedAt = cookie.load(UPGRADE_MODAL_DISMISSED_COOKIE) as string;
      // if the cookie is missing, show the modal
      if (!dismissedAt) {
        trackUpgradeShow();
        setOpen(true);
      }

      if (dismissedAt) {
        const dismissedDate = new Date(dismissedAt);
        const now = new Date();
        const diff = differenceInMinutes(now, dismissedDate);

        // if the cookie is older than 24hours, show the modal
        if (diff >= DAY_IN_MINUTES) {
          trackUpgradeShow();
          setOpen(true);
        }
      }
    }
  };

  const onClose = () => {
    setOpen(false);
    cookie.save(UPGRADE_MODAL_DISMISSED_COOKIE, new Date().toISOString());
  };

  useEffect(() => {
    checkModalInitialState();
  }, [canLoadBanner]);

  return { open, onClose };
};

export default useUpgradeModal;
