import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { VStack, Image, Text, Button } from '@chakra-ui/react';
import errorBuka from 'assets/images/error-buka@2x.png';
import { PAGE_PATHS } from 'config/routes';

const ErrorView = ({
  goBack,
}: {
  goBack?: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goBackFunction = () => {
    if (goBack) {
      return goBack;
    }
    return () => {
      navigate(generatePath(PAGE_PATHS.HOME_PAGE));
    };
  };

  return (
    <VStack w="100%" alignContent="center" spacing={0} pt={{ base: 6, lg: 20 }}>
      <Image src={errorBuka} alt="error" w="256px" />
      <VStack spacing={0}>
        <Text fontSize="3xl" fontWeight="700" lineHeight={9} textAlign="center">
          {t('errors.page_flown_away')}
        </Text>
      </VStack>
      <Text fontSize="md" p={8} lineHeight="24px">
        {t('errors.head_back')}
      </Text>
      <Button onClick={goBackFunction()} w={{ base: '100%', md: 'initial' }}>
        {t('common.go_back')}
      </Button>
    </VStack>
  );
};

export default ErrorView;
