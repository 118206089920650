import React, { useContext, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { track } from 'react-tracking';
import AirLuggageView from 'components/Flights/New/AirLuggageView';
import { PAGE_PATHS } from 'config/routes';
import { EditedJourneyContext } from 'contexts';
import { type ERROR_CODES } from 'types/api';
import { usePageTracking } from 'utils/tracking';
import { useTrackEvent } from 'utils/tracking/hooks';
import useProtectedPage from 'hooks/routing/useProtectedPage';

export type AirLuggageValueType = 'yes' | 'no';

const AirLuggagePage = () => {
  usePageTracking();
  useProtectedPage();
  const { trackAddFlightFunnel, trackCtaClick } = useTrackEvent();

  const navigate = useNavigate();
  const { saveJourney, itineraryIndex } = useContext(EditedJourneyContext);
  const [apiErrorCode, setApiErrorCode] = useState<ERROR_CODES | null>(null);
  const [isSavingJourney, setIsSavingJourney] = useState(false);
  const [hasAirLuggageValue, setHasAirLuggageValue] =
    useState<AirLuggageValueType>('yes');

  const nextStep = async () => {
    setIsSavingJourney(true);
    const result = await saveJourney();
    if (!result.errors && result.journey) {
      navigate(
        generatePath(PAGE_PATHS.JOURNEY.FLIGHTS_PAGE, {
          id: result.journey.id,
        }),
        {
          state: {
            showAirPayoutSubscriptionModal: true,
          },
        },
      );

      trackAddFlightFunnel('add this flight');
    } else if (result.errors && result.errorCode) {
      setApiErrorCode(result.errorCode);
    }

    setIsSavingJourney(false);
  };

  const previousStep = () => {
    navigate(-1);
    trackCtaClick(
      'back',
      `${itineraryIndex === 0 ? 'outbound' : 'inbound'} - air luggage`,
    );
  };

  return (
    <AirLuggageView
      onNextStep={nextStep}
      onPreviousStep={previousStep}
      isSavingJourney={isSavingJourney}
      apiErrorCode={apiErrorCode}
      setHasAirLuggageValue={setHasAirLuggageValue}
      hasAirLuggageValue={hasAirLuggageValue}
    />
  );
};

export default track({ page_type: 'Flights.New.AirLuggage' })(AirLuggagePage);
