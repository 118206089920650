import React from 'react';
import { createRoot } from 'react-dom/client';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'reflect-metadata';
import 'styles/index.scss';
import App from './App';

const container = document.getElementById('ahplus-root');
const root = createRoot(container as Element);
root.render(<App />);
