import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@chakra-ui/react';
import useEffectOnce from 'hooks/useEffectOnce';
import { useTrackEvent } from 'utils/tracking/hooks';
import { type JourneyCheckDeletable } from 'models/journey/JourneyCheckDeletable';

const DeleteModalContent = ({
  checkDeletableData,
}: {
  checkDeletableData: JourneyCheckDeletable;
}) => {
  const { t } = useTranslation();
  const { trackPageInteractions } = useTrackEvent();

  const {
    deletable,
    loungesIncluded,
    blockedByB2b,
    blockedByAirLuggage,
    blockedByAirPayout,
    blockedByLounges,
  } = checkDeletableData;

  const blockedByInsurance = blockedByAirLuggage || blockedByAirPayout;

  const getTextMsg = () => {
    if (!deletable) {
      if (blockedByB2b) {
        return t('journeys.delete_modal.contact_your_travel_agency');
      }
      if (blockedByLounges) {
        return t('journeys.delete_modal.protections_have_been_activated');
      }
      if (blockedByInsurance) {
        return t('journeys.delete_modal.your_trip_is_due_to_depart');
      }
    }

    if (loungesIncluded) {
      return `${t('journeys.delete_modal.this_trip_was_insured')}\n\n${t(
        'journeys.delete_modal.you_will_not_receive_lounge_pass',
      )}`;
    }
    return t('journeys.delete_modal.this_trip_was_insured');
  };

  const trackPlacement = deletable
    ? 'delete trip question'
    : 'delete trip information';

  useEffectOnce(() => {
    trackPageInteractions('show', trackPlacement);
  });

  return (
    <>
      <Heading
        variant="h5"
        lineHeight="short"
        mb={4}
        data-testid="delete-modal-content-header"
      >
        {deletable
          ? t('journeys.delete_modal.header_can_delete_trip')
          : t('journeys.delete_modal.header_can_not_delete_trip')}
      </Heading>
      <Text
        fontSize="md"
        mb={4}
        whiteSpace="break-spaces"
        lineHeight="initial"
        data-testid="delete-modal-content-text"
      >
        {getTextMsg()}
      </Text>
    </>
  );
};

export default DeleteModalContent;
