import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  triggerAirPayoutJourneys,
  type TriggerAirPayoutsForJourneyParams,
} from '@airhelp/plus';
import { ahplusClient } from 'api/clients/clients';

const useTriggerAirPayoutJourneys = () => {
  const queryClient = useQueryClient();

  const useTriggerAirPayoutJourneysMutation = useMutation({
    mutationFn: (params: TriggerAirPayoutsForJourneyParams) =>
      triggerAirPayoutJourneys({
        client: ahplusClient,
        params,
      }),
    onSuccess: async (data, params) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['journey', { id: params.journeyId }],
        }),
        queryClient.invalidateQueries({
          queryKey: ['journey', 'air-payouts', { journeyId: params.journeyId }],
        }),
        queryClient.setQueryData(['journey', { id: params.journeyId }], {
          ...data,
        }),
      ]);
    },
  });

  return useTriggerAirPayoutJourneysMutation;
};

export default useTriggerAirPayoutJourneys;
