import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { policyUrl } from 'utils/insurancePolicy';
import { type Journey } from 'models/journey/Journey';
import { type AirLuggageJourney } from 'models/journey/AirLuggageJourney';
import AirBundleThead from '../AirBundleThead';
import { PolicyButton } from '../HeaderContent';
import Header from './Header';
import AirLuggageInsuranceViewItem from './AirLuggageInsuranceViewItem';

const AirLuggageInsuranceView = ({
  journey,
  airLuggageJourneys,
}: {
  journey: Journey;
  airLuggageJourneys: AirLuggageJourney[];
}) => {
  const { mainAirLuggageJourney } = journey;

  const ipidVersion = mainAirLuggageJourney?.ipidVersion;
  const linkToFile = policyUrl(false, ipidVersion);

  return (
    <Box
      p={{ base: 4, md: 8 }}
      background="greyscale.100"
      borderRadius="lg"
      width="100%"
    >
      <Header
        airLuggageJourney={airLuggageJourneys[0]}
        linkToFile={linkToFile}
      />
      <Box
        p={{ base: 0, md: 4 }}
        background={{ base: 'greyscale.100', md: 'greyscale.300' }}
        borderRadius="lg"
      >
        {/* DesktopView only */}
        <AirBundleThead />
        <Flex flexDirection="column">
          {airLuggageJourneys.map((airLuggageJourney, index) => {
            const lastElement = index + 1 === airLuggageJourneys.length;

            return (
              <AirLuggageInsuranceViewItem
                key={airLuggageJourney.id}
                lastElement={lastElement}
                airLuggageJourney={airLuggageJourney}
              />
            );
          })}
        </Flex>
      </Box>
      <Box display={{ base: 'block', md: 'none' }} mt={2}>
        <PolicyButton linkToFile={linkToFile} />
      </Box>
    </Box>
  );
};

export default AirLuggageInsuranceView;
