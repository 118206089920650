import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  VStack,
  Heading,
  UnorderedList,
  ListItem,
  Link,
} from '@chakra-ui/react';
import { useTranslation, Trans } from 'react-i18next';

interface FAQItem {
  question: string;
  children: React.ReactNode;
}

function FAQParagraph({
  children,
  withMarginBottom = true,
}: {
  children: React.ReactNode;
  withMarginBottom?: boolean;
}) {
  const marginBottom = withMarginBottom ? { base: 4, md: 5 } : 0;

  return (
    <Text fontSize="md" color="primary.900" mb={marginBottom}>
      {children}
    </Text>
  );
}

function FAQItem({ question, children }: FAQItem) {
  return (
    <AccordionItem>
      <AccordionButton>
        <Text fontWeight="medium" fontSize="md" w="full" textAlign="left">
          {question}
        </Text>
        <AccordionIcon color="primary.500" />
      </AccordionButton>
      <AccordionPanel>{children}</AccordionPanel>
    </AccordionItem>
  );
}

export default function FAQ() {
  const { t } = useTranslation();

  return (
    <VStack
      spacing={4}
      alignItems="stretch"
      py={{ base: 0, md: 20 }}
      px={{ base: 4, md: 6 }}
      maxW="976px"
      width="100%"
    >
      <Heading as="h3" fontSize="30px">
        {t('faq.title')}
      </Heading>
      <Accordion allowToggle w={{ base: 'full' }} mx="auto">
        <FAQItem question={t('faq.how_does_lounge_work.question')}>
          <FAQParagraph>{t('faq.how_does_lounge_work.answer_p1')}</FAQParagraph>
          <FAQParagraph>
            <Trans
              i18nKey="faq.how_does_lounge_work.answer_p2"
              values={{ dashboardUrl: '/' }}
              components={{
                a: (
                  <Link
                    fontSize="md"
                    isExternal
                    color="primary.500"
                    _visited={{
                      color: 'primary.500',
                    }}
                  />
                ),
              }}
            />
          </FAQParagraph>
          <FAQParagraph>{t('faq.how_does_lounge_work.answer_p3')}</FAQParagraph>
          <FAQParagraph withMarginBottom={false}>
            <Trans
              i18nKey="faq.how_does_lounge_work.answer_p4"
              values={{ loungesUrl: '/lounge-finder' }}
              components={{
                a: (
                  <Link
                    fontSize="md"
                    isExternal
                    color="primary.500"
                    _visited={{
                      color: 'primary.500',
                    }}
                  />
                ),
              }}
            />
          </FAQParagraph>
        </FAQItem>
        <FAQItem question={t('faq.what_is_airpayout.question')}>
          <FAQParagraph>{t('faq.what_is_airpayout.answer_p1')}</FAQParagraph>
          <FAQParagraph>{t('faq.what_is_airpayout.answer_p2')}</FAQParagraph>
          <UnorderedList
            mb={{
              base: 4,
              md: 5,
            }}
          >
            <ListItem fontSize="md" color="primary.900">
              {t('faq.what_is_airpayout.answer_p2_list.delayed_by')}
            </ListItem>
            <ListItem fontSize="md" color="primary.900">
              {t('faq.what_is_airpayout.answer_p2_list.canceled_in')}
            </ListItem>
            <ListItem fontSize="md" color="primary.900">
              {t('faq.what_is_airpayout.answer_p2_list.diverted')}
            </ListItem>
          </UnorderedList>
          <FAQParagraph withMarginBottom={false}>
            {t('faq.what_is_airpayout.answer_p3')}
          </FAQParagraph>
        </FAQItem>
        <FAQItem question={t('faq.what_is_airluggage.question')}>
          <FAQParagraph>{t('faq.what_is_airluggage.answer_p1')}</FAQParagraph>
          <UnorderedList>
            <ListItem fontSize="md" color="primary.900">
              {t('faq.what_is_airluggage.answer_p1_list.delayed_or_lost')}
            </ListItem>
            <ListItem fontSize="md" color="primary.900">
              {t('faq.what_is_airluggage.answer_p1_list.obtain_pir_number')}
            </ListItem>
            <ListItem fontSize="md" color="primary.900">
              {t('faq.what_is_airluggage.answer_p1_list.payout_on_top')}
            </ListItem>
          </UnorderedList>
        </FAQItem>
        <FAQItem question={t('faq.what_happen_when_upgrade.question')}>
          <FAQParagraph withMarginBottom={false}>
            {t('faq.what_happen_when_upgrade.answer_p1')}
          </FAQParagraph>
        </FAQItem>
        <FAQItem question={t('faq.what_is_trip.question')}>
          <FAQParagraph withMarginBottom={false}>
            {t('faq.what_is_trip.answer_p1')}
          </FAQParagraph>
        </FAQItem>
        <FAQItem question={t('faq.what_happens_to_added_trips.question')}>
          <FAQParagraph>
            {t('faq.what_happens_to_added_trips.answer_p1')}
          </FAQParagraph>
          <FAQParagraph>
            {t('faq.what_happens_to_added_trips.answer_p2')}
          </FAQParagraph>
          <FAQParagraph withMarginBottom={false}>
            {t('faq.what_happens_to_added_trips.answer_p3')}
          </FAQParagraph>
        </FAQItem>
      </Accordion>
    </VStack>
  );
}
