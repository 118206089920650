import React, { createContext, type RefObject, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import {
  Crossroad,
  Panels,
  PanelsFilled,
  Chair,
  ChairFilled,
  Shield,
  ShieldFilled,
} from '@airhelp/icons';
import { Box, Grid, Portal } from '@chakra-ui/react';
import { type UserPolicy } from '@airhelp/plus';
import { PAGE_PATHS } from 'config/routes';
import { TrackingContext } from 'contexts';
import { useTrackEvent } from 'utils/tracking/hooks';
import useFetchLoungePasses from 'hooks/api/userProfiles/useFetchLoungePasses/useFetchLoungePasses';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import NavItem from './NavItem';
import { type NavBarItem } from './NavItem/NavItem';

enum NavigationElements {
  HOME = 'Home',
  JOURNEYS = 'Journeys',
  CLAIMS = 'Claims',
  LOUNGES = 'Lounges',
}

interface IComponent {
  userProfilePolicy?: UserPolicy;
}

const NavigationBar: React.FC<IComponent> = ({ userProfilePolicy }) => {
  const tracking = useTracking();
  const { t } = useTranslation();
  const { trackCtaClick } = useTrackEvent();
  const { subPageType } = useContext(TrackingContext);
  const location = useLocation();
  const { data: loungePasses } = useFetchLoungePasses();
  const { userProfile } = useMyProfile();
  const { dashboard } = userProfilePolicy || {};
  const { portalRef } = useNavigationBar();

  const upsellEnabled = dashboard?.upsellEnabled;
  const loungesEnabled =
    dashboard?.loungesPerkEnabled || (loungePasses || []).length > 0;

  const showTopBannerEssentialUpgrade = Boolean(
    userProfile?.policy?.membership?.isEssential,
  );

  const showPaymentManagementBanner = Boolean(
    userProfile?.policy.membership?.showPaymentManagementBanner,
  );

  const trackClickEvent = (element: NavigationElements, label: string) => {
    tracking.trackEvent({
      element,
      eventCategory: 'ahplus',
      eventAction: 'clicked',
      eventLabel: 'ahplus_dashboard_navigation_clicked',
    });
    trackCtaClick(label, subPageType);
  };

  const navBarItems: NavBarItem[] = [
    {
      label: t('navigation.home'),
      icon: Panels,
      hoverIcon: PanelsFilled,
      route: generatePath(PAGE_PATHS.HOME_PAGE),
      action: () => {
        trackClickEvent(NavigationElements.HOME, 'home section');
      },
      dataTestId: 'home',
    },
    {
      label: t('navigation.trips'),
      icon: Crossroad,
      hoverIcon: Crossroad,
      route: generatePath(PAGE_PATHS.JOURNEYS_PAGE),
      action: () => {
        trackClickEvent(NavigationElements.JOURNEYS, 'journeys section');
      },
      dataTestId: 'journeys',
    },
    {
      label: t('navigation.claims'),
      icon: Shield,
      hoverIcon: ShieldFilled,
      route: generatePath(PAGE_PATHS.CLAIMS_PAGE),
      action: () => {
        trackClickEvent(NavigationElements.CLAIMS, 'claims section');
      },
      dataTestId: 'claims',
    },
  ];

  if (loungesEnabled || upsellEnabled) {
    navBarItems.push({
      label: t('navigation.lounges'),
      icon: Chair,
      hoverIcon: ChairFilled,
      route: generatePath(PAGE_PATHS.LOUNGES_PAGE),
      action: () => {
        trackClickEvent(NavigationElements.LOUNGES, 'lounges section');
      },
      dataTestId: 'lounges',
    });
  }

  const getMarginTop = () => {
    const bannerCount =
      Number(showTopBannerEssentialUpgrade) +
      Number(showPaymentManagementBanner);

    switch (bannerCount) {
      case 2:
        return '300px';
      case 1:
        return '200px';
      default:
        return '160px';
    }
  };

  return (
    <Box
      as="nav"
      backgroundColor="greyscale.100"
      height={{ base: '78px', lg: '100%' }}
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      paddingTop={0}
      textAlign={{ base: 'center', lg: 'left' }}
      borderTop={{ base: '1px solid', lg: 0 }}
      borderTopColor="greyscale.400"
      width={{ base: '100%', lg: '278px' }}
      justifyContent="inherit"
      boxShadow="light"
      zIndex={{ base: '1000', md: '1' }}
    >
      <Grid
        h="100%"
        m={{ base: 'auto', lg: `${getMarginTop()} 0 0 0` }}
        p={{ base: '10px 11px', lg: 0 }}
        w={{ base: '92%', sm: '400px', md: '510px', lg: 'initial' }}
        gridTemplateColumns={{ base: 'repeat(4, minmax(0, 1fr))', lg: '1fr' }}
        alignItems={{ base: 'center', lg: 'initial' }}
        alignContent={{ base: 'unset', lg: 'flex-start' }}
        justifyContent={{ base: 'space-between', lg: 'flex-start' }}
        overflowX={{ base: 'scroll', lg: 'initial' }}
        gap={{ base: 2, lg: 8 }}
        css={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {navBarItems.map((item) => (
          <NavItem item={item} key={`${item.label}.${location.pathname}`} />
        ))}
      </Grid>
      <Box ref={portalRef} pos="absolute" bottom={0} left={0} w="full" />
    </Box>
  );
};

const NavigationBarContext = createContext({
  portalRef: undefined as RefObject<HTMLDivElement> | undefined,
});

export const NavigationBarProvider = NavigationBarContext.Provider;

export function useNavigationBar() {
  const { portalRef } = useContext(NavigationBarContext);
  return {
    portalRef,
    NavigationBarPortal: ({ children }) => (
      <Portal containerRef={portalRef} appendToParentPortal={false}>
        {children}
      </Portal>
    ),
  };
}

export default NavigationBar;
