import { Expose } from 'class-transformer';

export interface IDashboard {
  canAddFellowPassengersToFlight: boolean;
  canAddTrip: boolean;
  loungesPerkEnabled: boolean;
  dashboardVisited: boolean;
  personalDetailsProvided: boolean;
  upsellEnabled: boolean;
}

export class Dashboard implements IDashboard {
  @Expose() canAddFellowPassengersToFlight: boolean;
  @Expose() canAddTrip: boolean;
  @Expose() loungesPerkEnabled: boolean;
  @Expose() dashboardVisited: boolean;
  @Expose() personalDetailsProvided: boolean;
  @Expose() upsellEnabled: boolean;
}
