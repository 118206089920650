import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { track } from 'react-tracking';
import PaymentSuccess from 'components/Payment/PaymentSuccess';
import { PAGE_PATHS } from 'config/routes';
import { useLayout } from 'contexts/LayoutContext/LayoutContext';
import useEffectOnce from 'hooks/useEffectOnce';
import { usePageTracking } from 'utils/tracking';

const PaymentSucceededPage = () => {
  usePageTracking();
  const { setActiveStep } = useLayout();

  const navigate = useNavigate();

  useEffectOnce(() => {
    setActiveStep(3);
    const timeoutRef = setTimeout(() => {
      navigate(generatePath(PAGE_PATHS.HOME_PAGE));
    }, 1875);
    return () => {
      clearTimeout(timeoutRef);
    };
  });

  return <PaymentSuccess />;
};

export default track({ page_type: 'Trips.Upgrade.PaymentSucceeded' })(
  PaymentSucceededPage,
);
