import { type Locale } from 'contexts/LocaleContext/locales';

const affiliateLinks = {
  en: '1268485',
  da: '1914929',
  de: '1527459',
  es: '1818016',
  el: '1818026',
  fi: '1914932',
  fr: '1527464',
  it: '1527471',
  nl: '1665695',
  nb: '1917581',
  pl: '1527467',
  pt: '1818040',
  'pt-BR': '1917584',
  ro: '1914931',
  ru: '1562775',
  sv: '1914930',
  tr: '1803526',
  'zh-CN': '1818044',
};

export const getAiraloLinkByLocale = (locale: Locale) => {
  const link = affiliateLinks[locale] || affiliateLinks.en;

  const airaloLink = `https://airalo.pxf.io/c/4862139/${link}/15608`;

  return airaloLink;
};
