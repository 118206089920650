import React from 'react';
import { type IconType } from '@airhelp/icons';
import {
  Center,
  HStack,
  VStack,
  Text,
  type StackProps,
} from '@chakra-ui/react';

type IComponent = {
  city: string;
  iata: string;
  Icon?: IconType;
  reverseLayout?: boolean;
} & StackProps;

const AirportBadge: React.FC<IComponent> = ({
  city,
  iata,
  Icon,
  reverseLayout = false,
  ...props
}) => {
  return (
    <HStack spacing={3} w="50%" {...props}>
      {Icon ? (
        <Center bgColor="primary.100" boxSize="44px" borderRadius="md">
          <Icon color="primary.500" bgColor="primary.100" boxSize={5} />
        </Center>
      ) : null}
      {reverseLayout ? (
        <VStack align="start" spacing={0}>
          <Text
            fontSize="xl"
            color="primary.900"
            fontWeight="bold"
            data-testid="airport-code"
          >
            {iata}
          </Text>
          <Text
            fontSize="sm"
            color="greyscale.600"
            noOfLines={1}
            data-testid="airport-city"
          >
            {city}
          </Text>
        </VStack>
      ) : (
        <VStack align="start" spacing={0}>
          <Text
            fontSize="md"
            fontWeight="medium"
            noOfLines={1}
            data-testid="airport-city"
          >
            {city}
          </Text>
          <Text fontSize="md" color="greyscale.600" data-testid="airport-code">
            {iata}
          </Text>
        </VStack>
      )}
    </HStack>
  );
};

export default AirportBadge;
