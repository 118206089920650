import React from 'react';
import { useParams, Navigate, generatePath } from 'react-router-dom';
import { track } from 'react-tracking';
import { PAGE_PATHS } from 'config/routes';
import { usePageTracking } from 'utils/tracking';

interface Params extends Record<string, string | undefined> {
  id: string;
}

const IndexPage = () => {
  usePageTracking();
  const { id } = useParams<Params>();

  if (!id) {
    return <Navigate to={{ pathname: generatePath(PAGE_PATHS.HOME_PAGE) }} />;
  }

  return (
    <Navigate
      to={{ pathname: generatePath(PAGE_PATHS.JOURNEY.FLIGHTS_PAGE, { id }) }}
    />
  );
};

export default track({ page_type: 'Journey.Index' })(IndexPage);
