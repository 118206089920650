import React from 'react';
import { track } from 'react-tracking';
import FunnelLayout from 'components/Layouts/FunnelLayout';
import ErrorPageView from 'elements/ErrorView';
import { usePageTracking } from 'utils/tracking';

const ErrorPage = () => {
  usePageTracking();

  return (
    <FunnelLayout steps={[]} disableCurrencySwitcher disableAssistantBadge>
      <ErrorPageView />
    </FunnelLayout>
  );
};

export default track({ page_type: 'Error' })(ErrorPage);
