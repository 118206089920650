import { MyAccountPage } from 'pages';
import * as MyAccountPages from 'pages/MyAccount';

export const MyAccountRoutes = {
  path: 'my-account',
  element: <MyAccountPage />,
  children: [
    {
      index: true,
      element: <MyAccountPages.IndexPage />,
    },
    {
      path: 'special-offer',
      element: <MyAccountPages.SpecialOfferPage />,
    },
    { path: 'details', element: <MyAccountPages.DetailsPage /> },
    {
      path: 'notifications',
      element: <MyAccountPages.NotificationsPage />,
    },
    {
      path: 'benefits',
      element: <MyAccountPages.BenefitsPage />,
    },
    {
      path: 'billing',
      children: [
        {
          index: true,
          element: <MyAccountPages.BillingPage />,
        },
        {
          path: 'processing',
          element: <MyAccountPages.CardProcessingPage />,
        },
      ],
    },
  ],
};
