import { Box, Flex } from '@chakra-ui/react';

const Dot = () => {
  return (
    <Box
      backgroundColor="primary.100"
      width="6px"
      height="6px"
      borderRadius="xl"
      me={2}
    />
  );
};

const Dots = () => {
  return (
    <Flex>
      <Dot />
      <Dot />
      <Dot />
      <Dot />
    </Flex>
  );
};

export default Dots;
