import React, { type PropsWithChildren } from 'react';
import { AuthHTTPClientProvider } from '@airhelp/auth/react';
import { AhPlusHTTPClientProvider } from '@airhelp/plus/react';
import { WebappHTTPClientProvider } from '@airhelp/webapp/react';
import { ahplusClient, authClient, webappClient } from './clients';

const HTTPClientProviders = ({ children }: PropsWithChildren) => {
  return (
    <AuthHTTPClientProvider client={authClient}>
      <WebappHTTPClientProvider client={webappClient}>
        <AhPlusHTTPClientProvider client={ahplusClient}>
          {children}
        </AhPlusHTTPClientProvider>
      </WebappHTTPClientProvider>
    </AuthHTTPClientProvider>
  );
};

export default HTTPClientProviders;
