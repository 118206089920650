import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { Lounge } from '@airhelp/icons';
import { Box, Button, Flex, HStack } from '@chakra-ui/react';
import { PAGE_PATHS } from 'config/routes';
import { useTrackEvent } from 'utils/tracking/hooks';

interface IComponent {
  tripUuid: string;
}

const TripUpgradeBox: React.FC<IComponent> = ({ tripUuid }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { trackUpsellOption } = useTrackEvent();

  const upsellBannerCopy = {
    title: t('dashboard.add_airport_lounge_access'),
    upgrade: t('common.upgrade'),
  };

  const onUpgrade = () => {
    navigate(
      `${generatePath(PAGE_PATHS.UPSELL.TRIPS_PAGE, { token: tripUuid })}?channel=journey_details_page_upgrade`,
    );
    trackUpsellOption('get per trip complete', 'trip details page');
  };

  return (
    <>
      {/* flex is used to distribute content in a row direction on desktop, and column direction on mobile */}
      <Flex
        px={4}
        py={3}
        my={4}
        background="primary.200"
        border="1px solid"
        borderColor="primary.300"
        borderRadius="xl"
        justifyContent="space-between"
        alignItems={{ base: 'flex-start', md: 'center' }}
        flexDirection={{ base: 'column', md: 'row' }}
      >
        {/* icon and copy part */}
        <HStack alignItems="center" spacing={4}>
          <Box
            p="3"
            background="greyscale.100"
            border="1px transparent"
            borderRadius="md"
          >
            <Lounge color="primary.500" h={6} w="auto" />
          </Box>

          <Box fontWeight="medium" fontSize="s">
            {upsellBannerCopy.title}
          </Box>
        </HStack>

        {/* button part */}
        <Box width={{ base: '100%', md: 'auto' }} mt={{ base: 4, md: 0 }}>
          <Button onClick={onUpgrade} size="s" width="100%">
            {upsellBannerCopy.upgrade}
          </Button>
        </Box>
      </Flex>
    </>
  );
};

export default TripUpgradeBox;
