import React from 'react';
import Slider from 'react-slick';
import {
  Box,
  useBreakpointValue,
  Text,
  VStack,
  Image,
  Heading,
  Flex,
} from '@chakra-ui/react';
import { useTranslation, Trans } from 'react-i18next';
import airportTransfersHero from 'assets/images/perks/hero-airport-transfer.png';
import rentalCarsHero from 'assets/images/perks/hero-car-rental.png';
import { partnerDealsSettings } from '../carousel';

interface PartnerCardProps {
  title: string | React.ReactNode;
  description: string;
  image: string;
  alt: string;
  hideBorder?: boolean;
}

function PartnerCard({
  title,
  description,
  image,
  alt,
  hideBorder = false,
}: PartnerCardProps) {
  return (
    <Flex
      width={{ base: 'calc(100% - 8px)', md: 'calc(100% - 16px)', xl: 'unset' }}
      height={{ base: '100%', xl: 'auto' }}
      mx={{ base: 0, xl: 4 }}
      justifyContent="center"
    >
      <Box
        maxW={{
          base: '100%',
          md: '404px',
        }}
        width="100%"
        borderRadius="lg"
        border={hideBorder ? 'none' : '1px solid #dee5ed'}
        mx="auto"
      >
        <Image borderTopRadius="lg" src={image} alt={alt} width="100%" />
        <Box textAlign="left" padding={4}>
          <Text fontWeight="medium" fontSize="md" w="100%" mb={2}>
            {title}
          </Text>
          <Text color="greyscale.600" fontSize="md" w="100%">
            {description}
          </Text>
        </Box>
      </Box>
    </Flex>
  );
}

export default function PartnerDeals() {
  const isMobile = useBreakpointValue({ base: true, xl: false });
  const { t } = useTranslation();

  return (
    <VStack
      spacing={4}
      textAlign="center"
      alignItems="stretch"
      py={{ base: 0, md: 20 }}
      width="100%"
      overflowX={{ base: 'hidden', xl: 'unset' }}
    >
      <Text
        textTransform="uppercase"
        color="greyscale.600"
        fontSize="xs"
        fontWeight="700"
      >
        {t('partner_discount.discount_from_partners')}
      </Text>
      <Heading
        as="h3"
        fontSize={{
          base: '24px',
          md: '30px',
        }}
        textAlign="center"
        mb={{
          base: 5,
          md: 8,
        }}
      >
        {t('partner_discount.the_best_deals')}
      </Heading>
      {isMobile ? (
        <Box
          width="100%"
          height="100%"
          margin="auto"
          mx={{ base: 0, lg: 'unset' }}
          sx={{
            '.slick-list': {
              margin: { base: '0 8px 0 16px', md: '0 16px 0 32px', lg: 0 },
              overflow: 'unset',
            },
            '.slick-track': { display: 'flex' },
            '.slick-slide': {
              display: 'inline-flex',
              height: 'unset',
              '& > div': { width: '100%' },
            },
          }}
        >
          <Slider {...partnerDealsSettings}>
            <PartnerCard
              alt="car rentals"
              image={rentalCarsHero}
              title={
                <Trans
                  i18nKey="dashboard.perks.car_rentals.percentage_off_your_car_rental"
                  values={{ percentage: 8 }}
                  components={{
                    strong: (
                      <Text
                        display="inline"
                        as="span"
                        fontSize="md"
                        fontWeight="bold"
                        color="danger.500"
                      />
                    ),
                  }}
                />
              }
              description={t(
                'dashboard.perks.car_rentals.cancel_or_change_bookings',
                { discount: 8 },
              )}
            />
            <PartnerCard
              alt="airport transfers"
              image={airportTransfersHero}
              title={t('dashboard.perks.airport_transfer.low_cost_transfers')}
              description={t(
                'dashboard.perks.airport_transfer.book_hassle_free',
              )}
            />
          </Slider>
        </Box>
      ) : (
        <Flex width="100%" mx="auto" justifyContent="center">
          <PartnerCard
            alt=""
            image={rentalCarsHero}
            title={
              <Trans
                i18nKey="dashboard.perks.car_rentals.percentage_off_your_car_rental"
                values={{ percentage: 8 }}
                components={{
                  strong: (
                    <Text
                      display="inline"
                      as="span"
                      fontSize="md"
                      fontWeight="bold"
                      color="danger.500"
                    />
                  ),
                }}
              />
            }
            description={t(
              'dashboard.perks.car_rentals.cancel_or_change_bookings',
              { discount: 8 },
            )}
          />
          <PartnerCard
            alt=""
            image={airportTransfersHero}
            title={t('dashboard.perks.airport_transfer.low_cost_transfers')}
            description={t('dashboard.perks.airport_transfer.book_hassle_free')}
          />
        </Flex>
      )}
      <Text
        sx={{
          fontSize: 'small',
          textAlign: 'center',
          color: 'primary.900',
        }}
        pt={8}
      >
        {t('partner_discount.save_money')}
      </Text>
    </VStack>
  );
}
