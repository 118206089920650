import React from 'react';
import { Flex, Image } from '@chakra-ui/react';
import { Loader } from 'elements';

const PassQrCode: React.FC<{ imageUrl: string }> = ({ imageUrl }) => {
  return (
    imageUrl && (
      <Flex
        py={{ base: 12, md: 6 }}
        px={6}
        bg="greyscale.100"
        boxShadow="lg"
        borderRadius="lg"
        width={{ base: '100%', md: 'auto' }}
        justifyContent="center"
        alignItems="center"
        data-testid="lounge-pass-details-qr-image"
      >
        <Image
          minWidth={{ base: '160px', md: '180px' }}
          height={{ base: '160px', md: '180px' }}
          src={imageUrl}
          fallback={
            <Flex
              color="primary.900"
              w="100%"
              width={{ base: '160px', md: '180px' }}
              height={{ base: '160px', md: '180px' }}
              alignItems="center"
              justifyContent="center"
            >
              <Loader size={20} data-testid="qr-loader" />
            </Flex>
          }
        />
      </Flex>
    )
  );
};
export default PassQrCode;
