import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as ReactLink } from 'react-router-dom';
import { shadowsMd } from '@airhelp/design-tokens/dist/js/design-tokens';
import { ArrowLeft } from '@airhelp/icons';
import { Button, Container, Heading, Text } from '@chakra-ui/react';

interface IComponent {
  content: string;
  labelButton: string;
  urlButton: string;
}

const InfoBox: React.FC<IComponent> = ({ content, urlButton, labelButton }) => {
  const { t } = useTranslation();

  return (
    <Container
      mt={10}
      mb={{ base: 4, sm: 0 }}
      mx={{ base: 4, md: 16, lg: 'auto' }}
      p={10}
      bgColor="greyscale.100"
      w={{ base: 'auto', lg: '770px' }}
      maxW={{ base: 'none', lg: '770px' }}
      borderRadius="xl"
      boxShadow={{ base: 'none', sm: shadowsMd }}
      textAlign="center"
    >
      <Text
        fontSize="xs"
        textTransform="uppercase"
        fontWeight="bold"
        color="primary.500"
        mb={4}
      >
        {t('lounge_finder.en_airhelp_lounge_finder')}
      </Text>

      <Heading variant="h4" mb={10}>
        {content}
      </Heading>

      <Button
        as={ReactLink}
        to={{ pathname: urlButton }}
        leftIcon={<ArrowLeft />}
        _hover={{ backgroundColor: 'primary.600', color: 'greyscale.100' }}
      >
        {labelButton}
      </Button>
    </Container>
  );
};

export default InfoBox;
