import { fetchUserProfile, type FetchUserProfileResponse } from '@airhelp/plus';
import {
  type QueryClient,
  useQuery,
  useQueryClient,
  type UseQueryResult,
} from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { ahplusClient } from 'api/clients/clients';
import { type ApiQueryOptions } from 'hooks/api/queryHelper';
import {
  type UserProfile,
  transformToUserProfileClass,
} from 'models/userProfile/UserProfile';
import { sentryUtils } from 'utils';

const QUERY_KEY = ['userProfile', 'me'];

export const transformUserProfileResponse = (
  data: FetchUserProfileResponse,
): UserProfile => {
  return transformToUserProfileClass(data.userProfile);
};

const prefetchMyProfile = async (queryClient: QueryClient) => {
  const currentQueryData =
    queryClient.getQueryData<FetchUserProfileResponse>(QUERY_KEY);

  if (currentQueryData) {
    return transformUserProfileResponse(currentQueryData);
  }

  try {
    const response = await fetchUserProfile({ client: ahplusClient });

    queryClient.setQueryData(QUERY_KEY, response);

    return transformUserProfileResponse(response);
  } catch (error) {
    sentryUtils.captureException(error);
    return null;
  }
};

const useFetchMyProfile = (
  options?: ApiQueryOptions<FetchUserProfileResponse>,
): UseQueryResult<UserProfile> => {
  return useQuery<FetchUserProfileResponse, Error, UserProfile>({
    queryKey: QUERY_KEY,
    queryFn: () => fetchUserProfile({ client: ahplusClient }),
    select: transformUserProfileResponse,
    ...options,
  });
};

const useMyProfile = () => {
  const queryClient = useQueryClient();

  const { data: userProfile } = useFetchMyProfile({ enabled: false });
  const queryState = queryClient.getQueryState(QUERY_KEY);
  const clearProfile = () => {
    queryClient.removeQueries({ queryKey: QUERY_KEY });
  };

  useEffect(() => {
    if (userProfile) {
      const { userProfileUuid } = userProfile;
      Sentry.setUser({
        id: userProfileUuid,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [userProfile]);

  const reloadProfile = async () => {
    queryClient.removeQueries({ queryKey: QUERY_KEY });
    await prefetchMyProfile(queryClient);
  };

  return {
    userProfile,
    queryState,
    clearProfile,
    reloadProfile,
  };
};

export default useFetchMyProfile;

export { useMyProfile, prefetchMyProfile };
