import React from 'react';
import { Flex, Image, Text } from '@chakra-ui/react';
import { type BillingPayment } from '@airhelp/plus';
import { useTranslation } from 'react-i18next';
import Sepa from 'assets/images/billing/sepa-debit.svg';
import Ideal from 'assets/images/billing/ideal.svg';
import Bancontact from 'assets/images/billing/bancontact.svg';
import CreditCardBlank from 'assets/images/billing/credit-card-blank.png';
import AHIcon from 'assets/images/billing/ah-billing-icon.svg';

interface IComponent {
  payment: BillingPayment;
}

const PaymentMethodInfo: React.FC<IComponent> = ({ payment }) => {
  const { t } = useTranslation();

  const getIcon = () => {
    switch (payment.type) {
      case 'sepa_debit':
        return Sepa;
      case 'ideal':
        return Ideal;
      case 'bancontact':
        return Bancontact;
      default:
        return AHIcon;
    }
  };

  const icon = getIcon();

  return (
    <Flex
      flexDirection="column"
      borderRadius="3xl"
      p={6}
      maxWidth={{ base: '342px', sm: '312px' }}
      maxHeight={{ base: '100%', sm: '184px' }}
      minHeight={{ base: 'unset', sm: '184px' }}
      minWidth={{ base: 'unset', sm: '312px' }}
      width="100%"
      height="100%"
      backgroundImage={`url(${CreditCardBlank})`}
      backgroundSize={{ base: 'cover', sm: '100% 100%' }}
      backgroundRepeat="no-repeat"
      data-testid="credit-card"
    >
      <Flex width="100%" justifyContent="flex-end">
        <Image src={AHIcon} width="auto" height="auto" />
      </Flex>
      <Flex
        width="100%"
        alignItems="center"
        mt={4}
        justifyContent="space-between"
      />

      <Flex
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        mt={14}
      >
        <Text fontSize="md" fontWeight="700" color="primary.100">
          {t('payment_management.your_payment_method')}
        </Text>
        <Text
          fontSize="md"
          fontWeight="700"
          color="primary.100"
          data-testid="payment-type"
        >
          <Image src={icon} width="auto" height="auto" alt={payment.type} />
        </Text>
      </Flex>
    </Flex>
  );
};

export default PaymentMethodInfo;
