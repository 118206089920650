import React, { useEffect, useRef, useState } from 'react';
import {
  generatePath,
  Navigate,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { track } from 'react-tracking';
import { useCreateNewPassword } from '@airhelp/auth/react';
import {
  activateTrip,
  ActivationStatus,
  validateActivationToken,
  type ValidateActivationToken,
} from '@airhelp/plus';
import { acceptMarketingConsent } from '@airhelp/webapp';
import ctripActivationImage from 'assets/images/ctrip-activation@1x.jpg';
import { TwoColumnsImageLayout } from 'components/Layouts';
import RegistrationView, {
  type ActivationSubmitData,
} from 'components/Trips/RegistrationView/RegistrationView';
import { PAGE_PATHS } from 'config/routes';
import { PageLoader } from 'elements';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { ahplusClient, webappClient } from 'api/clients/clients';
import { sentryUtils } from 'utils';

interface PageParams extends Record<string, string | undefined> {
  uuid: string;
  token: string;
}

const TripRegistrationPage = () => {
  const [validationData, setValidationData] = useState<
    ValidateActivationToken | undefined
  >(undefined);
  const [isTokenValidated, setIsTokenValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [redirectToActivation, setRedirectToActivation] = useState(false);

  const { userProfile, reloadProfile } = useMyProfile();
  const navigate = useNavigate();

  const { mutate: authCreatePassword, isError } = useCreateNewPassword({});

  const { uuid, token } = useParams<PageParams>();

  const handleActivateTrip = async (
    submitData: ActivationSubmitData,
    refreshToken: string,
    tripUuid: string,
    tripToken: string,
  ) => {
    const { password, marketingConsent } = submitData;
    const fail = () => {
      setIsSubmitError(true);
      setIsSubmitting(false);
    };

    setIsSubmitting(true);

    authCreatePassword({
      password,
      token: refreshToken,
    });

    if (isError) {
      fail();
      return;
    }

    if (marketingConsent) {
      try {
        await acceptMarketingConsent({
          client: webappClient,
        });
      } catch (error) {
        sentryUtils.captureException(error);
        fail();
      }
    }

    try {
      await activateTrip({
        params: {
          tripUuid,
          token: tripToken,
        },
        client: ahplusClient,
      });
    } catch (error) {
      sentryUtils.captureException(error);
      fail();
    }

    await reloadProfile();
    setRedirectToActivation(true);

    setIsSubmitting(false);
  };

  const validateToken = async (tripUuid: string, activationToken: string) => {
    setIsLoading(true);

    try {
      const response = await validateActivationToken({
        params: {
          tripUuid,
          token: activationToken,
        },
        client: ahplusClient,
      });

      setValidationData(response);
    } catch (error) {
      setIsLoadingError(true);
    }

    setIsTokenValidated(true);
    setIsLoading(false);
  };

  // makes an api call to validate the token coming from URL
  const tokenValidatedRef = useRef(false);
  useEffect(() => {
    if (!isTokenValidated && !tokenValidatedRef.current && uuid && token) {
      tokenValidatedRef.current = true;

      setIsTokenValidated(true);

      validateToken(uuid, token);
    }
  }, [isTokenValidated, uuid, token]);

  // if the token is validated and user activation status is not inactive
  useEffect(() => {
    const shouldNavigate =
      isTokenValidated &&
      validationData &&
      validationData.activationStatus !== ActivationStatus.INACTIVE;

    if (shouldNavigate) {
      if (userProfile) {
        navigate(generatePath(PAGE_PATHS.TRIPS.ACTIVATE_PAGE, { token: uuid }));
      } else {
        navigate(generatePath(PAGE_PATHS.HOME_PAGE));
      }
    }
  }, [isTokenValidated, validationData, userProfile]);

  // redirects user to default trip activation flow after successful account registration
  useEffect(() => {
    if (redirectToActivation && userProfile) {
      navigate(generatePath(PAGE_PATHS.TRIPS.ACTIVATE_PAGE, { token: uuid }));
    }
  }, [redirectToActivation, userProfile, uuid]);

  if (isLoading) {
    return <PageLoader />;
  }

  if (isLoadingError) {
    return <Navigate to={generatePath(PAGE_PATHS.HOME_PAGE)} />;
  }

  return (
    <TwoColumnsImageLayout imageSrc={ctripActivationImage}>
      <RegistrationView
        isSubmitting={isSubmitting}
        isSubmitError={isSubmitError}
        email={validationData?.email || ''}
        onSubmit={(submitData) => {
          handleActivateTrip(
            submitData,
            validationData?.resetPasswordToken || '',
            uuid || '',
            token || '',
          );
        }}
      />
    </TwoColumnsImageLayout>
  );
};

export default track({ page_type: 'TripRegistration' })(TripRegistrationPage);
