import React, { type PropsWithChildren } from 'react';
import { Outlet } from 'react-router';
import AuthenticationView from './AuthenticationView';

const AuthenticationLayout: React.FC<PropsWithChildren> = () => {
  return (
    <AuthenticationView cardView>
      <Outlet />
    </AuthenticationView>
  );
};

export default AuthenticationLayout;
