import React from 'react';
import {
  useSearchParams,
  Navigate,
  useParams,
  generatePath,
} from 'react-router-dom';
import { track } from 'react-tracking';
import { ProductName } from '@airhelp/plus';
import CheckoutFormView from 'components/Trips/Checkout/CheckoutForm/CheckoutFormView';
import { PAGE_PATHS } from 'config/routes';
import { PageLoader } from 'elements';
import useFetchMyProfile from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { usePageTracking } from 'utils/tracking';
import { useComputedOffers } from 'hooks/api/offers/useComputedOffers';
import { CommonParams, castBooleanParam, castStringParam } from 'utils/params';

const CheckoutPage = () => {
  usePageTracking();

  const { data: userProfile } = useFetchMyProfile();
  const { token } = useParams();
  const [searchParams] = useSearchParams();

  const { isLoading: isLoadingOffers, data: offersData } = useComputedOffers({
    enabled: Boolean(userProfile),
    params: {
      renew: castBooleanParam(searchParams, CommonParams.RENEW),
      upgrade: castBooleanParam(searchParams, CommonParams.UPGRADE),
      channel: castStringParam(searchParams, CommonParams.CHANNEL),
    },
  });

  const productName = searchParams.get('product-name') as ProductName;

  if (!productName) {
    return <Navigate to={{ pathname: generatePath(PAGE_PATHS.HOME_PAGE) }} />;
  }

  if (isLoadingOffers) {
    return <PageLoader />;
  }

  if (!offersData) {
    switch (productName) {
      case ProductName.MEMBERSHIP_ESSENTIAL_24_99_EUR_ANNUALLY:
      case ProductName.AIRHELP_SMART_ANNUALLY:
        return (
          <Navigate
            to={{
              pathname: generatePath(PAGE_PATHS.TRIPS.UPGRADE.ANNUAL_PAGE, {
                token: token?.toString() || '',
              }),
              search: searchParams.toString(),
            }}
          />
        );

      case ProductName.UPGRADE_TO_TRIP_COMPLETE:
        return (
          <Navigate
            to={{
              pathname: generatePath(PAGE_PATHS.TRIPS.UPGRADE.COMPLETE_PAGE, {
                token: token?.toString() || '',
              }),
              search: searchParams.toString(),
            }}
          />
        );
      default:
        return null;
    }
  }

  const offers = offersData.offers;

  return <CheckoutFormView productName={productName} offers={offers} />;
};

export default track({ page_type: 'Trips.Upgrade.Checkout' })(CheckoutPage);
