import { Text, Flex, Box, VStack, Skeleton, useToken } from '@chakra-ui/react';
import { useParams } from 'react-router';
import config from 'config';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { useCompensationData } from './useCompensationData';
import { PieChart } from './parts/PieChart';
import { ChartLegend } from './parts/ChartLegend';

export function Compensation() {
  const { locale } = useLocale();
  const [success400, primary900, primary500] = useToken('colors', [
    'success.400',
    'primary.900',
    'primary.500',
  ]);

  const { id } = useParams();

  if (!id) {
    throw new Error('Id param is required for compensation component to work');
  }

  const {
    isLoading,
    legalFee,
    serviceFee,
    claimCompensation,
    finalCompensation,
    freeOfCharge,
    formattedValues,
  } = useCompensationData({
    claimId: Number(id),
  });

  return (
    <Skeleton isLoaded={!isLoading}>
      <Flex
        direction={{
          base: 'column',
          md: 'row-reverse',
        }}
        alignItems="center"
        w="100%"
        justifyContent="space-between"
        gap={{
          base: 4,
          md: 0,
        }}
        p={{
          base: 4,
          md: 6,
        }}
        backgroundColor="greyscale.100"
        rounded="lg"
      >
        <Box
          w={{
            base: '100%',
            md: '50%',
          }}
        >
          <PieChart
            data={[
              {
                value: finalCompensation,
                color: success400,
                label: `${finalCompensation} compensation`,
              },
              {
                value: legalFee,
                color: primary900,
                label: `${legalFee} legal fee`,
              },
              {
                value: serviceFee,
                color: primary500,
                label: `${serviceFee} service fee`,
              },
            ]}
            total={formattedValues.claimCompensation}
          />
        </Box>

        <VStack
          align="stretch"
          maxW="532px"
          gap={3}
          w={{
            base: '100%',
            md: '50%',
          }}
        >
          <ChartLegend
            freeOfCharge={Boolean(freeOfCharge)}
            eligibleForCompensation={claimCompensation > 0}
            originalCompensation={formattedValues.claimCompensation}
            serviceFee={`- ${formattedValues.serviceFee}`}
            legalActionFee={`- ${formattedValues.legalFee}`}
            perPassenger={formattedValues.finalCompensationPerPassenger}
            totalCompensation={formattedValues.finalCompensation}
          />
          <Text
            textAlign={{
              base: 'center',
              md: 'left',
            }}
            as="a"
            href={config.pricingUrl.replace('{{locale}}', locale)}
            color="primary.500"
            fontSize="xs"
          >
            Read more about our fees
          </Text>
        </VStack>
      </Flex>
    </Skeleton>
  );
}
