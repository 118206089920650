import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { policyUrl } from 'utils/insurancePolicy';
import { type Journey } from 'models/journey/Journey';
import { type AirPayoutJourney } from 'models/journey/AirPayoutJourney';
import { PolicyButton } from '../HeaderContent';
import AirBundleThead from '../AirBundleThead';
import Header from './Header';
import AirPayoutInsuranceViewItem from './AirPayoutInsuranceViewItem';

const AirPayoutInsuranceView = ({
  journey,
  airPayoutJourneys,
}: {
  journey: Journey;
  airPayoutJourneys: AirPayoutJourney[];
}) => {
  const { mainAirPayoutJourney } = journey;

  const ipidVersion = mainAirPayoutJourney?.ipidVersion;
  const linkToFile = policyUrl(true, ipidVersion);

  return (
    <Box
      p={{ base: 4, md: 8 }}
      background="greyscale.100"
      borderRadius="lg"
      width="100%"
    >
      <Header airPayoutJourney={airPayoutJourneys[0]} linkToFile={linkToFile} />
      <Box
        p={{ base: 0, md: 4 }}
        background={{ base: 'greyscale.100', md: 'greyscale.300' }}
        borderRadius="lg"
      >
        {/* DesktopView only */}
        <AirBundleThead />
        <Flex flexDirection="column">
          {airPayoutJourneys.map((airPayoutJourney, index) => {
            const lastElement = index + 1 === airPayoutJourneys.length;

            return (
              <AirPayoutInsuranceViewItem
                key={airPayoutJourney.id}
                airPayoutJourney={airPayoutJourney}
                lastElement={lastElement}
                journeyId={journey.id}
              />
            );
          })}
        </Flex>
      </Box>
      <Box display={{ base: 'block', md: 'none' }} mt={2}>
        <PolicyButton linkToFile={linkToFile} />
      </Box>
    </Box>
  );
};

export default AirPayoutInsuranceView;
