import React from 'react';
import { useTranslation } from 'react-i18next';
import perkLogo from 'assets/images/perks/logo-lounge-pass.svg';
import { useTrackEvent } from 'utils/tracking/hooks';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { type Locale } from 'contexts/LocaleContext/locales';
import { AHPLUS_API_URL } from 'api/clients/clients';
import PerkDrawerContent from '../PerkDrawerContent';

const TRACKING_PROVIDER_NAME = 'collinson_loungepass';

const redirectToLoungePassSite = (locale: Locale) => {
  window.open(
    `${AHPLUS_API_URL}/api/v2/user_profiles/lounge_pass_perk/?locale=${locale}`,
    '_blank',
  );
};

interface IComponent {
  discount: number;
  imageText: string;
  imageSrcMobile: string;
}

const LoungePass: React.FC<IComponent> = ({
  discount,
  imageText,
  imageSrcMobile,
}) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { trackPageInteractions } = useTrackEvent();

  const benefits = [
    {
      content: t('dashboard.perks.lounge_pass.guaranteed_access'),
      key: 'guaranteed_access',
    },
    {
      content: t('dashboard.perks.lounge_pass.over_six_hundred_lounges'),
      key: 'over_six_hundred_lounges',
    },
    {
      content: t('dashboard.perks.lounge_pass.relax_away'),
      key: 'relax_away',
    },
    {
      content: t('dashboard.perks.lounge_pass.complimentary_drinks'),
      key: 'complimentary_drinks',
    },
    {
      content: t('dashboard.perks.lounge_pass.free_cancellation'),
      key: 'free_cancellation',
    },
  ];

  const goToSite = () => {
    trackPageInteractions(
      'go to site',
      TRACKING_PROVIDER_NAME,
      'travel service banner',
    );

    redirectToLoungePassSite(locale);
  };

  return (
    <PerkDrawerContent
      heading={imageText}
      imageSrcMobile={imageSrcMobile}
      perkLogo={perkLogo}
      discount={discount}
      subHeading="dashboard.perks.lounge_pass.percentage_off_your_lounge_pass"
      descriptionPrimary={t('dashboard.perks.lounge_pass.enjoy_a_quiet_drink')}
      descriptionSecondary={t('dashboard.perks.lounge_pass.get_your_trip_off')}
      benefits={benefits}
      infoBoxText={t(
        'dashboard.perks.lounge_pass.gets_guaranteed_lounge_access',
      )}
      goToSite={goToSite}
      buttonLabel={t('dashboard.perks.lounge_pass.get_lounge_pass')}
    />
  );
};

export default LoungePass;
