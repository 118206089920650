import config from 'config';

const { zowieChat: zowieChatConfig } = config;

const loadScript = () => {
  if (zowieChatConfig.enabled) {
    const scriptScr = `${zowieChatConfig.url}/chatbotize-entrypoint.min.js`;

    const scriptExists = document.querySelector(`script[src="${scriptScr}"]`);

    if (scriptExists) {
      return;
    }

    const zowieScript = document.createElement('script');
    zowieScript.src = scriptScr;

    const zowieContainer = document.createElement('div');
    zowieContainer.setAttribute('id', 'chatbotize');

    document.head?.appendChild(zowieScript);
    document.body.appendChild(zowieContainer);
  }
};

export { loadScript };
