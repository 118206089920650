import AuthenticationLayout from 'components/Layouts/AuthenticationLayout';
import {
  LoginPage,
  ResetPasswordPage,
  SetPasswordPage,
  SignupPage,
} from 'pages';

export const AuthenticationRoutes = {
  element: <AuthenticationLayout />,
  children: [
    { path: 'signup', element: <SignupPage /> },
    { path: 'login', element: <LoginPage /> },
    { path: 'reset-password', element: <ResetPasswordPage /> },
    { path: 'set-password', element: <SetPasswordPage /> },
  ],
};
