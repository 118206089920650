import { useClaimHistorySuspense } from '@airhelp/webapp/react';
import {
  Box,
  Button,
  useDisclosure,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  useBreakpointValue,
  Text,
  Flex,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { Delete } from '@airhelp/icons';
import * as VerticalStepTracker from 'components/VerticalStepTracker';

export function Status() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const placement: 'bottom' | 'right' =
    useBreakpointValue({ base: 'bottom', md: 'right' }) || 'bottom';
  const { id } = useParams();

  if (!id) {
    throw new Error('Id param is required for status component to work');
  }

  const { data } = useClaimHistorySuspense({
    params: { id: Number(id) },
  });
  const claimHistory = data.slice().reverse();
  const shortClaimHistorySize = 3;
  const initialClaimHistory = claimHistory.slice(-1 * shortClaimHistorySize);
  const claimHistorySize = claimHistory.length;
  const isClaimHistoryLong = claimHistorySize > shortClaimHistorySize;

  return (
    <Box
      rounded="xl"
      backgroundColor="greyscale.100"
      p={{
        base: 4,
        md: 8,
      }}
    >
      <VerticalStepTracker.Root
        extendedBorder={isClaimHistoryLong ? 'top' : undefined}
      >
        {initialClaimHistory.map((stage, idx) => (
          <VerticalStepTracker.Step
            key={stage.state}
            header={stage.state}
            subheader={stage.completedAt.toString()}
            state={idx < shortClaimHistorySize - 1 ? 'completed' : 'upcoming'}
          >
            {idx === shortClaimHistorySize - 1 && (
              <VerticalStepTracker.StepBadge colorScheme="success">
                Up Next
              </VerticalStepTracker.StepBadge>
            )}
          </VerticalStepTracker.Step>
        ))}
      </VerticalStepTracker.Root>
      {isClaimHistoryLong ? (
        <Button variant="secondary" size="s" onClick={onOpen} mt={4}>
          View Status Details
        </Button>
      ) : null}
      <Drawer isOpen={isOpen} onClose={onClose} placement={placement}>
        <DrawerOverlay />
        <DrawerContent
          m={{
            base: 0,
            md: 4,
          }}
          maxW={{
            base: '100%',
            md: '484px',
          }}
          rounded="xl"
          maxHeight={{
            base: '90%',
            md: '100%',
          }}
          pb={{
            base: 4,
            md: 8,
          }}
          pt={{
            base: 2,
            md: 8,
          }}
          pe={3}
          ps={{
            base: 4,
            md: 4,
          }}
        >
          <Flex
            ps={{
              base: 4,
              md: 4,
            }}
            pb={{
              base: 0,
              md: 6,
            }}
            as="header"
            w="100%"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text color="primary.900" fontSize="sm" fontWeight="bold">
              Status Details
            </Text>
            <Flex
              justify="center"
              align="center"
              w={7}
              h={7}
              backgroundColor="greyscale.300"
              borderRadius="full"
            >
              <Delete fontSize="12px" color="greyscale.600" />
            </Flex>
          </Flex>
          <Box
            overflowY="scroll"
            overflowX="hidden"
            sx={{
              '::-webkit-scrollbar': {
                width: 2,
              },
              '::-webkit-scrollbar-track': {
                background: 'transparent',
                borderRadius: 'md',
              },
              '::-webkit-scrollbar-thumb': {
                background: 'greyscale.400',
                borderRadius: '3xl',
                height: '100px',
              },
              '::-webkit-scrollbar-thumb:hover': {
                background: { md: 'greyscale.400' },
              },
              scrollbarWidth: 0,
            }}
          >
            <VerticalStepTracker.Root>
              {claimHistory.map((stage, idx) => (
                <VerticalStepTracker.Step
                  key={stage.state}
                  header={stage.state}
                  subheader={stage.completedAt.toString()}
                  state={idx < claimHistorySize - 1 ? 'completed' : 'upcoming'}
                >
                  {idx === claimHistorySize - 1 && (
                    <VerticalStepTracker.StepBadge colorScheme="success">
                      Up Next
                    </VerticalStepTracker.StepBadge>
                  )}
                </VerticalStepTracker.Step>
              ))}
            </VerticalStepTracker.Root>
          </Box>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}
