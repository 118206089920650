import {
  type CreatePaymentIntentParams,
  createStripeCheckout,
} from '@airhelp/plus';
import { useMutation } from '@tanstack/react-query';
import { ahplusClient } from 'api/clients/clients';

const useCreatePayment = () => {
  const useCreatePaymentMutation = useMutation({
    mutationFn: (params: CreatePaymentIntentParams) =>
      createStripeCheckout({
        client: ahplusClient,
        params,
      }),
  });

  return useCreatePaymentMutation;
};

export default useCreatePayment;
