import { Flex, Circle, Divider } from '@chakra-ui/react';
import { Airplane } from '@airhelp/icons';

export function Separator() {
  return (
    <Flex w="100%" justifyItems="center" alignItems="center">
      <Divider orientation="horizontal" />
      <Circle
        alignSelf="center"
        border="1px solid white"
        p={2}
        transform="rotate(30deg)"
      >
        <Airplane color="greyscale.100" />
      </Circle>
      <Divider orientation="horizontal" />
    </Flex>
  );
}
