import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  updateAirPayoutJourneys,
  type UpdateAirPayoutsForJourneyParams,
} from '@airhelp/plus';
import { ahplusClient } from 'api/clients/clients';

const useUpdateAirPayoutJourneys = () => {
  const queryClient = useQueryClient();

  const useUpdateAirPayoutJourneysMutation = useMutation({
    mutationFn: (params: UpdateAirPayoutsForJourneyParams) =>
      updateAirPayoutJourneys({
        client: ahplusClient,
        params,
      }),
    onSuccess: async (data, params) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['journeys'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['journey', 'air-payouts', { journeyId: params.journeyId }],
        }),
        queryClient.setQueryData(['journey', { id: params.journeyId }], {
          ...data,
        }),
      ]);
    },
  });

  return useUpdateAirPayoutJourneysMutation;
};

export default useUpdateAirPayoutJourneys;
