import React from 'react';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { Input } from '@airhelp/react';
import { Box } from '@chakra-ui/react';
import { type TFunction } from 'i18next';

const BRANCH_AGENCY_CODE_MAX_LENGTH = 3;
const BRANCH_AGENCY_CODE_MIN_LENGTH = 3;

const branchAgencyCode = ({
  form,
  t,
}: {
  form: UseFormReturn;
  t: TFunction;
}) => {
  return (
    <Controller
      name="branchAgencyCode"
      control={form.control}
      rules={{
        required: { value: true, message: t('errors.required') },
        pattern: {
          value: /^[0-9]+$/,
          message: t('errors.please_insert_valid_field', {
            field: t('common.branch_agency_code'),
          }),
        },
        minLength: {
          value: BRANCH_AGENCY_CODE_MIN_LENGTH,
          message: t('errors.too_short', {
            attribute: t('common.branch_agency_code'),
            min: BRANCH_AGENCY_CODE_MIN_LENGTH,
          }),
        },
        maxLength: {
          value: BRANCH_AGENCY_CODE_MAX_LENGTH,
          message: t('errors.too_long', {
            attribute: t('common.branch_agency_code'),
            max: BRANCH_AGENCY_CODE_MAX_LENGTH,
          }),
        },
      }}
      defaultValue=""
      render={({
        field: { onChange, name, value },
        fieldState: { invalid, error },
      }) => (
        <Box width="100%" minHeight="106px">
          <Input
            isInvalid={invalid}
            errorMessage={error?.message || ''}
            name={name}
            label={t('common.branch_agency_code')}
            value={value}
            placeholder={t('common.branch_agency_code')}
            onChange={onChange}
            minLength={BRANCH_AGENCY_CODE_MIN_LENGTH}
            maxLength={BRANCH_AGENCY_CODE_MAX_LENGTH}
            data-testid="branch-agency-code"
          />
        </Box>
      )}
    />
  );
};

export default branchAgencyCode;
