import {
  Image,
  Flex,
  Text,
  VStack,
  Box,
  useBreakpointValue,
} from '@chakra-ui/react';
import Slider from 'react-slick';
import { useTranslation, Trans } from 'react-i18next';
import payout from 'assets/images/air-payout.png';
import luggage from 'assets/images/air-luggage.png';
import lounge from 'assets/images/airport-lounges.png';
import { AIR_PAYOUT_AMOUNT, AIR_LUGGAGE_AMOUNT } from 'config/benefits';
import { newBenefitsSettings } from '../carousel';

interface BenefitCard {
  title: string;
  description: string;
  image: string;
}

function BenefitCard({ title, description, image }: BenefitCard) {
  return (
    <Flex
      width={{
        base: 'calc(100% - 8px)',
        md: 'calc(100% - 16px)',
        lg: 'calc(100% - 16px)',
        xl: 'unset',
      }}
      height={{ base: '100%', lg: 'unset' }}
      mx={{ base: 0, xl: 4 }}
      justifyContent="center"
    >
      <Box
        backgroundColor="greyscale.100"
        borderRadius="lg"
        textAlign={{
          base: 'center',
          xl: 'left',
        }}
        p={{
          base: 4,
          md: 8,
        }}
        width="100%"
        maxW={{
          base: 'unset',
          md: '404px',
        }}
      >
        <Image
          margin="auto"
          src={image}
          alt={title}
          mb={4}
          height={{
            base: '128px',
            md: '243px',
          }}
        />
        <Text fontWeight="medium" fontSize="md" w="100%">
          {title}
        </Text>
        <Text color="greyscale.600" fontSize="md" w="100%">
          {description}
        </Text>
      </Box>
    </Flex>
  );
}

export default function NewBenefits() {
  const isMobile = useBreakpointValue({ base: true, xl: false });
  const { t } = useTranslation();

  return (
    <VStack
      py={{
        base: 10,
        md: 20,
      }}
      borderRadius="lg"
      backgroundColor="greyscale.300"
      spacing={4}
      textAlign="center"
      width="100%"
      overflowX={{ base: 'hidden', xl: 'unset' }}
    >
      <Text
        textTransform="uppercase"
        color="greyscale.600"
        fontSize="xs"
        fontWeight="700"
      >
        {t('what_is_new.title')}
      </Text>
      <Box
        fontSize={{ base: '2xl', md: '30px' }}
        textAlign="center"
        fontWeight="bold"
        px={{ base: 4, md: 0 }}
        mb={{
          base: 4,
          md: 8,
        }}
      >
        <Text
          color="primary.900"
          fontWeight="bold"
          fontSize={{ base: '2xl', md: '30px' }}
        >
          {t('what_is_new.comfort_protection_assistance_1')}
        </Text>
        <Trans
          i18nKey="what_is_new.comfort_protection_assistance_2"
          components={{
            span: (
              <Text
                color="secondary.500"
                as="span"
                fontWeight="bold"
                fontSize={{ base: '2xl', md: '30px' }}
              />
            ),
          }}
        />
      </Box>
      {isMobile ? (
        <Box
          width="100%"
          height="100%"
          margin="auto"
          mx={{ base: 0, lg: 'unset' }}
          sx={{
            '.slick-list': {
              margin: { base: '0 8px 0 16px', md: '0 16px 0 32px', lg: 0 },
              overflow: 'unset',
            },
            '.slick-track': { display: 'flex' },
            '.slick-slide': {
              display: 'inline-flex',
              height: 'unset',
              '& > div': { width: '100%' },
            },
          }}
        >
          <Slider {...newBenefitsSettings}>
            <BenefitCard
              title={t('what_is_new.air_payout.title')}
              description={t('what_is_new.air_payout.description', {
                amount: AIR_PAYOUT_AMOUNT,
              })}
              image={payout}
            />
            <BenefitCard
              title={t('what_is_new.air_luggage.title')}
              description={t('what_is_new.air_luggage.description', {
                amount: AIR_LUGGAGE_AMOUNT,
              })}
              image={luggage}
            />
            <BenefitCard
              title={t('what_is_new.lounge_comfort.title')}
              description={t('what_is_new.lounge_comfort.description')}
              image={lounge}
            />
          </Slider>
        </Box>
      ) : (
        <Flex width="100%" alignItems="strech" justifyContent="center">
          <BenefitCard
            title={t('what_is_new.air_payout.title')}
            description={t('what_is_new.air_payout.description', {
              amount: AIR_PAYOUT_AMOUNT,
            })}
            image={payout}
          />
          <BenefitCard
            title={t('what_is_new.air_luggage.title')}
            description={t('what_is_new.air_luggage.description', {
              amount: AIR_LUGGAGE_AMOUNT,
            })}
            image={luggage}
          />
          <BenefitCard
            title={t('what_is_new.lounge_comfort.title')}
            description={t('what_is_new.lounge_comfort.description')}
            image={lounge}
          />
        </Flex>
      )}
    </VStack>
  );
}
