import React from 'react';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { Input } from '@airhelp/react';
import { Box } from '@chakra-ui/react';
import { type TFunction } from 'i18next';
import paymentsFieldsConfig from 'utils/airBundle/paymentsFieldsConfig';

const DEFAULT_MAX_LENGTH_ACCOUNT_NUMBER = 17;
const DEFAULT_MIN_LENGTH_ACCOUNT_NUMBER = 6;

const accountNumber = ({
  form,
  t,
  countryCode,
}: {
  form: UseFormReturn;
  t: TFunction;
  countryCode: string;
}) => {
  const ACCOUNT_NUMBER_MAX_LENGTH =
    paymentsFieldsConfig[countryCode]?.rules?.accountNumber?.maxLength ||
    DEFAULT_MAX_LENGTH_ACCOUNT_NUMBER;

  const ACCOUNT_NUMBER_MIN_LENGTH =
    paymentsFieldsConfig[countryCode]?.rules?.accountNumber?.minLength ||
    DEFAULT_MIN_LENGTH_ACCOUNT_NUMBER;

  return (
    <Controller
      name="accountNumber"
      control={form.control}
      rules={{
        required: { value: true, message: t('errors.required') },
        pattern: {
          value: /^[0-9]+$/,
          message: t('errors.please_insert_valid_field', {
            field: t('common.account_number'),
          }),
        },
        maxLength: {
          value: ACCOUNT_NUMBER_MAX_LENGTH,
          message: t('errors.too_long', {
            attribute: t('common.account_number'),
            max: ACCOUNT_NUMBER_MAX_LENGTH,
          }),
        },
        minLength: {
          value: ACCOUNT_NUMBER_MIN_LENGTH,
          message: t('errors.too_short', {
            attribute: t('common.account_number'),
            min: ACCOUNT_NUMBER_MIN_LENGTH,
          }),
        },
      }}
      defaultValue=""
      render={({
        field: { onChange, name, value },
        fieldState: { invalid, error },
      }) => (
        <Box width="100%" minHeight="106px">
          <Input
            isInvalid={invalid}
            errorMessage={error?.message || ''}
            name={name}
            label={t('common.account_number')}
            value={value}
            placeholder={t('common.account_number')}
            onChange={onChange}
            minLength={ACCOUNT_NUMBER_MIN_LENGTH}
            maxLength={ACCOUNT_NUMBER_MAX_LENGTH}
            data-testid="account-number"
          />
        </Box>
      )}
    />
  );
};

export default accountNumber;
