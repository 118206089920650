import React from 'react';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { Input } from '@airhelp/react';
import { Box } from '@chakra-ui/react';
import { type TFunction } from 'i18next';

const DEFAULT_BIC_CODE_MIN_LENGTH = 6;
const DEFAULT_BIC_CODE_MAX_LENGTH = 6;

const bicCode = ({ form, t }: { form: UseFormReturn; t: TFunction }) => {
  const BIK_CODE_MAX_LENGTH = DEFAULT_BIC_CODE_MAX_LENGTH;

  const BIK_CODE_MIN_LENGTH = DEFAULT_BIC_CODE_MIN_LENGTH;

  return (
    <Controller
      name="bicCode"
      control={form.control}
      rules={{
        required: { value: true, message: t('errors.required') },
        pattern: {
          value: /^[0-9]+$/,
          message: t('errors.please_insert_valid_field', {
            field: t('common.bic_code'),
          }),
        },
        minLength: {
          value: BIK_CODE_MIN_LENGTH,
          message: t('errors.too_short', {
            attribute: t('common.bic_code'),
            min: BIK_CODE_MIN_LENGTH,
          }),
        },
        maxLength: {
          value: BIK_CODE_MAX_LENGTH,
          message: t('errors.too_long', {
            attribute: t('common.bic_code'),
            max: BIK_CODE_MAX_LENGTH,
          }),
        },
      }}
      defaultValue=""
      render={({
        field: { onChange, name, value },
        fieldState: { invalid, error },
      }) => (
        <Box width="100%" minHeight="106px">
          <Input
            isInvalid={invalid}
            errorMessage={error?.message || ''}
            name={name}
            label={t('common.bic_code')}
            value={value}
            placeholder={t('common.bic_code')}
            onChange={onChange}
            minLength={BIK_CODE_MIN_LENGTH}
            maxLength={BIK_CODE_MAX_LENGTH}
            data-testid="bic-code"
          />
        </Box>
      )}
    />
  );
};

export default bicCode;
