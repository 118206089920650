import { AirLuggageStatus } from '@airhelp/plus';
import { useTranslation } from 'react-i18next';
import config from 'config';
import { type AirLuggageJourney } from 'models/journey/AirLuggageJourney';

export const useGetAirLuggageTooltipStatus = (
  airLuggageJourney: AirLuggageJourney,
) => {
  const { t } = useTranslation();

  const { maxBags, status, payoutAmount } = airLuggageJourney;
  const defaultKey = maxBags === 1 ? 'active' : 'default';
  const airLuggagePayout = payoutAmount.toString();

  switch (status) {
    case AirLuggageStatus.ACTIVE:
      return t(`air_luggage.card.description.${defaultKey}`, {
        amount: airLuggagePayout,
        bags: maxBags,
      });

    case AirLuggageStatus.ELIGIBLE:
      return t('air_luggage.card.description.eligible');

    case AirLuggageStatus.INELIGIBLE:
    case AirLuggageStatus.BLOCKED:
    case AirLuggageStatus.BLOCKED_UNREGISTERED:
    case AirLuggageStatus.UNREGISTERED:
    case AirLuggageStatus.MEMBERSHIP_NOT_RENEWED:
      return t('air_payout.card.description.not_eligible');
    case AirLuggageStatus.REJECTED:
      return t('air_luggage.card.description.claim_rejected_with_email', {
        contactEmail: config.contactEmail,
      });
    case AirLuggageStatus.PROCESSING_PAYMENT:
    case AirLuggageStatus.MANUAL_VERIFICATION_PENDING:
      return t('air_luggage.card.description.checking_eligibility');

    case AirLuggageStatus.PAYOUT_FAILED:
    case AirLuggageStatus.PAYOUT_RETURNED:
      return t('air_luggage.card.description.payment_failed');

    case AirLuggageStatus.PAYOUT_SUCCESSFUL:
      return t('air_luggage.card.description.paid');

    case AirLuggageStatus.EXPIRED:
      return t('air_payout.card.description.expired_plural', { value: 28 });

    default:
      return t(`air_luggage.card.description.${defaultKey}`, {
        amount: airLuggagePayout,
        bags: maxBags,
      });
  }
};
