import { Flex, VStack, Text } from '@chakra-ui/react';

interface PlanBoxHeaderProps {
  subheading: string;
  planName: string;
  version: 'primary' | 'secondary';
  badge?: string;
}

const Badge = ({ text }: { text?: string }) => {
  return (
    <Flex
      borderRadius="lg"
      color="greyscale.100"
      backgroundColor="success.500"
      textTransform="uppercase"
      fontWeight="medium"
      px="12px"
      fontSize="xs"
      maxH="28px"
      alignItems="center"
    >
      {text}
    </Flex>
  );
};

export function PlanBoxHeader({
  subheading,
  planName,
  version,
  badge,
}: PlanBoxHeaderProps) {
  const hasBadge = Boolean(badge);

  const styles = {
    primary: {
      background: 'greyscale.200',
      color: 'primary.900',
    },
    secondary: {
      background: 'linear(194.43deg, #0D73FF 0%, #0958D7 100%)',
      color: 'greyscale.100',
    },
  };

  return (
    <VStack
      width="100%"
      bgGradient={styles[version].background}
      borderTopRadius="lg"
      align="stretch"
      padding="24px"
    >
      <Flex justifyContent="space-between" width="100%">
        <Text
          color={styles[version].color}
          fontWeight="700"
          fontSize={{
            base: '22px',
            md: '24px',
          }}
        >
          {subheading}
        </Text>
        {hasBadge ? <Badge text={badge} /> : null}
      </Flex>
      <Text
        color={styles[version].color}
        fontWeight="700"
        fontSize={{
          base: '36px',
          md: '48px',
        }}
      >
        {planName}
      </Text>
    </VStack>
  );
}
