import React from 'react';
import { useTranslation } from 'react-i18next';
import { Info } from '@airhelp/icons';
import {
  Modal,
  ModalOverlay,
  ModalBody,
  Button,
  ModalCloseButton,
  ModalContent,
  VStack,
  Center,
  Box,
  IconButton,
} from '@chakra-ui/react';
import { ErrorBadge, Loader } from 'elements';
import { useTrackEvent } from 'utils/tracking/hooks';
import { type Journey } from 'models/journey/Journey';
import useDeleteJourney from 'hooks/api/journey/useDeleteJourney';
import useCheckDeletableJourney from 'hooks/api/journey/useCheckDeletableJourney';
import DeleteAction from './DeleteAction';
import DeleteModalContent from './DeleteModalContent';

interface IComponent {
  journey: Journey;
  handleCloseDelete: () => void;
  isOpen: boolean;
}

const Delete: React.FC<IComponent> = ({
  journey,
  handleCloseDelete,
  isOpen,
}) => {
  const { t } = useTranslation();
  const { trackCtaClick } = useTrackEvent();

  const checkDeletable = useCheckDeletableJourney({
    id: journey.id,
    options: {
      enabled: isOpen,
      gcTime: 0,
    },
  });

  const { isLoading, isError, data: checkDeletableData } = checkDeletable;

  const deleteJourney = useDeleteJourney();

  const trackLabel = checkDeletableData?.deletable ? 'cancel' : 'ok';
  const trackPlacement = checkDeletableData?.deletable
    ? 'delete trip question'
    : 'delete trip information';

  const closeModalHandler = () => {
    handleCloseDelete();
    deleteJourney.reset();

    trackCtaClick(trackLabel, trackPlacement);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModalHandler}
      size="sm"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        {isLoading ? (
          <Center minHeight="200px">
            <Loader dataTestId="loader" />
          </Center>
        ) : (
          <>
            <ModalCloseButton
              borderRadius="sm"
              boxSize={2}
              top={4}
              right={4}
              backgroundColor="greyscale.300"
              sx={{
                svg: { boxSize: 2.5 },
                _hover: { backgroundColor: 'primary.200' },
                _active: {
                  backgroundColor: 'primary.800',
                  color: 'greyscale.200',
                },
              }}
              data-testid="btn-close-modal-delete-journey"
            />
            <ModalBody p={{ base: 4, md: 4 }} sx={{ overflow: 'auto' }}>
              <IconButton
                aria-label="info"
                icon={<Info w="auto" h={6} color="primary.500" />}
                size="lg"
                backgroundColor="greyscale.300"
                mb={4}
                pointerEvents="none"
              />
              {checkDeletableData ? (
                <DeleteModalContent checkDeletableData={checkDeletableData} />
              ) : null}
              {isError ? (
                <Box py={6}>
                  <ErrorBadge>{t('errors.something_went_wrong')}</ErrorBadge>
                </Box>
              ) : null}
              <VStack spacing={2}>
                {checkDeletableData?.deletable ? (
                  <DeleteAction
                    journey={journey}
                    handleCloseDelete={handleCloseDelete}
                  />
                ) : null}
                <Button
                  onClick={closeModalHandler}
                  variant="secondary"
                  size="s"
                  textTransform={
                    checkDeletableData?.deletable ? 'capitalize' : 'uppercase'
                  }
                  width="100%"
                  data-testid="cancel-button-delete-journey"
                >
                  {checkDeletableData?.deletable
                    ? t('common.cancel')
                    : t('common.ok')}
                </Button>
              </VStack>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default Delete;
