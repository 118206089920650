import type React from 'react';
import type { AirLuggagePaymentPax } from '@airhelp/plus';
import { type ReducerAction as AirLuggagePaymentAction } from 'types/reducers';

export const airLuggageInitialState = { airLuggageJourneys: [] };

export enum AirLuggagePaymentTypes {
  EDIT_PASSENGER = 'EDIT_PASSENGER',
  RESET = 'RESET',
}

export interface AirLuggagePaymentReducer {
  airLuggageJourneys: AirLuggagePaymentPax[];
}

export type AirLuggageAction = AirLuggagePaymentAction<
  AirLuggagePaymentTypes,
  AirLuggagePaymentPax | undefined
>;

export const airLuggagePaymentReducer: React.Reducer<
  AirLuggagePaymentReducer,
  AirLuggageAction
> = (state, action) => {
  const actions = {
    [AirLuggagePaymentTypes.EDIT_PASSENGER]: () => {
      const airLuggagePaymentPaxPayload =
        action.payload as AirLuggagePaymentPax;
      const airLuggageJourneys = [
        ...state.airLuggageJourneys,
        airLuggagePaymentPaxPayload,
      ];

      const existingIndex = state.airLuggageJourneys.findIndex(
        (pax) => pax.id === airLuggagePaymentPaxPayload.id,
      );

      if (existingIndex !== -1) {
        state.airLuggageJourneys[existingIndex] = airLuggagePaymentPaxPayload;
        return state;
      }

      return {
        ...state,
        airLuggageJourneys,
      };
    },
    [AirLuggagePaymentTypes.RESET]: () => {
      return airLuggageInitialState;
    },
  };

  if (typeof actions[action.type] === 'function') {
    return actions[action.type]();
  }
  throw new Error(
    `Action of type "${action.type}" is missing or is not a function`,
  );
};
