import React, { type PropsWithChildren } from 'react';
import { Box, Text } from '@chakra-ui/react';

interface IComponent extends PropsWithChildren {
  header?: string;
}

const ContentBox: React.FC<IComponent> = ({ children, header, ...props }) => {
  return (
    <Box
      w="100%"
      px={{ base: 6, md: 8 }}
      py={{ base: 6, md: 8 }}
      backgroundColor="greyscale.300"
      borderRadius="lg"
      borderTopLeftRadius="none"
      {...props}
    >
      {header ? (
        <Text mb={6} fontWeight="bold" data-testid="content-box-header">
          {header}
        </Text>
      ) : null}
      {children}
    </Box>
  );
};

export default ContentBox;
