import { type EditableJourney } from '@airhelp/plus';
import { leadingZerosPattern } from 'utils/validations';

export const sanitizeJourney = (journey: EditableJourney) => {
  const itineraries = journey?.itineraries.map((itinerary) => {
    const flights = itinerary?.flights.map((flight) => ({
      ...flight,
      flightNumber:
        flight?.flightNumber?.replace(leadingZerosPattern, '') ?? null,
    }));

    return { ...itinerary, flights };
  });

  return { ...journey, itineraries };
};
