import { type IconType } from '@airhelp/icons';
import { Flex } from '@chakra-ui/react';

interface BenefitItemProps {
  text: React.ReactNode;
  Icon: IconType;
  variant?: 'primary' | 'secondary' | 'disabled';
}

export function BenefitItem({
  text,
  Icon,
  variant = 'primary',
}: BenefitItemProps) {
  const styles = {
    primary: {
      background: 'success.200',
      text: 'primary.900',
      icon: 'success.500',
      border: '1px solid #DDF7EE',
    },
    secondary: {
      background: 'greyscale.100',
      text: 'primary.900',
      icon: 'primary.400',
      border: '1px solid #F1F4F8',
    },
    disabled: {
      background: 'greyscale.300',
      text: 'greyscale.500',
      icon: 'greyscale.500',
      border: '1px solid #F1F4F8',
    },
  };

  return (
    <Flex w="100%" gap={3} alignItems="center" color={styles[variant].text}>
      <Flex
        backgroundColor={styles[variant].background}
        borderRadius="md"
        justifyContent="center"
        alignItems="center"
        minW="40px"
        height="40px"
        border={styles[variant].border}
      >
        <Icon color={styles[variant].icon} width="16px" height="16px" />
      </Flex>
      <span>{text}</span>
    </Flex>
  );
}
