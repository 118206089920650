import React from 'react';
import { Text, VStack } from '@chakra-ui/react';

const PassInformationItem: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => {
  const mapLabelToTestId: Record<string, string> = {
    'lounges.passes.voucher.name': 'lounge-pass-details-name',
    'lounges.passes.voucher.qr': 'lounge-pass-details-qr-number',
    'lounges.passes.voucher.expires_on': 'lounge-pass-details-valid-until',
  };

  const getDataTestId = (val: string) => {
    return mapLabelToTestId[val];
  };

  return (
    <VStack width="100%" align="flex-start" spacing={0}>
      <Text fontSize="sm" color="greyscale.500">
        {label}
      </Text>
      <Text
        fontSize="md"
        color="primary.900"
        data-testid={getDataTestId(label)}
      >
        {value}
      </Text>
    </VStack>
  );
};

export default PassInformationItem;
