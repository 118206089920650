import { ModalBody, List, ListItem, Text } from '@chakra-ui/react';
import { useTranslation, Trans } from 'react-i18next';

export const UpgradeModalBody = () => {
  const { t } = useTranslation();

  return (
    <ModalBody p={{ base: '16px 16px 6px 16px', md: '16px' }}>
      <Text fontSize="20px" as="span" fontWeight="bold" color="primary.900">
        <Trans
          i18nKey="new_benefits_modal.we_have_new_benefits"
          components={{
            span: (
              <Text
                as="span"
                fontWeight="bold"
                fontSize="20px"
                color="secondary.500"
              />
            ),
          }}
        />
      </Text>
      <Text fontWeight="500" fontSize="sm" mt="16px">
        {t('new_benefits_modal.upgrade_to_smart')}
      </Text>
      <List spacing={3} mt="16px">
        <ListItem fontSize="sm" color="greyscale.600">
          🌟 {t('new_benefits_modal.comfy_lounge_access')}
        </ListItem>{' '}
        <ListItem fontSize="sm" color="greyscale.600">
          🧳{' '}
          {t('new_benefits_modal.compensation_for_lost_bag', {
            amount: 100,
          })}
        </ListItem>
        <ListItem fontSize="sm" color="greyscale.600">
          🚗 {t('new_benefits_modal.deals')}
        </ListItem>
      </List>
    </ModalBody>
  );
};
