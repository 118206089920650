import React, {
  type PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

export type TabState = 'All' | 'Active' | 'Past';

export type ActiveTabsState = Record<string, TabState>;

interface TabsContextInterface {
  tabsState: ActiveTabsState;
  setTabsState: React.Dispatch<React.SetStateAction<ActiveTabsState>>;
}

const TabsContext = createContext<TabsContextInterface>({
  tabsState: {},
  setTabsState: () => null,
});

const TabsContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [tabsState, setTabsState] = useState<ActiveTabsState>({});

  return (
    <TabsContext.Provider value={{ tabsState, setTabsState }}>
      {children}
    </TabsContext.Provider>
  );
};

const useTabsState = (tabId: string, defaultTab: TabState) => {
  const { tabsState, setTabsState } = useContext(TabsContext);

  useEffect(() => {
    if (tabsState[tabId] === undefined) {
      setTabsState({ ...tabsState, [tabId]: defaultTab });
    }
  }, [tabsState, setTabsState, defaultTab, tabId]);

  return tabsState[tabId];
};

export { TabsContext, TabsContextProvider, useTabsState };
