import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Navigate } from 'react-router-dom';
import { track } from 'react-tracking';
import Claims from 'components/Claims';
import { PAGE_PATHS } from 'config/routes';
import { usePageNavigationHeader } from 'contexts/LayoutContext/LayoutContext';
import { Loader } from 'elements';
import useComputedClaimEnquiries from 'hooks/api/claims/useComputedClaimEnquiries';
import useFetchMyProfile from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import useProtectedPage from 'hooks/routing/useProtectedPage';
import { usePageTracking } from 'utils/tracking';

const IndexPage: React.FC = () => {
  usePageTracking();
  useProtectedPage();

  const { t } = useTranslation();

  usePageNavigationHeader({
    title: t('navigation.claims'),
    backPath: generatePath(PAGE_PATHS.HOME_PAGE),
  });

  const { data: userProfile, isLoading: isUserProfileLoading } =
    useFetchMyProfile();

  const {
    data: claimEnquiries,
    isLoading: isClaimEnquiriesLoading,
    isError: isClaimEnquiriesError,
  } = useComputedClaimEnquiries();

  if (isUserProfileLoading || isClaimEnquiriesLoading) {
    return <Loader dataTestId="loader" />;
  }

  if (isClaimEnquiriesError) {
    return <Navigate to={{ pathname: generatePath(PAGE_PATHS.ERROR_PAGE) }} />;
  }

  if (!userProfile) {
    return <Navigate to={{ pathname: generatePath(PAGE_PATHS.HOME_PAGE) }} />;
  }

  return <Claims claimEnquiries={claimEnquiries} />;
};

export default track({ page_type: 'Claims' })(IndexPage);
