import React from 'react';
import { useTranslation } from 'react-i18next';
import { AuthSignupForm } from '@airhelp/funnel';
import { Flex } from '@chakra-ui/react';
import {
  useMarketingConsentLabel,
  usePrivacyConsentLabel,
} from 'elements/UserConsentsForm/common';
import useEffectOnce from 'hooks/useEffectOnce';
import { useTrackEvent } from 'utils/tracking/hooks';
import { type SignupSubmitData } from 'models/userProfile/Signup';

export type ActivationSubmitData = Omit<SignupSubmitData, 'email'>;

export interface IPageContentComponentParams {
  isSubmitting: boolean;
  isSubmitError: boolean;
  email: string;
  onSubmit: (submitData: ActivationSubmitData) => void;
}

const RegistrationView: React.FC<IPageContentComponentParams> = ({
  isSubmitting,
  isSubmitError,
  email,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { trackOnboardingFunnel, trackCtaClick } = useTrackEvent();

  useEffectOnce(() => {
    trackOnboardingFunnel('partner user onboarding with password setup');
  });

  const handleSubmit = (data: ActivationSubmitData) => {
    trackCtaClick('finish', 'finish registration page');
    onSubmit(data);
  };

  return (
    <Flex justifyContent="center" width="100%">
      <AuthSignupForm
        formError={isSubmitError ? t('errors.something_went_wrong') : undefined}
        i18n={{
          title: t('authentication.create_an_account'),
          actionText: t('authentication.create_an_account'),
          confirmPasswordPlaceholder: t('authentication.password_confirmation'),
          divider: null,
          emailPlaceholder: t('authentication.email_address'),
          passwordPlaceholder: t('authentication.password'),
          marketingConsentLabel: useMarketingConsentLabel(),
          privacyConsentLabel: usePrivacyConsentLabel(),
          selectAllLabel: t('common.select_all'),
          signinCallout: null,
        }}
        onSubmit={handleSubmit}
        defaultValues={{ email }}
        isSubmitting={isSubmitting}
        isFullWidth
        isEmailDisabled
      />
    </Flex>
  );
};

export default RegistrationView;
