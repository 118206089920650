import { useTranslation } from 'react-i18next';
import { Flex, Text } from '@chakra-ui/react';
import { TechnicalSupport, MoneyFee, Agenda } from '@airhelp/icons';
import { BenefitItem } from './BenefitItem';

export function AnnualSupport() {
  const { t } = useTranslation();

  return (
    <Flex direction="column" gap={4} mt={6}>
      <Text fontSize="md" color="primary.900" fontWeight="bold">
        {t('special_offer.annual_support.title')}
      </Text>
      <BenefitItem
        Icon={MoneyFee}
        variant="secondary"
        text={t('special_offer.annual_support.no_fee', {
          amount: 100,
        })}
      />
      <BenefitItem
        Icon={TechnicalSupport}
        text={t('special_offer.annual_support.dedicated_support')}
        variant="secondary"
      />
      <BenefitItem
        Icon={Agenda}
        text={t('special_offer.annual_support.flight_status')}
        variant="secondary"
      />
    </Flex>
  );
}
