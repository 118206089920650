import { Expose } from 'class-transformer';
import { type CurrencyCode } from 'stores/preferences/currencies';

export interface IPaymentDetails {
  currency: CurrencyCode;
  discountValue: number;
  discountedPrice: number;
  productPrice: number;
}

export class PaymentDetails implements IPaymentDetails {
  @Expose() currency: CurrencyCode;
  @Expose() discountValue: number;
  @Expose() discountedPrice: number;
  @Expose() productPrice: number;
}
