import React from 'react';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import CollectUserDetails from '../CollectUserDetails';

const Onboarding = () => {
  const { userProfile } = useMyProfile();
  const policy = userProfile?.policy;

  const showCollectUserDetails =
    policy?.membership?.active && !policy.dashboard?.personalDetailsProvided;

  if (showCollectUserDetails) {
    return <CollectUserDetails />;
  }
  return null;
};

export default Onboarding;
