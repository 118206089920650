import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import BackToButton from 'components/BackToButton';
import FunnelLayout from 'components/Layouts/FunnelLayout';
import { PAGE_PATHS } from 'config/routes';
import { EditedJourneyContext, EditedJourneyContextProvider } from 'contexts';
import { FlightsFunnel } from 'funnels/FlightsFunnel';
import useProtectedPage from 'hooks/routing/useProtectedPage';
import { usePageTracking } from 'utils/tracking';
import { useTrackEvent } from 'utils/tracking/hooks';

const DEFAULT_ROUTE_FROM = 'dashboard';

const NewFlightsPage = () => {
  usePageTracking();
  useProtectedPage();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname, state } = useLocation();
  const [searchParams] = useSearchParams();
  const { trackCtaClick } = useTrackEvent();
  const { itineraryIndex, fromRoute } = useContext(EditedJourneyContext);

  const [isFunnelRouted, setIsFunnelRouted] = useState(false);
  const comingFromDashboard = fromRoute === 'dashboard';

  const steps = [
    t('funnels.add_new_flight.navigation.add_trip'),
    t('funnels.add_new_flight.navigation.trip_details'),
    t('funnels.add_new_flight.navigation.finish'),
  ];

  const handleCtaClickEvent = () => {
    const routes = [
      'route-destination',
      'connecting-flights',
      'flights-details',
      'air-luggage',
    ];
    const regex = /\/new\/([^/]+)/;
    const route = regex.exec(location.pathname)?.[1].replace(/-/g, ' ');

    if (!comingFromDashboard) {
      trackCtaClick('back to journeys', 'add trip');
      return;
    }

    if (
      routes.some((routeValue) => location.pathname.includes(routeValue)) &&
      route
    ) {
      trackCtaClick(
        'back to dashboard',
        `${itineraryIndex === 0 ? 'outbound' : 'inbound'} - ${route}`,
      );
    } else if (location.pathname.includes('return-flight')) {
      trackCtaClick('back to dashboard', 'return flights');
    }
  };

  // this effect make sure that the customer cannot start the funnel in the middle after page refresh
  useEffect(() => {
    if (!isFunnelRouted) {
      const sanitizedPathname = pathname.replace(/\/$/, '');
      const itineraryIndexFromUrl = searchParams.toString();
      if (
        sanitizedPathname !== '/flights/new/route-destination' ||
        itineraryIndexFromUrl !== 'itineraryIndex'
      ) {
        navigate({
          pathname: generatePath(PAGE_PATHS.FLIGHTS.NEW.ROUTE_DESTINATION_PAGE),
          search: `?itineraryIndex=0&src=${state?.from ?? DEFAULT_ROUTE_FROM}`,
        });
      }

      setIsFunnelRouted(true);
    }
  }, [isFunnelRouted]);

  if (!isFunnelRouted) {
    return null;
  }

  return (
    <EditedJourneyContextProvider>
      <FunnelLayout
        steps={steps}
        disableCurrencySwitcher
        sideHeader={
          <Box mb={10}>
            <BackToButton
              onClick={handleCtaClickEvent}
              navigateTo={
                comingFromDashboard
                  ? generatePath(PAGE_PATHS.HOME_PAGE)
                  : generatePath(PAGE_PATHS.JOURNEYS_PAGE)
              }
              dataTestId="back-to-button-desktop"
            />
          </Box>
        }
        headerStart={
          <Box alignSelf="center" me={4}>
            <BackToButton
              onClick={handleCtaClickEvent}
              navigateTo={
                comingFromDashboard
                  ? generatePath(PAGE_PATHS.HOME_PAGE)
                  : generatePath(PAGE_PATHS.JOURNEYS_PAGE)
              }
              variantBreakpoint="xl"
              dataTestId="back-to-button-mobile"
            />
          </Box>
        }
      >
        <FlightsFunnel />
      </FunnelLayout>
    </EditedJourneyContextProvider>
  );
};

export default NewFlightsPage;
