import { useTranslation } from 'react-i18next';
import { Flex, Heading, VStack, Text } from '@chakra-ui/react';
import { ProductDisplayName } from '@airhelp/plus';
import useFetchUpgradeOffers from 'hooks/api/offers/useFetchUpgradeOffers';
import { Loader } from 'elements';
import { type CurrencyCode } from 'stores/preferences/currencies';
import { SMART_UPGRADE_FIRST_YEAR_SAVE_AMOUNT } from 'config/benefits';
import { type Item } from 'utils/tracking/props/ga4/types';
import { SmartPlan } from './parts/SmartPlan';
import { EssentialPlan } from './parts/EssentialPlan';

interface PlanComparisonProps {
  currency: string;
  ecommerceItem: Item;
}

export default function PlanComparison({
  currency,
  ecommerceItem,
}: PlanComparisonProps) {
  const { data, isLoading } = useFetchUpgradeOffers({ currency });
  const { t } = useTranslation();

  const renewOffer = data?.renewOffer;
  const upgradeOffer = data?.upgradeOffer;

  return (
    <VStack>
      <Flex
        textTransform="uppercase"
        color="primary.400"
        border="1px solid"
        borderRadius="xl"
        fontWeight="500"
        fontSize="xs"
        backgroundColor="primary.100"
        padding="4px 12px"
      >
        {t('common.special_offer')}
      </Flex>
      <Heading
        as="h2"
        fontSize={{ base: '30px', md: '4xl' }}
        textAlign="center"
      >
        {t('special_offer.title', {
          amount: SMART_UPGRADE_FIRST_YEAR_SAVE_AMOUNT,
          planName: ProductDisplayName.AIRHELP_PLUS_SMART,
        })}
      </Heading>
      <Text
        color="greyscale.600"
        fontWeight="medium"
        fontSize="md"
        textAlign="center"
        maxW="80%"
        mb={{
          base: 5,
          md: 10,
        }}
      >
        {t('special_offer.upgrade_to_new_plan')}
      </Text>
      {isLoading ? (
        <Loader />
      ) : (
        <Flex
          direction={{
            base: 'column',
            md: 'row',
          }}
          gap={{
            base: '16px',
            md: '32px',
          }}
        >
          {renewOffer ? (
            <EssentialPlan
              price={renewOffer.productPrice}
              currency={renewOffer.code as CurrencyCode}
            />
          ) : null}
          {upgradeOffer ? (
            <SmartPlan
              price={upgradeOffer.productPrice}
              discountedPrice={
                upgradeOffer.discountedPrice ?? upgradeOffer.productPrice
              }
              currency={upgradeOffer.code as CurrencyCode}
              ecommerceItem={ecommerceItem}
            />
          ) : null}
        </Flex>
      )}
    </VStack>
  );
}
