import React from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '@airhelp/icons';
import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import { Card } from 'elements';
import { type AirLuggageJourney } from 'models/journey/AirLuggageJourney';
import { type AirPayoutJourney } from 'models/journey/AirPayoutJourney';
import { type IBoxTag } from './AirBundleTagStatus/AirBundleTagStatus';

const UserCards = ({
  airBundleJourneys,
}: {
  airBundleJourneys: AirLuggageJourney[] | AirPayoutJourney[];
  statusBox: IBoxTag;
}) => {
  const { t } = useTranslation();

  return (
    <Card
      padding={{ base: 4, md: 5 }}
      backgroundColor="primary.100"
      borderBottomRadius="0"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          {airBundleJourneys.map((airBundleJourney) => (
            <Flex
              align="center"
              justifyContent="space-between"
              key={airBundleJourney.id}
              m={1}
            >
              <Flex align="center">
                <IconButton
                  aria-label="user"
                  icon={<User width={5} height={5} color="primary.500" m={2} />}
                  size="lg"
                  backgroundColor="greyscale.100"
                  width={9}
                  minW={9}
                  height={9}
                  me={3}
                  pointerEvents="none"
                />
                <Flex
                  flexDirection={{ base: 'column', md: 'row' }}
                  alignItems={{ base: 'flex-start', md: 'center' }}
                >
                  <Text
                    fontSize="md"
                    color="greyscale.600"
                    me={{ base: 0, md: 3 }}
                  >
                    {t('common.single_passenger')}:
                  </Text>
                  <Text fontSize="md" fontWeight="medium">
                    {airBundleJourney?.name}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          ))}
        </Box>
      </Flex>
    </Card>
  );
};

export default UserCards;
