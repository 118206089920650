import React from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar, MoneyBack, ShieldPlusSolid } from '@airhelp/icons';
import PlanDetailsList from '../PlanDetailsList';
import { type PlanItem } from '../PlanDetailsList/PlanDetailsList';
import Section from '../Section';

const FeaturesNotIncluded = () => {
  const { t } = useTranslation();
  const featuresNotIncluded: PlanItem[] = [
    {
      title_tkey: 'my_account.my_benefits.benefits_not_included.list.rebooking',
      icon: Calendar,
      tooltip: {
        content:
          'my_account.my_benefits.benefits_not_included.list.rebooking_desc',
      },
      key: 'rebooking',
      included: false,
    },
    {
      title_tkey: 'my_account.my_benefits.benefits_not_included.list.refund',
      icon: MoneyBack,
      tooltip: {
        content:
          'my_account.my_benefits.benefits_not_included.list.refund_desc',
      },
      key: 'refund',
      included: false,
    },
    {
      title_tkey:
        'my_account.my_benefits.benefits_not_included.list.travel_insurance',
      icon: ShieldPlusSolid,
      tooltip: {
        content:
          'my_account.my_benefits.benefits_not_included.list.travel_insurance_desc',
      },
      key: 'travel_insurance',
      included: false,
    },
  ];
  return (
    <Section
      header={t('my_account.my_benefits.benefits_not_included.header')}
      description={t(
        'my_account.my_benefits.benefits_not_included.description',
      )}
    >
      <PlanDetailsList planDetails={featuresNotIncluded} />
    </Section>
  );
};

export default FeaturesNotIncluded;
