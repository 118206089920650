import React, {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import cookie from 'react-cookies';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { USER_LANGUAGE_COOKIE } from 'config/cookies';
import { createCookieOptions } from 'utils/cookies';
import {
  type Locale,
  locales,
  normalizeLocale,
  mapBrowserLanguageToLocale,
  dateFnLocale,
  singleLanguage,
} from './locales';

const browserPreferredLanguage = navigator.language;

const { lang }: AppSettingsLangParam = queryString.parse(
  window.location.search,
);

const userLanguage = cookie.load(USER_LANGUAGE_COOKIE) as string;
const singleLang = singleLanguage(lang) as string;

export const DEFAULT_LOCALE = normalizeLocale(
  singleLang ||
    userLanguage ||
    mapBrowserLanguageToLocale(browserPreferredLanguage) ||
    'en',
);

interface AppSettingsLangParam {
  lang?: Locale;
}

export interface LocaleContextContextType {
  locale: Locale;
  setLocale: (value: Locale) => void;
}

export const LocaleContext = createContext<
  LocaleContextContextType | undefined
>(undefined);

export const dateFnLocaleHelper = (locale: string) =>
  dateFnLocale[locale] || dateFnLocale['en-GB'];

const LocaleContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [locale, setLocale] = useState<Locale>(DEFAULT_LOCALE);

  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale);
    cookie.save(
      USER_LANGUAGE_COOKIE,
      locale,
      createCookieOptions({ path: '/' }),
    );
  }, [locale]);

  const setNormalizedLocale = useCallback(
    (l: string) => {
      const localeValue = normalizeLocale(l);
      if (locales[localeValue]) {
        setLocale(localeValue);
      }
    },
    [setLocale],
  );

  const value = {
    locale,
    setLocale: setNormalizedLocale,
  };

  return (
    <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
  );
};

export default LocaleContextProvider;

export const useLocale = (): LocaleContextContextType => {
  const context = useContext(LocaleContext);
  if (!context) {
    throw new Error('useLocale must be used within a LocaleContextProvider');
  }

  return context;
};
