import * as ClaimsPages from 'pages/Claims';
import { Layout } from '../components/Claim/Layout';

export const ClaimsRoutes = [
  {
    path: 'claims',
    element: <ClaimsPages.IndexPage />,
  },
  {
    path: 'claims/:id',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <ClaimsPages.ClaimProgressPage />,
      },
      {
        path: 'compensation',
        element: <ClaimsPages.ClaimCompensationPage />,
      },
      {
        path: 'documents',
        element: <ClaimsPages.ClaimDocumentsPage />,
      },
      {
        path: 'details',
        element: <ClaimsPages.ClaimDetailsPage />,
      },
      {
        path: 'history',
        element: <ClaimsPages.ClaimHistoryPage />,
      },
    ],
  },
];
