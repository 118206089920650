import React from 'react';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { Input } from '@airhelp/react';
import { Box } from '@chakra-ui/react';
import { type TFunction } from 'i18next';
import paymentsFieldsConfig from 'utils/airBundle/paymentsFieldsConfig';

const DEFAULT_BRANCH_CODE_MIN_LENGTH = 4;
const DEFAULT_BRANCH_CODE_MAX_LENGTH = 5;

const branchCode = ({
  form,
  t,
  countryCode,
}: {
  form: UseFormReturn;
  t: TFunction;
  countryCode: string;
}) => {
  const BRANCH_CODE_MAX_LENGTH =
    paymentsFieldsConfig[countryCode]?.rules?.branchCode?.maxLength ||
    DEFAULT_BRANCH_CODE_MAX_LENGTH;

  const BRANCH_CODE_MIN_LENGTH =
    paymentsFieldsConfig[countryCode]?.rules?.branchCode?.minLength ||
    DEFAULT_BRANCH_CODE_MIN_LENGTH;

  const BRANCH_CODE_REQUIRED =
    !paymentsFieldsConfig[countryCode]?.rules?.branchCode?.optional;

  return (
    <Controller
      name="branchCode"
      control={form.control}
      rules={{
        required: {
          value: BRANCH_CODE_REQUIRED,
          message: t('errors.required'),
        },
        pattern: { value: /^\S+/, message: t('errors.required') },
        minLength: {
          value: BRANCH_CODE_MIN_LENGTH,
          message: t('errors.too_short', {
            attribute: t('common.branch_code'),
            min: BRANCH_CODE_MIN_LENGTH,
          }),
        },
        maxLength: {
          value: BRANCH_CODE_MAX_LENGTH,
          message: t('errors.too_long', {
            attribute: t('common.branch_code'),
            max: BRANCH_CODE_MAX_LENGTH,
          }),
        },
      }}
      defaultValue=""
      render={({
        field: { onChange, name, value },
        fieldState: { invalid, error },
      }) => (
        <Box width="100%" minHeight="106px">
          <Input
            isInvalid={invalid}
            errorMessage={error?.message || ''}
            name={name}
            label={t('common.branch_code')}
            value={value}
            placeholder={t('common.branch_code')}
            onChange={onChange}
            minLength={BRANCH_CODE_MIN_LENGTH}
            maxLength={BRANCH_CODE_MAX_LENGTH}
            data-testid="branch-code"
          />
        </Box>
      )}
    />
  );
};

export default branchCode;
