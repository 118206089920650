import { Suspense } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useClaimSuspense } from '@airhelp/webapp/react';
import {
  Box,
  Grid,
  GridItem,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { isCountryFlagCode, UNKNOWN_COUNTRY_CODE } from '@airhelp/flags';
import { Loader } from 'elements';
import { type CurrencyCode } from 'stores/preferences/currencies';
import { SupportBox } from '../SupportBox';
import { useNavigationBar } from '../Layouts/Default/NavigationBar/NavigationBar';
import ReferralCard from '../ReferralCard';
import { ClaimOverview } from './Overview/Overview';
import { Header } from './Header/Header';
import { Navigation } from './Navigation/Navigation';

export function Layout() {
  const isLargeScreen = useBreakpointValue({ base: false, xl: true });
  const { id } = useParams();
  const { data: claim } = useClaimSuspense({
    params: { id: Number(id) },
  });
  const { NavigationBarPortal } = useNavigationBar();

  const templateAreas = useBreakpointValue({
    base: `"header"
      "main-content"`,
    xl: `"header header"
      "main-content faq"`,
  });

  if (!claim) {
    return null;
  }

  const flight = claim.itinerary.flights.at(0);

  // TODO - decide what to do when there is no flight available
  if (!flight) {
    return null;
  }

  const { departureAirport, arrivalAirport } = flight;
  const rawDepartureAirportCountryCode =
    departureAirport.countryCode.toLowerCase();
  const rawArrivalAirportCountryCode = arrivalAirport.countryCode.toLowerCase();

  const departureAirportCountryCode = isCountryFlagCode(
    rawDepartureAirportCountryCode,
  )
    ? rawDepartureAirportCountryCode
    : UNKNOWN_COUNTRY_CODE;
  const arrivalAirportCountryCode = isCountryFlagCode(
    rawArrivalAirportCountryCode,
  )
    ? rawArrivalAirportCountryCode
    : UNKNOWN_COUNTRY_CODE;

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Grid
          w="full"
          columnGap={8}
          templateAreas={templateAreas}
          autoRows="min-content"
          templateColumns={{
            base: 'minmax(100%, 632px)',
            xl: '785px 377px',
          }}
        >
          <GridItem area="header">
            <Header title="Your Claim" />
          </GridItem>
          <GridItem area="main-content">
            <VStack spacing={8} align="stretch">
              <ClaimOverview
                currency={claim.claimPayout.currency as CurrencyCode}
                departureAirportCountryCode={departureAirportCountryCode}
                arrivalAirportCountryCode={arrivalAirportCountryCode}
                departureAirportName={departureAirport.name}
                arrivalAirportName={arrivalAirport.name}
                departureAirportCode={departureAirport.iata}
                arrivalAirportCode={arrivalAirport.iata}
                claimId={claim.id}
                compensation={claim.finalCompensationInPreferredCurrency}
              />
              <ClaimReferralCard variant="section" />
              <VStack gap={4} align="stretch">
                <Navigation
                  showDocumentNotification={claim.pendingDocumentRequest}
                />
                <Outlet />
              </VStack>
            </VStack>
          </GridItem>
          {isLargeScreen ? (
            <GridItem>
              <SupportBox />
            </GridItem>
          ) : null}
        </Grid>
      </Suspense>
      <NavigationBarPortal>
        <Box p={4}>
          <ClaimReferralCard />
        </Box>
      </NavigationBarPortal>
    </>
  );
}

function ClaimReferralCard({
  variant = 'box',
}: {
  variant?: 'box' | 'section';
}) {
  return (
    <ReferralCard
      variant={variant}
      referralLink="http://google.com"
      i18n={{
        header: 'Refer and <span>get €10</span> for each successful claim!',
        subheader: 'Easy cash for sharing!',
        modal: {
          header: 'Refer and <span>get €10</span> for each successful claim!',
          subheader:
            'Copy the link to share with your friends. You’ll earn €10 for each claim we approve.',
          actionText: 'Copy',
          copiedActionText: 'Copied!',
          readMore: 'Read more about our referral program',
          readMoreLink: 'http://google.com',
        },
      }}
    />
  );
}
