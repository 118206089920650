import { create } from 'zustand';

export interface PaymentDetails {
  currency: string | null;
  discountValue?: number | null;
  discountedPrice?: number | null;
  productPrice?: number | null;
}

export interface IStripePaymentStore {
  paymentDetails: PaymentDetails | null;
  setPaymentDetails: (paymentDetails: PaymentDetails) => void;
  paymentIntentId: string | null;
  setPaymentIntentId: (clientSecret: string | null) => void;
  stripeElementId: number;
  setStripeElementId: () => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export const useStripeStore = create<IStripePaymentStore>((set) => ({
  paymentDetails: null,
  setPaymentDetails: (paymentDetails: PaymentDetails) => {
    set(() => ({ paymentDetails }));
  },
  paymentIntentId: null,
  setPaymentIntentId: (clientSecret: string | null) => {
    const paymentIntentId = clientSecret?.match(/^(pi_.*)_secret/);

    if (!paymentIntentId) {
      return;
    }

    set(() => ({ paymentIntentId: paymentIntentId[1] }));
  },
  stripeElementId: 0,
  setStripeElementId: () => {
    set((state) => ({ stripeElementId: state.stripeElementId + 1 }));
  },
  isLoading: false,
  setIsLoading: (isLoading: boolean) => {
    set(() => ({ isLoading }));
  },
}));
