let SCRIPT_LOADED = false;
let ERROR_HANDLED = false;

const SCRIPT_LOAD_TIMEOUT = 3000;

function handleScriptLoaded(
  resolveHandler: () => void,
  customOnLoad?: () => void,
) {
  return () => {
    resolveHandler();
    customOnLoad && customOnLoad();
    SCRIPT_LOADED = true;
  };
}

function handleScriptLoadError(
  rejectHandler: (reason: string) => void,
  customOnError?: () => void,
  concreteReason?: string,
) {
  return () => {
    if (!ERROR_HANDLED) {
      rejectHandler(`External script load error${concreteReason || ''}`);
      customOnError && customOnError();
      ERROR_HANDLED = true;
    }
  };
}

export const appendExternalScript = async (
  url: string,
  customOnLoad?: () => void,
  customOnError?: () => void,
): Promise<void> => {
  const head = document.querySelector('head');
  const scriptExists = head?.querySelector(`script[src="${url}"]`);

  if (scriptExists) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('src', url);

  return new Promise<void>((resolve, reject) => {
    script.onload = handleScriptLoaded(resolve, customOnLoad);
    script.onerror = handleScriptLoadError(reject, customOnError);

    head?.appendChild(script);

    setTimeout(() => {
      if (!SCRIPT_LOADED) {
        handleScriptLoadError(
          reject,
          customOnError,
          `Timeout ${SCRIPT_LOAD_TIMEOUT}ms`,
        )();
      }
    }, SCRIPT_LOAD_TIMEOUT);
  });
};
