import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { LuggageSolid } from '@airhelp/icons';
import AirBundleTagStatus from 'components/Journey/AirBundle/AirBundleTagStatus';
import useGetAirLuggageStatusBadge from 'components/Journey/AirLuggage/getAirLuggageStatusBadge';
import { NotificationDot } from 'elements/PulsatingNotificationDot/PulsatingNotificationDot';
import { type AirLuggageJourney } from 'models/journey/AirLuggageJourney';
import { airBundleTableStyle } from '../AirBundleThead';
import AirLuggageAction from './AirLuggageAction';
import { useGetAirLuggageTooltipStatus } from './useGetAirLuggageTooltipStatus';

const AirLuggageInsuranceViewItem = ({
  airLuggageJourney,
  lastElement,
}: {
  airLuggageJourney: AirLuggageJourney;
  lastElement: boolean;
}) => {
  const { t } = useTranslation();

  const { status, showNotification } = airLuggageJourney;
  const boxTag = useGetAirLuggageStatusBadge(status);
  const tooltipLabel = useGetAirLuggageTooltipStatus(airLuggageJourney);

  return (
    <React.Fragment key={airLuggageJourney.id}>
      {/* DesktopView */}
      <Flex
        alignItems="center"
        display={{ base: 'none', md: 'grid' }}
        gridTemplateColumns="repeat(3, 1fr)"
        py={4}
        pb={lastElement ? 0 : 4}
        borderBottom={lastElement ? 'none' : '1px solid'}
        borderColor="greyscale.400"
      >
        <Flex alignItems="center">
          {showNotification ? <NotificationDot /> : null}
          <Text {...airBundleTableStyle.td} ms={showNotification ? 2 : 0}>
            {airLuggageJourney.name}
          </Text>
          <LuggageSolid
            width={4}
            height={4}
            color="greyscale.600"
            ms={2}
            me={1}
          />
          <Text fontSize="xs" color="greyscale.600">
            {airLuggageJourney.maxBags}
          </Text>
        </Flex>
        <AirBundleTagStatus
          statusBox={boxTag}
          tooltipLabel={tooltipLabel}
          dataTestId="status-tag-airluggage"
        />
        <Text
          {...airBundleTableStyle.td}
          color="greyscale.500"
          fontWeight="normal"
          textAlign="right"
        >
          <AirLuggageAction airLuggageJourney={airLuggageJourney} />
        </Text>
      </Flex>
      {/* MobileView */}
      <Flex
        display={{ base: 'flex', md: 'none' }}
        flexDirection="column"
        background="greyscale.300"
        borderRadius="lg"
        p={4}
        mb={2}
      >
        <Flex justifyContent="space-between" alignItems="center" pb={3}>
          <Flex alignItems="center">
            {showNotification ? <NotificationDot /> : null}
            <Text {...airBundleTableStyle.td} ms={showNotification ? 2 : 0}>
              {airLuggageJourney.name}
            </Text>
          </Flex>
          <Flex>
            <Text fontSize="xs" color="greyscale.600">
              {airLuggageJourney.maxBags}
            </Text>
            <LuggageSolid width={4} height={4} color="greyscale.600" ms={2} />
          </Flex>
        </Flex>
        <Flex justifyContent="space-between" pb={4}>
          <Text {...airBundleTableStyle.th}>{t('common.status')}</Text>
          <AirBundleTagStatus
            statusBox={boxTag}
            tooltipLabel={tooltipLabel}
            dataTestId="status-tag-airluggage"
          />
        </Flex>
        <Flex justifyContent="space-between" pb={4}>
          <Text {...airBundleTableStyle.th}>{t('common.action')}</Text>
          <Text
            {...airBundleTableStyle.td}
            color="greyscale.500"
            fontWeight="normal"
          >
            <AirLuggageAction airLuggageJourney={airLuggageJourney} />
          </Text>
        </Flex>
      </Flex>
    </React.Fragment>
  );
};

export default AirLuggageInsuranceViewItem;
