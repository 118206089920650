import type { UserProfile } from 'models/userProfile/UserProfile';

function getUserSubscriptionName(userProfile?: UserProfile): string | null {
  return userProfile?.policy?.membership?.productName || null;
}

function getUserBusinessType(userProfile?: UserProfile): string | null {
  const policy = userProfile?.policy;

  if (!policy) {
    return null;
  }

  if (policy.membership) {
    return 'b2c';
  } else if (policy.trip) {
    return policy.trip.partnerSlug;
  }

  return null;
}

function getUserHashedEmail(userProfile?: UserProfile): string | null {
  return userProfile?.hashedEmail || null;
}

export { getUserSubscriptionName, getUserBusinessType, getUserHashedEmail };
