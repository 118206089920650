import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  type BankFieldsList,
  type Journey,
  InsuranceType,
} from '@airhelp/plus';
import { Box, Text } from '@chakra-ui/react';
import cookie from 'react-cookies';
import { useSearchParams } from 'react-router-dom';
import { TURNSTILE_COOKIE } from 'config/cookies';
import AirBundlePayoutActions from 'components/Journey/AirBundle/AirBundlePayoutActions';
import PayoutForm from 'components/Journey/AirBundle/Payment/PayoutForm';
import UserCards from 'components/Journey/AirBundle/UserCards';
import { Card, ErrorBadge } from 'elements';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { useTrackEvent } from 'utils/tracking/hooks';
import { type AirLuggageJourney } from 'models/journey/AirLuggageJourney';
import useUpdateAirLuggageJourneys from 'hooks/api/airBundle/useUpdateAirLuggageJourneys';
import ContactLink from 'elements/ContactLink/ContactLink';
import useGetAirLuggageStatusBadge from '../getAirLuggageStatusBadge';

interface IComponent {
  journeyListData: AirLuggageJourney[];
  onPayoutSuccessful: (successful: boolean) => void;
  journeyData: Journey;
}

const PayoutUpdateView: React.FC<IComponent> = ({
  journeyListData,
  onPayoutSuccessful,
  journeyData,
}) => {
  const form = useForm({ mode: 'onSubmit' });
  const { t } = useTranslation();
  const { trackPageInteractions } = useTrackEvent();
  const { userProfile } = useMyProfile();

  const [submitError, setSubmitError] = useState<boolean>(false);
  const [isSubmitComplete, setIsSubmitComplete] = useState(false);

  const updatePayout = useUpdateAirLuggageJourneys();
  const status = journeyListData[0]?.status;
  const beneficiaryFullName = `${userProfile?.firstName} ${userProfile?.lastName}`;
  const { id: journeyId } = journeyData;
  const [searchParams] = useSearchParams();

  useEffect(() => {
    onPayoutSuccessful(isSubmitComplete && !submitError);
  }, [isSubmitComplete, submitError]);

  const onSubmit = (data: BankFieldsList) => {
    setSubmitError(false);

    const token = cookie.load(TURNSTILE_COOKIE);
    const transactionReference = searchParams.get('tr');

    const params = {
      countryId: data.country?.value || '',
      transactionReference,
      journeyId,
      token,
      bankFields: {
        ...data,
        countryOfResidence: data?.residence?.value,
      },
    };

    updatePayout.mutate(params, {
      onError: () => {
        setSubmitError(true);
      },
      onSuccess: () => {
        setIsSubmitComplete(true);
        trackPageInteractions(
          'saved update bank details - airLuggage',
          'ahp airLuggage page',
        );
      },
    });
  };

  const statusBox = useGetAirLuggageStatusBadge(status);

  return (
    <>
      <Box
        backgroundColor="greyscale.300"
        borderRadius="xl"
        padding={{ base: 4, lg: 8 }}
      >
        <Text
          fontSize="lg"
          fontWeight="medium"
          mb={4}
          data-testid="air-luggage-payout-luggage-details-title"
        >
          {t('instant_cash.payout.bank_details')}
        </Text>
        <Card
          padding={0}
          border="1px solid"
          borderColor="primary.500"
          overflow="visible"
        >
          <UserCards
            airBundleJourneys={journeyListData}
            statusBox={statusBox}
          />
          <PayoutForm
            form={form}
            onSubmit={onSubmit}
            insuranceType={InsuranceType.AIR_LUGGAGE}
            beneficiaryFullName={beneficiaryFullName}
            journeyId={journeyId}
          />
        </Card>
      </Box>
      {submitError ? (
        <ErrorBadge data-testid="backend-error">
          <ContactLink tKey="instant_cash.payout.request_payment_error" />
        </ErrorBadge>
      ) : null}
      <AirBundlePayoutActions
        form={form}
        onSubmit={onSubmit}
        submitError={submitError}
        insuranceType={InsuranceType.AIR_LUGGAGE}
        isUpdatePayment
      />
    </>
  );
};

export default PayoutUpdateView;
