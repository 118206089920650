import { UpsellPage } from 'pages';
import * as UpsellPages from 'pages/Upsell';
import * as UpsellTripsPages from 'pages/Upsell/Trips';

export const UpsellRoutes = {
  path: 'upsell',
  element: <UpsellPage />,
  children: [
    {
      path: 'trips/:token',
      element: <UpsellPages.TripsPage />,
      children: [
        {
          index: true,
          element: <UpsellTripsPages.IndexPage />,
        },
        {
          path: 'checkout',
          element: <UpsellTripsPages.CheckoutPage />,
        },
        {
          path: 'payment-succeeded',
          element: <UpsellTripsPages.PaymentSucceededPage />,
        },
      ],
    },
  ],
};
