import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  triggerAirLuggageJourneys,
  type TriggerAirLuggagesForJourneyParams,
} from '@airhelp/plus';
import { ahplusClient } from 'api/clients/clients';

const useTriggerAirLuggageJourneys = () => {
  const queryClient = useQueryClient();

  const useTriggerAirLuggageJourneysMutation = useMutation({
    mutationFn: (params: TriggerAirLuggagesForJourneyParams) =>
      triggerAirLuggageJourneys({
        client: ahplusClient,
        params,
      }),
    onSuccess: async (data, params) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['journey', { id: params.journeyId }],
        }),
        queryClient.invalidateQueries({
          queryKey: [
            'journey',
            'air-luggages',
            { journeyId: params.journeyId },
          ],
        }),
        queryClient.setQueryData(['journey', { id: params.journeyId }], {
          ...data,
        }),
      ]);
    },
  });

  return useTriggerAirLuggageJourneysMutation;
};

export default useTriggerAirLuggageJourneys;
