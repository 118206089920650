import React, {
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
} from 'react';
import { create } from 'zustand';
import config from 'config';
import { appendExternalScript } from 'utils/appendExternalScript';
import { type Optimizely, type OptimizelyEvent } from './types';

declare global {
  interface Window {
    optimizely: Optimizely;
  }
}

interface IOptimizelyStore {
  isInjected: boolean;
  setIsInjected: (isInjected: boolean) => void;
  variants: Record<string, string>;
  addVariant: (experimentId: string, variantId: string) => void;
}

type TOptimizelyContext = IOptimizelyStore & {
  loadScript: () => Promise<void>;
  trackEvent: (eventName: string, tags: object) => void;
  setAttributes: (attributes: object) => void;
};

const OptimizelyContext = createContext<TOptimizelyContext>({
  isInjected: false,
  setIsInjected: () => null,
  variants: {},
  addVariant: () => null,
  loadScript: () => Promise.resolve(),
  trackEvent: () => null,
  setAttributes: () => null,
});

const useStore = create<IOptimizelyStore>((set) => ({
  isInjected: false,
  setIsInjected: (isInjected: boolean) => {
    set((state) => ({ ...state, isInjected }));
  },
  variants: {},
  addVariant: (experimentId: string, variantId: string) => {
    set((state) => ({
      ...state,
      variants: { ...state.variants, [experimentId]: variantId },
    }));
  },
}));

function getOptimizely(): Optimizely {
  window.optimizely = window.optimizely || [];

  return window.optimizely;
}

function log(...others: unknown[]) {
  // eslint-disable-next-line no-console -- We need to log Optimizely events for debugging purposes
  console.log('[Optimizely 📈] ', ...others);
}

function trackEvent(eventName: string, tags: object = {}) {
  log('Tracking event', eventName, tags);

  getOptimizely().push({
    type: 'event',
    eventName,
    tags,
  });
}

function setAttributes(attributes: object) {
  log('Setting user attributes', attributes);

  getOptimizely().push({
    type: 'user',
    attributes,
  });
}

function initializeCallbacks(
  optimizely: Optimizely,
  addVariant: (experimentId: string, variantId: string) => void,
) {
  optimizely.push({
    type: 'addListener',
    filter: {
      type: 'lifecycle',
      name: 'campaignDecided',
    },
    handler: (event: OptimizelyEvent) => {
      log('Campaign decided', event);

      addVariant(
        event.data.decision.experimentId,
        event.data.decision.variationId,
      );
    },
  });
}

const OptimizelyContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { isInjected, setIsInjected, variants, addVariant } = useStore();

  const loadScript = useCallback(async () => {
    if (!config.optimizely.enabled) {
      log('Optimizely disabled in configuration');
      return;
    }

    if (isInjected) {
      log('Script already injected');
      return;
    }

    try {
      log('Injecting script');

      await appendExternalScript(config.optimizely.scriptUrl);

      const optimizely = getOptimizely();

      initializeCallbacks(optimizely, addVariant);

      log('Script successfuly injected');

      setIsInjected(true);
    } catch {
      log('Script injection error');
    }
  }, [isInjected, setIsInjected, addVariant]);

  return (
    <OptimizelyContext.Provider
      value={{
        isInjected,
        setIsInjected,
        variants,
        addVariant,
        loadScript,
        trackEvent,
        setAttributes,
      }}
    >
      {children}
    </OptimizelyContext.Provider>
  );
};

const useOptimizely = () => {
  return useContext(OptimizelyContext);
};

export default OptimizelyContextProvider;
export { OptimizelyContext, useOptimizely };
