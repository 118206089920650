import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { type BankFieldsList, InsuranceType } from '@airhelp/plus';
import { Box, Text } from '@chakra-ui/react';
import cookie from 'react-cookies';
import { TURNSTILE_COOKIE } from 'config/cookies';
import AirBundlePayoutActions from 'components/Journey/AirBundle/AirBundlePayoutActions';
import UserCard from 'components/Journey/AirBundle/UserCard';
import { Card, ErrorBadge } from 'elements';
import { useTrackEvent } from 'utils/tracking/hooks';
import { type Journey } from 'models/journey/Journey';
import { type AirPayoutJourney } from 'models/journey/AirPayoutJourney';
import useUpdateAirPayoutJourneys from 'hooks/api/airBundle/useUpdateAirPayoutJourneys';
import ContactLink from 'elements/ContactLink/ContactLink';
import PayoutForm from '../../AirBundle/Payment/PayoutForm';
import useGetAirPayoutStatusBadge from '../getAirPayoutStatusBadge';

interface IComponent {
  journeyListData: AirPayoutJourney[];
  onPayoutSuccessful: (successful: boolean) => void;
  journeyData: Journey;
}

const insuranceType = InsuranceType.AIR_PAYOUT;

const PayoutUpdateView: React.FC<IComponent> = ({
  journeyListData,
  onPayoutSuccessful,
  journeyData,
}) => {
  const form = useForm({ mode: 'onSubmit' });
  const { t } = useTranslation();
  const { trackPageInteractions } = useTrackEvent();

  const [submitError, setSubmitError] = useState<boolean>(false);
  const [isSubmitComplete, setIsSubmitComplete] = useState(false);

  const updatePayout = useUpdateAirPayoutJourneys();
  const status = journeyListData[0]?.status;
  const beneficiaryFullName = journeyListData[0]?.name;
  const { id: journeyId } = journeyData;

  useEffect(() => {
    onPayoutSuccessful(isSubmitComplete && !submitError);
  }, [isSubmitComplete, submitError]);

  const onSubmit = (data: BankFieldsList) => {
    setSubmitError(false);

    const token = cookie.load(TURNSTILE_COOKIE);

    const params = {
      countryId: data.country?.value || '',
      journeyId,
      token,
      bankFields: {
        ...data,
        countryOfResidence: data?.residence?.value,
      },
    };

    updatePayout.mutate(params, {
      onError: () => {
        setSubmitError(true);
      },
      onSuccess: () => {
        setIsSubmitComplete(true);
        trackPageInteractions(
          'saved update bank details - airPayout',
          'ahp airPayout page',
        );
      },
    });
  };

  const statusBox = useGetAirPayoutStatusBadge(status);

  return (
    <>
      <Box
        backgroundColor="greyscale.300"
        borderRadius="xl"
        padding={{ base: 4, lg: 8 }}
      >
        <Text
          fontSize="lg"
          fontWeight="medium"
          mb={4}
          data-testid="air-bundle-payout-title"
        >
          {t('instant_cash.payout.bank_details')}
        </Text>
        <Card
          padding={0}
          border="1px solid"
          borderColor="primary.500"
          overflow="visible"
        >
          <UserCard
            isOpen
            airBundleJourney={journeyListData[0]}
            statusBox={statusBox}
            payoutAllowed
          />
          <PayoutForm
            form={form}
            onSubmit={onSubmit}
            beneficiaryFullName={beneficiaryFullName}
            journeyId={journeyId}
            insuranceType={insuranceType}
          />
        </Card>
      </Box>
      {submitError ? (
        <ErrorBadge data-testid="backend-error">
          <ContactLink tKey="instant_cash.payout.request_payment_error" />
        </ErrorBadge>
      ) : null}
      <AirBundlePayoutActions
        submitError={submitError}
        form={form}
        onSubmit={onSubmit}
        insuranceType={insuranceType}
        isUpdatePayment
      />
    </>
  );
};

export default PayoutUpdateView;
