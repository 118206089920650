import { useTranslation, Trans } from 'react-i18next';
import { Flex, Text } from '@chakra-ui/react';
import { Star } from '@airhelp/icons';
import { BenefitItem } from './BenefitItem';

interface TravelPerksProps {
  additionalSavingsEnabled: boolean;
}
export function TravelPerks({ additionalSavingsEnabled }: TravelPerksProps) {
  const { t } = useTranslation();

  return (
    <Flex direction="column" gap={4} mt={6}>
      <Text fontSize="md" color="primary.900" fontWeight="medium">
        {t('special_offer.travel_perks.title')}
      </Text>
      <BenefitItem
        variant="secondary"
        Icon={Star}
        text={
          <Trans
            i18nKey="special_offer.travel_perks.savings"
            components={{
              first: <Text as="span" fontSize="md" color="primary.900" />,
              second: (
                <Text
                  as="span"
                  fontSize="md"
                  color={
                    additionalSavingsEnabled ? 'primary.900' : 'greyscale.500'
                  }
                />
              ),
            }}
          />
        }
      />
    </Flex>
  );
}
