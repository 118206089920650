import { useClaimEnquiries } from '@airhelp/webapp/react';
import { type UseQueryResult } from '@tanstack/react-query';
import { useMemo } from 'react';
import {
  transformToClaimEnquiryClass,
  type ClaimEnquiry,
  type IClaimEnquiry,
} from 'models/Claim';

type UseComputedClaimEnquiriesProps = Parameters<typeof useClaimEnquiries>[0];

type ClaimEnquiriesData = NonNullable<
  UseQueryResult<ReturnType<typeof useClaimEnquiries>['data']>['data']
>;

const transformClaimEnquiries = (data: ClaimEnquiriesData): ClaimEnquiry[] => {
  const claimEnquiries: ClaimEnquiry[] = data.claimEnquiries
    .map((claimEnquiry) => {
      const normalizedClaimEnquiry: IClaimEnquiry = {
        id: claimEnquiry.id,
        claimType: claimEnquiry.claimType,
        createdAt: claimEnquiry.createdAt,
        claimId: claimEnquiry.claimId,
        uuid: claimEnquiry.uuid,
        flights: claimEnquiry.itinerary.flights.map((flight) => {
          return {
            id: flight.id,
            departureAirportCode: flight.departureAirport.iata,
            departureCity: flight.departureAirport.city,
            arrivalAirportCode: flight.arrivalAirport.iata,
            arrivalCity: flight.arrivalAirport.city,
            localDepartureDate: flight.localDepartureDate,
            disrupted: flight.disrupted,
            airline: flight.marketingAirlineName,
          };
        }),
      };

      return transformToClaimEnquiryClass(normalizedClaimEnquiry);
    })
    .sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });

  return claimEnquiries;
};

export default function useComputedClaimEnquiries(
  props: UseComputedClaimEnquiriesProps,
) {
  const { isLoading, data, isError } = useClaimEnquiries({
    ...props,
    notifyOnChangeProps: ['data', 'isError', 'isLoading'],
  });

  return {
    isError,
    isLoading,
    data: useMemo(() => {
      if (data?.claimEnquiries) {
        return transformClaimEnquiries(data);
      }
      return [];
    }, [data]),
  };
}
