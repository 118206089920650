import { Expose, plainToClass } from 'class-transformer';
import { type FetchAirBundleSubscriptionResponse as IFetchAirBundleSubscriptionResponse } from '@airhelp/plus';

export class AirBundleSubscription
  implements IFetchAirBundleSubscriptionResponse
{
  @Expose() airPayoutCreditsLeft: number;
  @Expose() airLuggageCreditsLeft: number;
  @Expose() airPayoutUnlimitedAccess: boolean;
  @Expose() airLuggageUnlimitedAccess: boolean;
  @Expose() airPayoutIpidVersion: string;
  @Expose() airLuggageIpidVersion: string;
  @Expose() validUntil: string;

  @Expose()
  get hasCredit(): boolean {
    return Boolean(
      this.airPayoutCreditsLeft > 0 || this.airLuggageCreditsLeft > 0,
    );
  }

  @Expose()
  get airLuggageAccess(): boolean {
    return Boolean(
      this.airLuggageUnlimitedAccess || this.airLuggageCreditsLeft > 0,
    );
  }
}

export function transformToFetchAirBundleSubscriptionClass(
  airBundleSubscription: IFetchAirBundleSubscriptionResponse,
): AirBundleSubscription {
  return plainToClass(AirBundleSubscription, airBundleSubscription);
}
