import { type InsuranceType } from '@airhelp/plus';
import cookie from 'react-cookies';

const MAX_AGE = 601200; // 7 days (Veriff limit) minus 1 hour (just to be safe)

const setVeriffUrl = (
  journeyId: number,
  sessionUrl: string,
  insuranceType: InsuranceType,
  airRequestId: number,
) => {
  const key = formatKey(journeyId, insuranceType, airRequestId);
  return cookie.save(key, sessionUrl, {
    max_age: MAX_AGE,
    expires: new Date(Date.now() + MAX_AGE * 1000),
  });
};

const getVeriffUrl = (
  journeyId: number,
  insuranceType: InsuranceType,
  airRequestId?: number,
) => {
  const key = formatKey(journeyId, insuranceType, airRequestId);
  return cookie.load(key) as string;
};

const removeVeriffUrl = (
  journeyId: number,
  insuranceType: InsuranceType,
  airRequestId?: number,
) => {
  const key = formatKey(journeyId, insuranceType, airRequestId);
  cookie.remove(key);
};

const formatKey = (
  journeyId: number,
  insuranceType: InsuranceType,
  airRequestId?: number,
) => {
  return `veriff-${insuranceType}-${journeyId}-${airRequestId}`;
};

export { setVeriffUrl, getVeriffUrl, removeVeriffUrl };
