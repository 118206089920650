import {
  fetchFeatureFlags,
  type FetchFeatureFlagsResponse,
} from '@airhelp/plus';
import { useQuery } from '@tanstack/react-query';
import { type ApiQueryOptions } from 'hooks/api/queryHelper';
import { ahplusClient } from 'api/clients/clients';

const useFeatureFlags = (
  options?: ApiQueryOptions<FetchFeatureFlagsResponse>,
) => {
  return useQuery({
    queryKey: ['feature-flags'],
    queryFn: () => fetchFeatureFlags({ client: ahplusClient }),
    ...options,
  });
};

export default useFeatureFlags;
