import { fetchLounges, type FetchLoungesResponse } from '@airhelp/plus';
import { useQuery } from '@tanstack/react-query';
import { type ApiQueryOptions } from 'hooks/api/queryHelper';
import { ahplusClient } from 'api/clients/clients';

const useLounges = ({
  options,
  airportCode,
}: {
  options?: ApiQueryOptions<FetchLoungesResponse>;
  airportCode: string;
}) => {
  return useQuery({
    queryKey: ['lounges', { airportCode }],
    queryFn: () =>
      fetchLounges({ client: ahplusClient, params: { airportCode } }),
    ...options,
  });
};

export default useLounges;
