import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { ZodError } from 'zod';
import { type HTTPClientError } from '@airhelp/http-client';
import config from 'config';

const EXCLUDED_STATUSES = [401, 404, 409, 422];

const SUPPORTED_DOMAIN_REGEX =
  /https:\/\/(www\.)?((app|my)(-sta)?\.)?airhelp\.com/i;

export const captureException = (
  error: unknown,
  tags: Record<string, string | number | undefined> | null = null,
) => {
  const statusCode = (error as HTTPClientError)?.response?.status;
  if (EXCLUDED_STATUSES.includes(statusCode)) {
    return;
  }

  Sentry.captureException(error, tags ? { tags } : undefined);
};

export function initSentry() {
  Sentry.init({
    dsn: config.sentryDsnToken,
    environment: config.mode,
    enabled: config.isSentryEnabled,
    release: config.codeVersion,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.1,

    beforeSend(event, hint) {
      if (hint.originalException instanceof ZodError) {
        if (!event.breadcrumbs) {
          event.breadcrumbs = [];
        }

        event.breadcrumbs.push({
          level: 'error',
          type: 'error',
          category: 'zod',
          data: {
            issues: hint.originalException.issues,
          },
          message: 'Schema validation failed',
        });
      }

      const excludedStatuses = EXCLUDED_STATUSES.some((status) =>
        hint?.data?.payload?.data?.statusCode?.includes(`code ${status}`),
      );
      if (excludedStatuses) {
        return null;
      }
      return event;
    },

    beforeBreadcrumb(breadcrumb) {
      if (
        breadcrumb.category === 'xhr' &&
        !breadcrumb.data?.url.match(SUPPORTED_DOMAIN_REGEX)
      ) {
        return null;
      }

      return breadcrumb;
    },

    // This option is required for capturing headers and cookies. Used by httpClientIntegration
    sendDefaultPii: true,

    ignoreErrors: [
      'Failed to fetch',
      'ttq is not defined',
      'fbq is not defined',
      'esk is not defined',
      "Can't find variable: fbq",
      "Can't find variable: ttq",
      "Can't find variable: esk",
      'window.lintrk is not a function',
      'Request aborted',
      'Request timed out',
      'Failed to start the audio device',
      'timeout exceeded',
      'NotAllowedError',
      'NotAllowedError: Permission denied',
      't.throwIfAborted is not a function',
      "undefined is not an object (evaluating 'a.L')",
      'NetworkError when attempting to fetch resource',
      'Load failed',
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
    ],
    allowUrls: [SUPPORTED_DOMAIN_REGEX, 'localhost'],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      // Other
      /gtm\.js/i,
      // translators
      /translate_http[^/]+/,
      /^https:\/\/www-airhelp-com\.translate\.goog\/\S+/,
    ],
  });
}
