import { type ValidateResult } from 'react-hook-form';
import countryCodesMap from 'country-calling-code';
import { type TFunction } from 'i18next';
import { validateBIC, extractIBAN } from 'ibantools';
import { type EditableFlight, type EditableItinerary } from '@airhelp/plus';

const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const flightNumberPattern = /^(?!0+$)\d{1,4}$/;
const leadingZerosPattern = /^0+/;
const phoneNumberPattern = /^\+?[\d -]+$/;
const phoneNumberLength = { min: 5, max: 23 };
const minPasswordLength = 8;
const addressFieldMaxLength = 32;

const countryCodes = countryCodesMap.flatMap(
  (countryData) => countryData.countryCodes,
);
const removeWhitespaces = (value: string) => value.replace(/ /g, '');
const nameMaxLength = 64;
const nameMinLength = 2;
const fullNamePattern = /(\w+\s).+/i;

const validateCountry = (countryCode: string | undefined, country: string) => {
  return countryCode === country;
};

const validateIban = (value: string, t: TFunction, country: string) => {
  const extractIBANFunction = extractIBAN(value);
  const countryCodeFromIban = extractIBANFunction.countryCode;

  const result =
    extractIBANFunction.valid && validateCountry(countryCodeFromIban, country);

  return result ? result : t('errors.iban_invalid');
};

// SWIFT is the same thing as BIC
const validateSwift = (value: string, t: TFunction) => {
  const result = validateBIC(value).valid;

  return result ? result : t('errors.swift_invalid');
};

const validatePhoneNumberCountryCode = (
  phoneNumber: string,
  t: TFunction,
): ValidateResult => {
  // in case phone is empty we want to allow submission
  // as we assign it to predefined number for complete products in backend
  if (phoneNumber.length === 0) {
    return true;
  }

  const formattedPhoneNumber = phoneNumber.replace(/\s/g, '');
  const errorMessage = t('errors.phone_number_country_code_invalid');

  if (!formattedPhoneNumber.startsWith('+')) {
    return errorMessage;
  }

  for (const countryCode of countryCodes) {
    const formattedCountryCode = countryCode.replace(/\s/g, '');

    if (formattedPhoneNumber.startsWith(`+${formattedCountryCode}`)) {
      return true;
    }
  }

  return errorMessage;
};

const validateName = (value: string, t: TFunction) => {
  // latin extended from https://en.wikipedia.org/wiki/Latin_script_in_Unicode
  const latinCharsRegEx = /^[\u0020-\u007E\u00A0-\u017F]+$/;

  // Regex for disallowed characters
  const disallowedCharsRegEx = /[0-9!@#$€¥%^&*(){}_/+=[\]:;|\\<>?~§£]/;

  return latinCharsRegEx.test(value) && !disallowedCharsRegEx.test(value)
    ? true
    : t('errors.latin_invalid');
};

const removeExtraSpace = (value: string) => value.trim().split(/ +/).join(' ');

const validateUniqueFlightDesignator = (
  value: { iata?: string; flightNumber?: string },
  itinerary: EditableItinerary,
  flight: EditableFlight,
  t: TFunction,
): boolean | string => {
  const { id } = flight;

  const getFlightDesignator = (flightValue: EditableFlight) => {
    if (
      flightValue.id === id ||
      !flightValue.airline?.iata ||
      !flightValue.flightNumber
    ) {
      return null;
    }
    return `${flightValue.airline.iata}${flightValue.flightNumber}`;
  };

  const flightDesignators = itinerary.flights
    .map(getFlightDesignator)
    .filter((designator) => designator !== null);

  const getDesignatorToCheck = (val) => {
    if (val.iata) {
      return `${value.iata}${flight.flightNumber}`;
    } else if (value.flightNumber) {
      return `${flight.airline?.iata}${value.flightNumber}`;
    }

    return null;
  };

  const designatorToCheck = getDesignatorToCheck(value);
  const isDesignatorUnique =
    !designatorToCheck || !flightDesignators.includes(designatorToCheck);

  return isDesignatorUnique || t('errors.flight_number_repeated');
};

export {
  emailPattern,
  minPasswordLength,
  flightNumberPattern,
  phoneNumberPattern,
  phoneNumberLength,
  removeWhitespaces,
  validateIban,
  validateName,
  validateSwift,
  validatePhoneNumberCountryCode,
  nameMaxLength,
  nameMinLength,
  leadingZerosPattern,
  fullNamePattern,
  removeExtraSpace,
  validateUniqueFlightDesignator,
  addressFieldMaxLength,
};
