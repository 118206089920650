import { type Locale } from 'contexts/LocaleContext/locales';

const setLang = (locale: Locale) => {
  document.documentElement.lang = locale;
};

export const setLangHtmlAttribute = (locale: Locale) => {
  if (locale) {
    setLang(locale);
  }
};
