import { Us, Br, Pl, Ch, Se, Gb, Ro, Eu } from '@airhelp/flags';
import { type IconType } from '@airhelp/icons';
import { type IPrice } from 'models/Offers';
import { type TranslationKey } from 'types/i18n';

export type CurrencyCode =
  | 'EUR'
  | 'USD'
  | 'BRL'
  | 'PLN'
  | 'SEK'
  | 'CHF'
  | 'GBP'
  | 'RON';

export interface CurrencyData {
  id: CurrencyCode;
  name: TranslationKey;
  icon: IconType;
  code: CurrencyCode;
  symbol: string;
  symbolPosition: 'before' | 'after';
}

export type Currencies = Record<CurrencyCode, CurrencyData>;

export const currencies: Currencies = {
  EUR: {
    id: 'EUR',
    name: 'common.currency.eur',
    icon: Eu,
    code: 'EUR',
    symbol: '€',
    symbolPosition: 'before',
  },
  USD: {
    id: 'USD',
    name: 'common.currency.usd',
    icon: Us,
    code: 'USD',
    symbol: '$',
    symbolPosition: 'before',
  },
  BRL: {
    id: 'BRL',
    name: 'common.currency.brl',
    icon: Br,
    code: 'BRL',
    symbol: 'R$',
    symbolPosition: 'before',
  },
  PLN: {
    id: 'PLN',
    name: 'common.currency.pln',
    icon: Pl,
    code: 'PLN',
    symbol: 'zł',
    symbolPosition: 'after',
  },
  SEK: {
    id: 'SEK',
    name: 'common.currency.sek',
    icon: Se,
    code: 'SEK',
    symbol: 'kr',
    symbolPosition: 'after',
  },
  CHF: {
    id: 'CHF',
    name: 'common.currency.chf',
    icon: Ch,
    code: 'CHF',
    symbol: 'CHF',
    symbolPosition: 'before',
  },
  GBP: {
    id: 'GBP',
    name: 'common.currency.gbp',
    icon: Gb,
    code: 'GBP',
    symbol: '£',
    symbolPosition: 'before',
  },
  RON: {
    id: 'RON',
    name: 'common.currency.ron',
    icon: Ro,
    code: 'RON',
    symbol: 'lei',
    symbolPosition: 'after',
  },
};

export const mapLocaleToCurrency = (locale: string): CurrencyCode => {
  const lowerCaseLocale = locale.toLowerCase();

  const localeToCurrencyCodeMap: Record<string, CurrencyCode> = {
    en: 'USD',
    'en-us': 'USD',
    'en-gb': 'GBP',
    pl: 'PLN',
    'de-ch': 'CHF',
    sv: 'SEK',
    ro: 'RON',
    'pt-br': 'BRL',
  };

  const currency: CurrencyCode =
    lowerCaseLocale in localeToCurrencyCodeMap
      ? localeToCurrencyCodeMap[lowerCaseLocale]
      : 'EUR';

  return currency;
};

const FALLBACK_CURRENCY = 'EUR';

export const getLocalPriceValue = (
  prices: Record<Uppercase<CurrencyCode>, IPrice>,
  currency: CurrencyCode,
) => prices?.[currency]?.price ?? prices?.[FALLBACK_CURRENCY]?.price;

export const formatValueToCurrency = (
  price: string | number | null | undefined,
  currency: CurrencyCode | null | undefined,
) => {
  if (!price || !currency) {
    return '';
  }
  const currencyDetails = currencies[currency.toUpperCase()] || currencies.EUR;

  // ensure that the price is always a string before parsing
  const parsedPrice = parseFloat(price.toString());

  const formattedValue = parsedPrice.toFixed(2);
  if (currencyDetails.symbolPosition === 'before') {
    return `${currencyDetails.symbol}${formattedValue}`;
  }
  return `${formattedValue} ${currencyDetails.symbol}`;
};
