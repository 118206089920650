import React from 'react';
import { Flex, VStack, Image, Text, Heading } from '@chakra-ui/react';
import { Card } from 'elements';
import ArrowButton from 'elements/ArrowButton';
import { LinkTarget } from 'types/utils';
import { isRtl } from 'utils/textDirection/isRtl';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';

interface IComponent {
  header: string;
  description?: string;
  handleClick: () => void;
  imageSrc: string;
  externalLink?: string;
  externalLinkTarget?: LinkTarget;
}

const ActionCard: React.FC<IComponent> = ({
  header,
  description,
  imageSrc,
  handleClick,
  externalLink,
  externalLinkTarget = LinkTarget.BLANK,
  ...props
}) => {
  const { locale } = useLocale();

  return (
    <Card
      width="100%"
      p={{ base: 4, md: 8 }}
      columnGap={4}
      border="1px solid transparent"
      flexDirection={{ base: 'column', md: 'row' }}
      alignItems={{ base: 'flex-start', lg: 'center' }}
      onClick={handleClick}
      _hover={{
        boxShadow: '0px 0px 0px 4px #DFEDFF',
        border: '1px solid #0D73FF',
      }}
      role="group"
      data-testid="action-card"
      {...(externalLink && {
        as: 'a',
        href: externalLink,
        target: externalLinkTarget,
        rel: 'noopener noreferrer',
      })}
      {...props}
    >
      <Image
        src={imageSrc}
        width={{ base: '64px', md: '80px' }}
        height={{ base: '64px', md: '80px' }}
        alignSelf={{ md: 'flex-start', xl: 'center' }}
        flex="none"
        className="icon"
      />
      <Flex
        justifyContent="space-between"
        flexDirection={{ base: 'column', md: 'row' }}
        alignItems={{ base: 'flex-start', md: 'center' }}
        alignSelf="center"
        rowGap={3}
        columnGap={3}
        height={{ base: '100%', md: 'auto' }}
        width="100%"
        className="content-wrapper"
      >
        <VStack
          textAlign={{ base: 'left', lg: 'unset' }}
          alignItems="flex-start"
          spacing={{ base: 0, md: 1 }}
          flexWrap="wrap"
          overflow="hidden"
          className="content"
        >
          <Heading
            variant="h5"
            className="header"
            fontSize={{
              base: 'sm',
              // go back to the original size from the h5 variant
              sm: '1.25rem',
            }}
          >
            {header}
          </Heading>
          {description ? (
            <Text fontSize="md" display={{ base: 'none', sm: 'block' }}>
              {description}
            </Text>
          ) : null}
        </VStack>
        <ArrowButton
          onClick={handleClick}
          alignSelf={{ base: 'flex-start', md: 'center' }}
          display={{ base: 'none', sm: 'flex' }}
          type={isRtl(locale) ? 'back' : 'forward'}
        />
      </Flex>
    </Card>
  );
};

export default ActionCard;
