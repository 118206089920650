import React from 'react';
import { HStack, Text } from '@chakra-ui/react';

interface IComponent {
  claimId: string;
  createdAt: string;
}

const Footer: React.FC<IComponent> = ({ claimId, createdAt }) => {
  return (
    <HStack width="100%">
      <Text fontSize="sm" color="greyscale.600" flex="1 1">
        {claimId}
      </Text>
      <HStack flex="1 1">
        <Text fontSize="sm" color="greyscale.600">
          {createdAt}
        </Text>
      </HStack>
    </HStack>
  );
};

export default Footer;
