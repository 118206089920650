import { useMemo } from 'react';
import { Box, Text, Icon } from '@chakra-ui/react';

interface PieChartData {
  label?: string;
  value: number;
  color: string;
}

interface PieChartProps {
  data: PieChartData[];
  total?: string;
}

const CHART_BASE = {
  circumference: 2 * Math.PI * 85,
  adjustedCircumference: 2 * Math.PI * 85 - 2,
  cx: 100,
  cy: 100,
  radius: 85,
  strokeWidth: 24,
} as const;

function getPercentage(value: number, total: number) {
  return value / total;
}

function calculateStrokeDashoffset(value: number, total: number) {
  const strokeDiff = getPercentage(value, total) * CHART_BASE.circumference;
  return CHART_BASE.circumference - strokeDiff;
}

function usePieChartValues(data: PieChartData[]) {
  return useMemo(() => {
    const total = data.reduce((acc, { value }) => acc + value, 0);

    let rotateAngle = -90;

    if (total === 0) {
      return [
        {
          degrees: rotateAngle,
          color: '#DEE5ED',
          label: 'No data',
          offset: 0,
          percentage: 100,
        },
      ];
    }

    const computedValues = data
      .filter((val) => val.value > 0)
      .map((val) => {
        const dataValue = {
          degrees: rotateAngle,
          color: val.color,
          label: val.label,
          offset: calculateStrokeDashoffset(val.value, total),
        };

        rotateAngle = getPercentage(val.value, total) * 360 + rotateAngle;

        return dataValue;
      });

    return computedValues;
  }, [data]);
}

export function PieChart({ data, total }: PieChartProps) {
  const chartData = usePieChartValues(data);

  if (!data.length) {
    return null;
  }

  // do not include spacing if there is only one item
  const strokeDasharray =
    chartData.length > 1
      ? CHART_BASE.adjustedCircumference
      : CHART_BASE.circumference;

  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <figure>
        <Icon
          width={{
            base: '128px',
            md: '200px',
          }}
          height={{
            base: '128px',
            md: '200px',
          }}
          viewBox="0 0 200 200"
        >
          <title>Chart representing {total} compensation </title>
          {chartData.map(({ degrees, color, offset, label }) => {
            return (
              <circle
                key={color}
                cx={CHART_BASE.cx}
                cy={CHART_BASE.cy}
                r={CHART_BASE.radius}
                fill="none"
                stroke={color}
                strokeWidth={CHART_BASE.strokeWidth}
                strokeDasharray={strokeDasharray}
                strokeDashoffset={offset}
                transform={`rotate(${degrees}, ${CHART_BASE.cx}, ${CHART_BASE.cy})`}
              >
                <title>{label}</title>
              </circle>
            );
          })}
        </Icon>
      </figure>
      <Text
        position="absolute"
        fontWeight={700}
        color="primary.900"
        fontSize={{
          base: '24px',
          lg: '36px',
        }}
      >
        {total}
      </Text>
    </Box>
  );
}
