import { useTranslation } from 'react-i18next';
import { Check } from '@airhelp/icons';
import { AirLuggageStatus } from '@airhelp/plus';

const useGetAirLuggageStatusBadge = (status?: AirLuggageStatus) => {
  const { t } = useTranslation();
  switch (status) {
    case AirLuggageStatus.ACTIVE:
      return {
        label: t('common.active'),
      };
    case AirLuggageStatus.PAYOUT_SUCCESSFUL:
      return {
        label: t('common.paid'),
        icon: Check,
        style: {
          color: 'greyscale.100',
          backgroundColor: 'success.500',
        },
      };
    case AirLuggageStatus.ELIGIBLE:
      return {
        label: t('air_luggage.card.badge.eligible'),
        style: {
          color: 'success.500',
        },
      };
    case AirLuggageStatus.PAYOUT_FAILED:
    case AirLuggageStatus.PAYOUT_RETURNED:
      return {
        label: t('air_luggage.card.badge.resubmit_bank_details'),
        style: {
          color: 'warning.500',
        },
      };
    case AirLuggageStatus.INELIGIBLE:
    case AirLuggageStatus.BLOCKED:
    case AirLuggageStatus.UNREGISTERED:
    case AirLuggageStatus.BLOCKED_UNREGISTERED:
    case AirLuggageStatus.MEMBERSHIP_NOT_RENEWED:
      return {
        label: t('air_payout.card.badge.not_eligible'),
      };
    case AirLuggageStatus.REJECTED:
      return {
        label: t('air_luggage.card.badge.claim_rejected'),
        style: {
          color: 'warning.500',
          backgroundColor: 'warning.100',
        },
      };
    case AirLuggageStatus.PROCESSING_PAYMENT:
    case AirLuggageStatus.MANUAL_VERIFICATION_PENDING:
      return {
        label: t('air_luggage.card.badge.checking_eligibility'),
      };
    case AirLuggageStatus.EXPIRED:
      return {
        label: t('air_payout.card.badge.claim_expired'),
      };
    default:
      return {
        label: t('common.active'),
      };
  }
};

export default useGetAirLuggageStatusBadge;
