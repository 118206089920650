import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Navigate } from 'react-router-dom';
import { track } from 'react-tracking';
import bukaSunglasses from 'assets/images/buka-sunglasses@2x.png';
import { PAGE_PATHS } from 'config/routes';
import { usePageNavigationHeader } from 'contexts/LayoutContext/LayoutContext';
import { Loader } from 'elements';
import MembershipUpsell from 'elements/MembershipUpsell';
import useFeatureFlags from 'hooks/api/useFeatureFlags';
import useFetchMyProfile from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import useProtectedPage from 'hooks/routing/useProtectedPage';
import { usePageTracking } from 'utils/tracking';
import useJourneys from 'hooks/api/journey/useJourneys';
import { JourneysView } from '../components/Journeys/JourneysView';

const JourneysPage = () => {
  useProtectedPage();
  usePageTracking();
  const { t } = useTranslation();
  const { data: featureFlags } = useFeatureFlags();

  usePageNavigationHeader({
    title: t('navigation.trips'),
    backPath: generatePath(PAGE_PATHS.HOME_PAGE),
  });

  const {
    isLoading: isJourneysLoading,
    data: journeysData,
    isError,
  } = useJourneys();
  const { isLoading: isUserPolicyLoading, data: userProfile } =
    useFetchMyProfile();

  const isLoading = isUserPolicyLoading || isJourneysLoading;
  const journeys = journeysData || [];

  if (isLoading) {
    return <Loader dataTestId="loader" />;
  }

  if (isError) {
    return <Navigate to={{ pathname: generatePath(PAGE_PATHS.ERROR_PAGE) }} />;
  }

  if (!userProfile) {
    return <Navigate to={{ pathname: generatePath(PAGE_PATHS.HOME_PAGE) }} />;
  }

  if (
    journeys.length === 0 &&
    userProfile.policy.dashboard.upsellEnabled &&
    !(
      featureFlags?.non_ahp_trip ||
      (!featureFlags?.non_ahp_trip && userProfile.policy.coverage.active)
    )
  ) {
    return (
      <MembershipUpsell
        imageUrl={bukaSunglasses}
        header={t('journeys.protect_future_trip_with_ah')}
        description={t('journeys.protect_future_trip_with_ah_desc')}
        eventPlacement="trips page"
      />
    );
  }

  return <JourneysView userProfile={userProfile} journeys={journeys} />;
};

export default track({ page_type: 'Trips' })(JourneysPage);
