import config from 'config';

// Converts the domain of the target URL to the domain of the current URL
// e.g. 'https://example.com' -> 'https://example.pl'
function convertDomainUrl(currentOrigin: string, targetUrl: string): string {
  // If the current URL is localhost or doesn't contain a domain suffix, return the target URL
  if (
    currentOrigin.includes('localhost') ||
    !currentOrigin.includes('.') ||
    targetUrl.includes('localhost') ||
    !targetUrl.includes('.')
  ) {
    return targetUrl;
  }

  // Get the domain suffix of the current URL (example: 'com' from 'www.example.com')
  const currentDomainSuffix = getDomainSuffix(currentOrigin);

  // If the domain suffix of the current URL is supported, replace it with the domain suffix of the target URL
  if (currentDomainSuffix && isDomainSupported(currentDomainSuffix)) {
    const urlParts = targetUrl.split('.');
    urlParts[urlParts.length - 1] = currentDomainSuffix;
    return urlParts.join('.');
  }

  return targetUrl;
}

function getDomainSuffix(currentOrigin: string): string | null {
  try {
    return new URL(currentOrigin).hostname.split('.').slice(-1)[0];
  } catch (error) {
    return null;
  }
}

function isDomainSupported(domain: string): boolean {
  return config.supportedRegionalDomains.includes(domain);
}

export { convertDomainUrl, getDomainSuffix, isDomainSupported };
