import React from 'react';
import { AltArrowBack, AltArrowForward } from '@airhelp/icons';
import { type BoxProps, Circle } from '@chakra-ui/react';
import { LinkTarget } from 'types/utils';

type IComponent = {
  onClick: () => void;
  externalLink?: string;
  externalLinkTarget?: LinkTarget;
  type?: 'forward' | 'back';
  disabled?: boolean;
} & BoxProps;

const ArrowButton: React.FC<IComponent> = ({
  onClick,
  externalLink,
  externalLinkTarget = LinkTarget.BLANK,
  type = 'forward',
  disabled = false,
  ...props
}) => {
  return (
    <Circle
      as="button"
      className="button"
      bgColor="primary.100"
      color="primary.500"
      p={2}
      alignSelf="center"
      onClick={onClick}
      disabled={disabled}
      _hover={{ bgColor: 'primary.500', color: 'primary.100' }}
      _groupHover={{ bgColor: 'primary.500', color: 'primary.100' }}
      {...(externalLink && {
        as: 'a',
        href: externalLink,
        target: externalLinkTarget,
        rel: 'noopener noreferrer',
      })}
      {...props}
    >
      {type === 'forward' ? <AltArrowForward /> : <AltArrowBack />}
    </Circle>
  );
};

export default ArrowButton;
