import React from 'react';
import { useTranslation } from 'react-i18next';
import { type BillingManagementAddress } from '@airhelp/plus';
import DrawerComponent from '../common/DrawerComponent/DrawerComponent';
import UpdateBillingInformationForm from './UpdateBillingInformationForm';

interface IComponent {
  onClose: () => void;
  isOpen: boolean;
  billingAddress: BillingManagementAddress;
}

const UpdateBillingInformation: React.FC<IComponent> = ({
  onClose,
  isOpen,
  billingAddress,
}) => {
  const { t } = useTranslation();

  return (
    <DrawerComponent
      header={t('payment_management.update_billing_address')}
      content={
        <UpdateBillingInformationForm
          onClose={onClose}
          billingAddress={billingAddress}
        />
      }
      onClose={onClose}
      isOpen={isOpen}
    />
  );
};

export default UpdateBillingInformation;
