import React, { useContext } from 'react';
import { HStack } from '@chakra-ui/react';
import {
  type ActiveTabsState,
  TabsContext,
  type TabState,
} from 'contexts/TabsContext/TabsContext';
import Tab from './Tab';

export interface ITab {
  name: TabState;
  label: string;
}

interface IComponent {
  onTabChange: (value: string) => void;
  tabId: string;
  tabs: ITab[];
}
const Tabs: React.FC<IComponent> = ({ onTabChange, tabId, tabs }) => {
  const { tabsState, setTabsState } = useContext(TabsContext);

  const changeTab = (tab: TabState) => {
    onTabChange(tab);
    const newTabsState: ActiveTabsState = { ...tabsState, [tabId]: tab };
    setTabsState(newTabsState);
  };

  return (
    <HStack spacing={2}>
      {tabs.map((tab) => {
        const { name } = tab;
        return (
          <Tab
            key={`${name}-tab`}
            tab={tab}
            active={tabsState[tabId] === name}
            handleClick={() => {
              changeTab(name);
            }}
            tabId={tabId}
          />
        );
      })}
    </HStack>
  );
};

export default Tabs;
