import config from 'config';
import { DataLayer } from './datalayer';
import { type TDispatcher } from './dispatcher';
import { PROVIDERS } from './providers';

function getDispatcher(providerName: PROVIDERS): TDispatcher {
  switch (providerName) {
    case PROVIDERS.DATA_LAYER:
      return DataLayer;
  }
}

interface IDispatchData {
  name: string;
  providers?: PROVIDERS[];
  [key: string]: unknown;
}

export default function dispatch(data: IDispatchData) {
  const { name, providers, ...rest } = data;

  // https://developer.chrome.com/docs/devtools/console/format-style/
  const stylisedName = `\x1B[91;4m${name}\x1B[m`;

  if (config.tracking.logEvents) {
    // eslint-disable-next-line no-console -- We want to log tracking events for debugging purposes
    console.log(`🖥️ Tracking analytics event ${stylisedName}`, {
      name,
      providers,
      params: rest,
    });
  }

  getDispatcher(PROVIDERS.DATA_LAYER).track(name, { ...rest });
}
