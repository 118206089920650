import React from 'react';
import cookie from 'react-cookies';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  AuthPasswordResetEmailSection,
  AuthSummarySection,
} from '@airhelp/funnel';
import { Link, Text, useBreakpointValue, useToast } from '@chakra-ui/react';
import { useBeginPasswordReset } from '@airhelp/auth/react';
import { Ok } from '@airhelp/icons';
import { generatePath, useNavigate } from 'react-router';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { USER_RETURN_AHPLUS_COOKIE } from 'config/cookies';
import { PAGE_PATHS } from 'config/routes';
import { createCookieOptions } from 'utils/cookies';

const ResetPasswordView: React.FC = () => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const navigate = useNavigate();

  const toast = useToast();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const saveCookieUserReturnUrl = () => {
    cookie.save(USER_RETURN_AHPLUS_COOKIE, '1', createCookieOptions());
  };

  const {
    mutate: authBeginResetPassword,
    isPending,
    isError,
    isSuccess,
  } = useBeginPasswordReset({
    onSuccess: () => {
      saveCookieUserReturnUrl();

      toast({
        title: t('my_account.details.password_reset_link_sent'),
        status: 'success',
        duration: 4000,
        position: isMobile ? 'bottom' : 'top',
        isClosable: true,
        icon: <Ok boxSize={5} alignSelf="center" color="greyscale.100" />,
      });
    },
  });

  if (isSuccess) {
    return (
      <AuthSummarySection
        i18n={{
          heading: t('authentication.reset_password_summary_header'),
          description: t('authentication.reset_password_summary_description'),
          actionText: t('common.back_to_login'),
        }}
        onActionClick={() => {
          navigate(generatePath(PAGE_PATHS.LOGIN_PAGE));
        }}
      />
    );
  }

  return (
    <AuthPasswordResetEmailSection
      errorText={isError ? t('errors.something_went_wrong') : undefined}
      i18n={{
        introHeader: t('authentication.reset_password'),
        subtitle: t('authentication.reset_password_description'),
        emailPlaceholder: t('authentication.email_address'),
        resetPasswordAction: t('authentication.reset_it_now'),
        callout: (
          <Text as="span" fontSize="sm" color="greyscale.600">
            {t('authentication.already_password')}
            <Link
              as={NavLink}
              to={generatePath(PAGE_PATHS.LOGIN_PAGE)}
              ps={1}
              fontSize="sm"
              fontWeight="normal"
              color="primary.500"
            >
              <Text as="span" fontSize="sm" color="primary.500">
                {t('authentication.login_form.log_in_here')}
              </Text>
            </Link>
          </Text>
        ),
      }}
      onPasswordReset={(email) => {
        authBeginResetPassword({ email, languageCode: locale });
      }}
      isPending={isPending}
    />
  );
};

export default ResetPasswordView;
