import useUpgradeModal from 'hooks/useUpgradeModal';
import { useTrackEvent } from 'utils/tracking/hooks';
import UpgradeModal from '../UpgradeModal';

interface EssentialUpgradeProps {
  canLoadBanner: boolean;
}

const EssentialUpgrade: React.FC<EssentialUpgradeProps> = ({
  canLoadBanner,
}) => {
  const { trackCtaClick } = useTrackEvent();

  const { open, onClose } = useUpgradeModal({
    canLoadBanner,
  });

  if (!open) {
    return null;
  }

  const handleReadMore = () => {
    onClose();
  };

  const handleUpgradeNow = () => {
    trackCtaClick('essential to smart', 'essential to smart pop up');
    onClose();
  };

  return (
    <UpgradeModal
      onReadMore={handleReadMore}
      onUpgradeNow={handleUpgradeNow}
      onClose={onClose}
    />
  );
};

export default EssentialUpgrade;
