import React from 'react';
import {
  generatePath,
  Navigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { track } from 'react-tracking';
import { PAGE_PATHS } from 'config/routes';
import { PageLoader } from 'elements';
import useFetchMyProfile from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { usePageTracking } from 'utils/tracking';

const Activate: React.FC = () => {
  const {
    data: userProfile,
    isLoading: isUserProfileLoading,
    refetch: refetchUserProfile,
  } = useFetchMyProfile();
  const { token } = useParams();
  const [searchParams] = useSearchParams();

  refetchUserProfile();
  usePageTracking();

  if (!userProfile || isUserProfileLoading) {
    return <PageLoader />;
  }

  if (
    !userProfile.policy.dashboard?.dashboardVisited &&
    userProfile.policy.dashboard?.upsellEnabled
  ) {
    return (
      <Navigate
        to={{
          pathname: generatePath(PAGE_PATHS.TRIPS.UPGRADE.ANNUAL_PAGE, {
            token: token?.toString() || '',
          }),
          search: searchParams.toString(),
        }}
      />
    );
  }

  return <Navigate to={{ pathname: generatePath(PAGE_PATHS.HOME_PAGE) }} />;
};

export default track({ page_type: 'Trips.Activate' })(Activate);
