import { useState } from 'react';
import useEffectOnce from 'hooks/useEffectOnce';

interface Size {
  width?: number;
  height?: number;
}

// Hook
const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });

  const [isDesktop, setIsDesktop] = useState<boolean>();

  useEffectOnce(() => {
    function handleResize() {
      const newSize: Size = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      setWindowSize(newSize);
      if (newSize.width !== undefined) {
        setIsDesktop(newSize.width >= 1024);
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  return { windowSize, isDesktop };
};

export default useWindowSize;
