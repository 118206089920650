import React, { useContext } from 'react';
import { generatePath, Navigate, useSearchParams } from 'react-router-dom';
import { track } from 'react-tracking';
import ProcessingPaymentView from 'components/ProcessingPaymentView';
import { PAGE_PATHS } from 'config/routes';
import { TrackingContext } from 'contexts';
import useEffectOnce from 'hooks/useEffectOnce';
import { usePageTracking } from 'utils/tracking';
import { useTrackCheckout, useTrackOnce } from 'utils/tracking/hooks';
import { CheckoutActionTypes } from 'utils/tracking/reducers/checkoutDataReducer';

const ProcessingPaymentPage = () => {
  usePageTracking();

  const [searchParams] = useSearchParams();
  const { checkoutData, checkoutDataDispatch } = useContext(TrackingContext);
  const { trackSubscriptionFunnel, trackPaymentFailed } = useTrackCheckout();

  useEffectOnce(() => {
    checkoutDataDispatch({ type: CheckoutActionTypes.LOAD });
  });

  useTrackOnce(
    (setTracked) => {
      if (checkoutData.productDetails) {
        trackSubscriptionFunnel('Processing');
        setTracked();
      }
    },
    [trackSubscriptionFunnel, checkoutData],
  );

  const onPaymentFailed = () => {
    trackPaymentFailed();
    trackSubscriptionFunnel('Failed');
  };

  const paymentIntentId = searchParams.get('payment_intent') as string;
  const succeededPath = generatePath(
    PAGE_PATHS.MEMBERSHIPS.BUY.PAYMENT_SUCCEEDED_PAGE,
  );
  const tryAgainPath = generatePath(PAGE_PATHS.MEMBERSHIPS.BUY.CHECKOUT_PAGE);

  return paymentIntentId ? (
    <ProcessingPaymentView
      paymentIntentId={paymentIntentId}
      succeededPath={succeededPath}
      tryAgainPath={tryAgainPath}
      onPaymentFailed={onPaymentFailed}
    />
  ) : (
    <Navigate to={{ pathname: generatePath(PAGE_PATHS.HOME_PAGE) }} />
  );
};

export default track({ page_type: 'Membership.Buy.ProcessingPayment' })(
  ProcessingPaymentPage,
);
