import React, { useState } from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import { type IconType } from '@airhelp/icons';
import { Link, GridItem, Text, Icon } from '@chakra-ui/react';

export interface NavBarItem {
  icon: IconType;
  hoverIcon: IconType;
  label: string;
  route?: string;
  action?: () => void;
  dataTestId: string;
}

const NavItem: React.FC<{ item: NavBarItem }> = ({ item }) => {
  const routeActive = useMatch(item.route ?? '');
  const [iconActive, setIconActive] = useState(Boolean(routeActive));

  return (
    <GridItem
      display={{ base: 'inline-flex', lg: 'block' }}
      h={{ base: '100%', lg: 'initial' }}
      maxH="initial"
      onMouseEnter={() => {
        setIconActive(true);
      }}
      onMouseLeave={() => {
        if (!routeActive) {
          setIconActive(false);
        }
      }}
      onClick={item.action}
    >
      <Link
        as={NavLink}
        size="l"
        data-testid={item.dataTestId}
        to={item.route || '#'}
        display="flex"
        alignItems="center"
        flexDirection={{ base: 'column', lg: 'unset' }}
        justifyContent={{ base: 'center', lg: 'flex-start' }}
        w="100%"
        px={{ base: 1, md: 2, lg: 8 }}
        height={{ base: 'initial', lg: 6 }}
        outline="none"
        cursor="pointer"
        _focus={{ outline: 'none' }}
        _hover={{
          backgroundColor: {
            base: 'greyscale.300',
            lg: 'greyscale.100',
          },
          borderRadius: { base: 8, lg: 0 },
          '&.active': {
            backgroundColor: 'greyscale.100',
            borderRadius: 0,
            ' > ': {
              svg: {
                color: 'primary.500',
              },
              label: {
                color: 'primary.500',
                textDecoration: 'none',
              },
            },
          },
          svg: {
            color: { base: 'greyscale.600', lg: 'primary.500' },
          },
          label: {
            color: { base: 'greyscale.600', lg: 'primary.500' },
            textDecoration: 'none',
          },
        }}
        _activeLink={{
          backgroundColor: 'greyscale.100',
          _hover: {
            backgroundColor: 'greyscale.100',
          },
          svg: {
            color: 'primary.500',
          },
          label: {
            color: 'primary.500',
            textDecoration: 'none',
            fontWeight: { base: 'normal', lg: 'medium' },
          },
          _after: {
            content: '""',
            position: 'absolute',
            right: 0,
            width: { base: 0, lg: '2px' },
            height: { base: 0, lg: '24px' },
            borderRadius: '2px 0 0 2px',
            backgroundColor: 'primary.500',
          },
        }}
      >
        <Icon
          as={iconActive ? item.hoverIcon : item.icon}
          color="greyscale.600"
          mb={0.5}
          me={{ base: 0, lg: 3 }}
        />

        <Text
          as="label"
          fontSize={{ base: 'xs', lg: 'md' }}
          fontWeight="normal"
          color="greyscale.600"
          overflow="hidden"
          textOverflow="ellipsis"
          width={{ base: 'calc(100% - 1px)', lg: 'auto' }}
          whiteSpace={{ base: 'nowrap', lg: 'normal' }}
          lineHeight={{ base: 'none', lg: 'normal' }}
          cursor="pointer"
          mt={{ base: 1, lg: 0 }}
        >
          {item.label}
        </Text>
      </Link>
    </GridItem>
  );
};

export default NavItem;
