import React from 'react';
import { useTranslation } from 'react-i18next';
import { VStack, useBreakpointValue } from '@chakra-ui/react';
import flightsCheckedFormal from 'assets/images/flights-checked-formal@2x.png';
import LargeBlankTile from 'elements/LargeBlankTile';
import { type ClaimEnquiry } from 'models/Claim';
import { useTrackEvent } from 'utils/tracking/hooks';
import { getWebappNewClaimUrl } from 'utils/sites';
import ClaimCard from './ClaimCard';
import ClaimListItem from './ClaimListItem';
import Header from './Header';

interface IComponent {
  claimEnquiries: ClaimEnquiry[];
}

const ClaimsList: React.FC<IComponent> = ({ claimEnquiries = [] }) => {
  const { t } = useTranslation();
  const { trackCtaClick } = useTrackEvent();

  const isMobile = useBreakpointValue({ base: true, md: false });

  const hasIncompleteClaims = claimEnquiries.some(
    (claimEnquiry) => !claimEnquiry.claimId,
  );
  const hasSubmittedClaims = claimEnquiries.some(
    (claimEnquiry) => claimEnquiry.claimId,
  );

  const onAddClaimClick = () => {
    trackCtaClick('add claim button', 'claims page');
  };

  const onDeleteClaimClick = () => {
    trackCtaClick('delete claim', 'claims page');
  };

  const onCompleteClaimClick = () => {
    trackCtaClick('complete claim', 'claims page');
  };

  return (
    <VStack width="100%" spacing={12}>
      {claimEnquiries.length > 0 ? (
        <>
          {hasIncompleteClaims ? (
            <VStack width="100%" spacing={4} data-testid="unfinished-claims">
              <Header
                heading={t('claims.your_incomplete_claims')}
                tooltip={t('claims.your_incomplete_claims_tip')}
              />
              {claimEnquiries.map((claimEnquiry) => {
                const ClaimComponent =
                  !claimEnquiry.claimId &&
                  (isMobile ? ClaimCard : ClaimListItem);
                return (
                  ClaimComponent && (
                    <ClaimComponent
                      key={claimEnquiry.id}
                      claimEnquiry={claimEnquiry}
                      onDeleteClaimClick={onDeleteClaimClick}
                      onCompleteClaimClick={onCompleteClaimClick}
                    />
                  )
                );
              })}
            </VStack>
          ) : null}
          {hasSubmittedClaims ? (
            <VStack width="100%" spacing={4} data-testid="finished-claims">
              <Header
                heading={t('claims.your_submitted_claims')}
                tooltip={t('claims.your_claims_tip')}
                onAddClaimClick={onAddClaimClick}
              />
              {claimEnquiries.map((claimEnquiry) => {
                const ClaimComponent =
                  claimEnquiry.claimId &&
                  (isMobile ? ClaimCard : ClaimListItem);
                return (
                  ClaimComponent && (
                    <ClaimComponent
                      key={claimEnquiry.id}
                      claimEnquiry={claimEnquiry}
                      onDeleteClaimClick={onDeleteClaimClick}
                      onCompleteClaimClick={onCompleteClaimClick}
                    />
                  )
                );
              })}
            </VStack>
          ) : null}
        </>
      ) : (
        <VStack width="100%" spacing={4}>
          <Header
            heading={t('claims.your_compensation_claims')}
            onAddClaimClick={onAddClaimClick}
          />
          <LargeBlankTile
            testId="btn-large-blank-tile-claims"
            image={flightsCheckedFormal}
            header={t('claims.no_claims_yet')}
            description={t('claims.start_a_new_claim')}
            buttonLabel={t('claims.add_claim')}
            onClick={onAddClaimClick}
            externalLink={getWebappNewClaimUrl()}
            data-testid="large-blank-tile-claims"
          />
        </VStack>
      )}
    </VStack>
  );
};

export default ClaimsList;
