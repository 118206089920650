import React from 'react';
import { useTranslation } from 'react-i18next';
import { AltArrowForward } from '@airhelp/icons';
import { Flex, VStack, Text } from '@chakra-ui/react';
import bigImageBackground from 'assets/images/bg-homescreen-shortcut-tablet@2x.png';
import smallImageBackground from 'assets/images/bg-homescreen-shortcut@2x.png';
import { useTrackEvent } from 'utils/tracking/hooks';
import ExplanationModal from '../ExplanationModal';

const BottomBanner: React.FC = () => {
  const { t } = useTranslation();
  const { trackCtaClick } = useTrackEvent();

  return (
    <Flex width="100%" flexDirection="column" mb={2}>
      <Text
        p={0}
        mb={3}
        textTransform="uppercase"
        color="greyscale.600"
        fontWeight="bold"
        fontSize="xs"
      >
        {t('home_screen_banner.for_you')}
      </Text>
      <ExplanationModal
        render={(openModalHandler) => (
          <Flex
            bg="primary.900"
            p={{ base: 4, md: 8 }}
            minHeight="172px"
            borderRadius="lg"
            alignItems={{ base: 'flex-start', md: 'center' }}
            justifyContent="flex-start"
            backgroundImage={{
              base: `url(${smallImageBackground})`,
              md: `url(${bigImageBackground})`,
            }}
            backgroundRepeat="no-repeat"
            backgroundSize={{ base: '50% auto', sm: 'auto 100%' }}
            backgroundPosition="bottom right"
            onClick={() => {
              trackCtaClick('add', 'create shortcut low banner');
              openModalHandler();
            }}
            data-testid="add-to-home-screen-bottom-banner"
          >
            <VStack width="100%" spacing={3} alignItems="flex-start">
              <Text
                p={0}
                m={0}
                fontWeight="semibold"
                fontSize="xl"
                lineHeight="shorter"
                color="greyscale.100"
                maxWidth={{ base: '65%', md: '80%' }}
              >
                {t('home_screen_banner.add_ahplus_to_your_home_screen')}
              </Text>
              <Text
                p={0}
                m={0}
                fontWeight="normal"
                fontSize="md"
                lineHeight="base"
                color="greyscale.400"
                display="inline-block"
                maxWidth={{ base: '210px', sm: '320px', md: '360px' }}
              >
                {t('home_screen_banner.all_your_flights_and_benefits')}
                <AltArrowForward boxSize={5} />
              </Text>
            </VStack>
          </Flex>
        )}
      />
    </Flex>
  );
};

export default BottomBanner;
