import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, type AutocompleteProps } from '@airhelp/react';
import { useAirlines } from '@airhelp/webapp/react';
import { type Airline } from '@airhelp/webapp';
import useDebouncedValue from 'hooks/useDebouncedValue';

const AirlineAutocomplete: React.FC<AirlineAutocompleteProps> = ({
  errorMessage,
  defaultAirline,
  placeholder,
  ...props
}) => {
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState('');
  const searchDebounced = useDebouncedValue(searchTerm, 300);
  const isSearchStringCorrect = searchDebounced?.length >= 2;

  const { data, isLoading } = useAirlines({
    params: { query: searchDebounced },
    enabled: isSearchStringCorrect,
  });

  const airlinesOptions = data?.airlines.map((airline) => ({
    label: airline.displayName,
    value: airline,
  }));

  return (
    <Autocomplete
      {...props}
      placeholder={placeholder || t('funnels.airline_placeholder')}
      options={airlinesOptions}
      isLoading={isLoading}
      onInputChange={setSearchTerm}
      errorMessage={errorMessage}
      defaultValue={
        defaultAirline
          ? { label: defaultAirline.displayName, value: defaultAirline }
          : null
      }
    />
  );
};

interface AirlineOption {
  label: string;
  value: Airline;
  errorMessage?: string;
}

type AirlineAutocompleteProps = AutocompleteProps<AirlineOption> & {
  defaultAirline?: Airline;
  placeholder?: string;
};

export default AirlineAutocomplete;
