import React, { type PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FunnelAvatar,
  FunnelContent,
  FunnelFooter,
  FunnelHeader,
  FunnelProgressBar,
  FunnelShell,
} from '@airhelp/funnel';
import { useTrustpilotScore } from '@airhelp/webapp/react';
import { Button } from '@chakra-ui/react';
import { getYear } from 'date-fns';
import supportImage from 'assets/images/customer-support@2x.png';
import { useLayout } from 'contexts/LayoutContext/LayoutContext';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import CurrencySwitcher from 'elements/CurrencySwitcher';
import LanguageSwitcher from 'elements/LanguageSwitcher';
import useEffectOnce from 'hooks/useEffectOnce';
import { usePreferencesStore } from 'stores/preferences';
import { type TranslationKey } from 'types/i18n';
import { getWebsitePageUrl } from 'utils/sites';

const openModalButtonProps = {
  variant: 'subtle',
  bg: 'none',
  color: 'font.primary',
  _hover: { md: { color: 'primary.500' } },
  _focus: { bg: 'none' },
  _active: { bg: 'none' },
  border: 'none',
  fontSize: 'md',
  cursor: 'pointer',
  fontWeight: 'medium',
  px: 0,
  minWidth: 'content',
};

interface ILink {
  tKey: TranslationKey;
  key: string;
  url: string;
}

interface IComponent extends PropsWithChildren {
  disableCurrencySwitcher?: boolean;
  disableAssistantBadge?: boolean;
  steps: string[];
  sideHeader?: React.ReactNode;
  headerStart?: React.ReactNode;
}

const FunnelLayout: React.FC<IComponent> = ({
  children,
  disableCurrencySwitcher,
  disableAssistantBadge,
  steps,
  sideHeader,
  headerStart,
}) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const currency = usePreferencesStore((state) => state.currency);
  const setCurrency = usePreferencesStore((state) => state.setCurrency);
  const copyrightDate = getYear(new Date());
  const { activeStep, setStepsCount } = useLayout();
  const stepsLength = steps.length;

  const { data: trustpilotScore } = useTrustpilotScore();

  const links: ILink[] = [
    {
      tKey: t('common.help') as TranslationKey,
      key: 'help',
      url: getWebsitePageUrl('help', locale),
    },
    {
      tKey: t('common.terms_and_conditions') as TranslationKey,
      key: 'terms_and_conditions',
      url: getWebsitePageUrl('termsPath', locale),
    },
    {
      tKey: t('common.privacy_policy') as TranslationKey,
      key: 'privacy_policy',
      url: getWebsitePageUrl('privacyPath', locale),
    },
  ];

  useEffectOnce(() => {
    setStepsCount(stepsLength);
  });

  return (
    <FunnelShell
      sidebarContent={
        <FunnelProgressBar steps={steps} activeStep={activeStep} />
      }
      logoVariant="ahplus"
      header={sideHeader}
      backgroundColor="greyscale.100"
      disabledLink
    >
      <FunnelHeader
        badgeText={t('layouts.trips.header')}
        badgeVariant="rightsAssurance"
        disabledLink
        logoVariant="ahplus"
        stretch
        headerStart={headerStart}
        settingSwitcher={
          <>
            {!disableCurrencySwitcher && (
              <CurrencySwitcher
                onCurrencyChange={setCurrency}
                render={(openModalHandler) => (
                  <Button
                    onClick={openModalHandler}
                    data-testid="currency-switcher-button"
                    {...openModalButtonProps}
                  >
                    {currency}
                  </Button>
                )}
              />
            )}
            <LanguageSwitcher />
          </>
        }
      />
      <FunnelContent
        avatar={
          disableAssistantBadge ? null : (
            <FunnelAvatar
              name="Alex"
              role={t('common.your_air_help_assistant')}
              image={supportImage}
            />
          )
        }
      >
        {children}
      </FunnelContent>
      <FunnelFooter
        links={links.map((link) => ({
          title: link.tKey,
          href: link.url,
          key: link.key,
        }))}
        trustpilotText={t('common.trustpilot', {
          scoreValue: `${trustpilotScore?.scoreValue}/5`,
          totalReviews: trustpilotScore?.totalReviews.toLocaleString(),
        })}
        copyright={t('common.en_copyright_short', { date: copyrightDate })}
      />
    </FunnelShell>
  );
};

export default FunnelLayout;
