import React from 'react';
import { useParams } from 'react-router-dom';
import { track } from 'react-tracking';
import { Center } from '@chakra-ui/react';
import LoungeView from 'components/LoungeFinder/LoungeView';
import { Loader } from 'elements';
import { usePageTracking } from 'utils/tracking';
import useLounges from 'hooks/api/useLounges';

interface PageParams extends Record<string, string | undefined> {
  airport_code: string;
  lounge_code: string;
}

const LoungePage = () => {
  usePageTracking();

  const { airport_code: airportCode, lounge_code: loungeCode } =
    useParams<PageParams>();

  const { data: lounges, isLoading } = useLounges({
    airportCode: airportCode || '',
  });

  if (!airportCode || !loungeCode) {
    return null;
  }

  return isLoading ? (
    <Center>
      <Loader />
    </Center>
  ) : (
    <LoungeView lounges={lounges || []} activeLoungeCode={loungeCode} />
  );
};

export default track({ page_type: 'LoungeFinder.Lounge' })(LoungePage);
