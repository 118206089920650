import React from 'react';
import { useTranslation } from 'react-i18next';
import { Airplane, AirplaneRight, Calendar } from '@airhelp/icons';
import { type StackProps, HStack, Link, VStack } from '@chakra-ui/react';
import { format as formatDate } from 'date-fns-tz';
import queryString from 'query-string';
import { generatePath } from 'react-router';
import { dateFnLocaleHelper } from 'contexts/LocaleContext/LocaleContextProvider';
import { LinkTarget } from 'types/utils';
import { type ClaimEnquiry } from 'models/Claim';
import { PAGE_PATHS } from 'config/routes';
import { useIsClaimDetailsFeature } from 'hooks/useClaimDetails';
import ClaimActions from '../ClaimActions';
import AirportBadge from '../AirportBadge';
import Footer from './Footer';
import ClaimDetailsBox from './ClaimDetailsBox';

type IComponent = {
  claimEnquiry: ClaimEnquiry;
  sourceParam?: string;
  onDeleteClaimClick?: () => void;
  onCompleteClaimClick?: () => void;
} & StackProps;

const ClaimCard: React.FC<IComponent> = ({
  claimEnquiry,
  onDeleteClaimClick,
  onCompleteClaimClick,
  sourceParam,
  ...props
}) => {
  const { t, i18n } = useTranslation();
  const { claimId, createdAt, claimOrEnquiryUrl } = claimEnquiry;
  const { isEnabled: isClaimDetailsFeatureEnabled } =
    useIsClaimDetailsFeature();

  const {
    localDepartureDate,
    departureCity,
    departureAirportCode,
    arrivalCity,
    arrivalAirportCode,
    airline,
  } = claimEnquiry.disruptedFlight;

  const dateLocale = dateFnLocaleHelper(i18n.language);
  const disruptedDepartureDate = formatDate(
    localDepartureDate,
    'dd LLLL yyyy',
    {
      locale: dateLocale,
    },
  );
  const claimCreatedDate = formatDate(new Date(createdAt), 'dd LLLL yyyy', {
    locale: dateLocale,
  });

  const showDeleteClaimButton = onDeleteClaimClick;

  const showNewClaimPage = isClaimDetailsFeatureEnabled && claimId;

  const claimRedirectUrl = showNewClaimPage
    ? generatePath(PAGE_PATHS.CLAIM.INDEX_PAGE, { id: claimId })
    : queryString.stringifyUrl({
        url: claimOrEnquiryUrl,
        query: { src: sourceParam },
      });

  return (
    <VStack
      width="100%"
      gap={4}
      p={4}
      bgColor="greyscale.100"
      borderRadius="lg"
      data-testid={`claim-card-${claimId ? claimId : claimEnquiry.id}`}
      {...(claimId && {
        as: Link,
        href: claimRedirectUrl,
        isExternal: true,
        target: LinkTarget.SELF,
        rel: 'noopener noreferrer',
      })}
      {...props}
    >
      <HStack width="100%">
        <AirportBadge
          city={departureCity}
          iata={departureAirportCode}
          reverseLayout
          me="auto"
          flex="1"
        />
        <VStack alignContent="center">
          <AirplaneRight color="primary.500" boxSize={6} />
        </VStack>
        <AirportBadge
          city={arrivalCity}
          iata={arrivalAirportCode}
          reverseLayout
          ms="auto"
          flex="1"
          justifyContent="flex-end"
        />
      </HStack>
      <VStack
        width="100%"
        spacing={showDeleteClaimButton ? 7 : 4}
        height="100%"
        justify="space-between"
      >
        <HStack spacing={4} width="100%" align="flex-start">
          <ClaimDetailsBox
            primarylabel={t('common.airline')}
            secondaryLabel={airline}
            Icon={Airplane}
          />
          <ClaimDetailsBox
            primarylabel={t('common.flight_date')}
            secondaryLabel={disruptedDepartureDate}
            Icon={Calendar}
          />
        </HStack>
        {claimId ? (
          <Footer
            claimId={t('claim_card.claim_id', { claim_id: claimId })}
            createdAt={t('claims.created', { date: claimCreatedDate })}
          />
        ) : (
          <ClaimActions
            claimEnquiry={claimEnquiry}
            onDeleteClaimClick={
              showDeleteClaimButton ? onDeleteClaimClick : undefined
            }
            onCompleteClaimClick={onCompleteClaimClick}
          />
        )}
      </VStack>
    </VStack>
  );
};

export default ClaimCard;
