import React, { type PropsWithChildren } from 'react';
import { Fonts } from '@airhelp/react';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';

const Provider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      {children}
    </ChakraProvider>
  );
};

export default Provider;
