import React from 'react';
import { Flex } from '@chakra-ui/react';

const Card = ({ children, ...props }) => {
  return (
    <Flex
      backgroundColor="greyscale.100"
      borderRadius="xl"
      boxSizing="border-box"
      flexDirection="column"
      justifyContent="space-between"
      overflow="hidden"
      p={4}
      cursor={props.onClick ? 'pointer' : 'initial'}
      {...props}
    >
      {children}
    </Flex>
  );
};

export default Card;
