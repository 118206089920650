import React, { useEffect, useMemo, useContext } from 'react';
import { Box, Center } from '@chakra-ui/react';
import { Elements } from '@stripe/react-stripe-js';
import { type StripeElementsOptions } from '@stripe/stripe-js';
import { type ProductName } from '@airhelp/plus';
import { useGetActionNameForProduct } from 'components/Membership/membershipHelper';
import { UNKNOWN_CHANNEL } from 'config/channels';
import config from 'config';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { StripePaymentContext } from 'contexts/StripePaymentContext/StripePaymentContext';
import { ErrorBadge, Loader } from 'elements';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import useClientSecret from 'hooks/api/payments/useClientSecret';
import useStripeObject from 'hooks/api/payments/useStripeObject';
import { usePreferencesStore } from 'stores/preferences';
import StripeForm from './StripeForm';
import { theme } from './theme';

interface StripeCheckoutWidgetProps {
  callbackUrl: string;
  productName?: ProductName;
  footer?: React.ReactNode;
  recurring?: boolean;
  upgrade?: boolean;
  tripUuid?: string;
  requireTerms?: boolean;
  requireMarketing?: boolean;
  onPaymentSubmit?: () => void;
  onPaymentFailed?: () => void;
}

const StripeCheckoutWidget: React.FC<StripeCheckoutWidgetProps> = ({
  footer,
  callbackUrl,
  productName,
  requireTerms,
  requireMarketing,
  recurring,
  upgrade,
  tripUuid,
  onPaymentSubmit,
  onPaymentFailed,
}) => {
  const { stripePublishableKey, stripeAccountId } = config;

  const { locale } = useLocale();
  const { userProfile } = useMyProfile();
  const { setPaymentIntentId } = useContext(StripePaymentContext);
  const email = userProfile?.email || '';
  const newUrlSearchParams = new URLSearchParams(location.search);
  const channel = newUrlSearchParams.get('channel') || UNKNOWN_CHANNEL;

  const actionButtonName = useGetActionNameForProduct(productName);

  const stripeObject = useStripeObject({
    publishableKey: stripePublishableKey,
    accountId: stripeAccountId,
  });

  const currency = usePreferencesStore((state) => state.currency);

  const { pending, clientSecret, error } = useClientSecret({
    email,
    productName,
    currency,
    recurring,
    channel,
    locale,
    tripUuid,
    upgrade,
  });

  useEffect(() => {
    setPaymentIntentId(clientSecret);
  }, [clientSecret]);

  const options = useMemo(() => {
    return {
      clientSecret,
      appearance: theme,
      locale,
      loader: 'never',
    } as StripeElementsOptions;
  }, [clientSecret, locale]);

  if (error) {
    return (
      <Box maxWidth="90%">
        <ErrorBadge />
      </Box>
    );
  }

  if (pending || !stripeObject) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <>
      {clientSecret ? (
        <Box width="100%">
          <Elements options={options} stripe={stripeObject}>
            <StripeForm
              callbackUrl={callbackUrl}
              clientSecret={clientSecret}
              requireTerms={requireTerms}
              requireMarketing={requireMarketing}
              actionButtonName={actionButtonName}
              footer={footer}
              onPaymentSubmit={onPaymentSubmit}
              onPaymentFailed={onPaymentFailed}
            />
          </Elements>
        </Box>
      ) : null}
    </>
  );
};

export default StripeCheckoutWidget;
