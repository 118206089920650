import React from 'react';
import { Outlet, createBrowserRouter, RouterProvider } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import AppInitializer from 'components/Routes/AppInitializer';
import DefaultPageRouteLayout from 'components/Routes/DefaultPageRouteLayout';
import {
  HomePage,
  NotFoundPage,
  RedirectPage,
  TripRegistrationPage,
  ErrorPage,
  JourneysPage,
} from 'pages';
import * as UpsellTripsPages from 'pages/Upsell/Trips';
import ScrollToTop from 'utils/scrollToTop';
import NewFlightsPage from 'pages/Flights/NewFlightsPage';
import { AuthenticationRoutes } from 'routes/AuthenticationRoutes';
import { MembershipRoutes } from 'routes/MembershipRoutes';
import { ClaimsRoutes } from 'routes/ClaimsRoutes';
import { MyAccountRoutes } from 'routes/MyAccountRoutes';
import { LoungeFinderRoutes } from 'routes/LoungeFinderRoutes';
import { JourneyRoutes } from 'routes/JourneyRoutes';
import { LoungesRoutes } from 'routes/LoungesRoutes';
import { UpsellRoutes } from 'routes/UpsellRoutes';
import { AirBundleRoutes } from 'routes/AirBundleRoutes';
import { TripsRoutes } from 'routes/TripsRoutes';
import { DefaultErrorBoundary } from 'utils/DefaultErrorBoundary';
import config from './config';

let routerOptions: { basename?: string } = {
  basename: config.baseUrl,
};

const hostname = window.location.hostname;
if (hostname.includes('my-sta.airhelp.') || hostname.includes('my.airhelp.')) {
  routerOptions = {};
}

const { isProduction } = config;

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const newRouter = sentryCreateBrowserRouter(
  [
    {
      element: (
        <AppInitializer>
          <Outlet />
          <ScrollToTop />
        </AppInitializer>
      ),
      ErrorBoundary: isProduction && DefaultErrorBoundary,
      children: [
        {
          id: 'add-flights',
          path: 'flights/new/*',
          element: <NewFlightsPage />,
        },
        LoungeFinderRoutes,
        MembershipRoutes,
        {
          path: 'trip-registration/:uuid/:token',
          element: <TripRegistrationPage />,
        },
        TripsRoutes,
        UpsellRoutes,
        // TODO: upsell route is doubled due to previous implementation and changing layout.
        //       something to clean up in the future.
        {
          path: 'upsell/trips/:token',
          children: [
            {
              path: 'processing-payment',
              element: <UpsellTripsPages.ProcessingPaymentPage />,
            },
          ],
        },
        AirBundleRoutes,
        AuthenticationRoutes,
        {
          element: <DefaultPageRouteLayout />,
          children: [
            {
              index: true,
              element: <HomePage />,
            },
            ...ClaimsRoutes,
            MyAccountRoutes,
            JourneyRoutes,
            {
              path: 'journeys',
              element: <JourneysPage />,
            },
            LoungesRoutes,
            {
              path: 'r',
              element: <RedirectPage />,
            },
          ],
        },
        {
          path: '*',
          element: <NotFoundPage />,
        },
        {
          path: 'error',
          element: <ErrorPage />,
        },
      ],
    },
  ],
  routerOptions,
);

const RouterComponent = () => {
  return <RouterProvider router={newRouter} />;
};

export default RouterComponent;
