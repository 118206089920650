import { Flex, Heading, HStack, Link, Text, VStack } from '@chakra-ui/react';
import { cloneElement, type PropsWithChildren, type ReactElement } from 'react';
import { AltArrowForward, Chat, Envelope, Help, Delete } from '@airhelp/icons';
import { NavLink } from 'react-router-dom';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import config from 'config';
import { useZowie } from '../../contexts/ZowieContext';

export function SupportBox({ onClose }: { onClose?: () => void }) {
  const { openZowieChat } = useZowie();
  const { locale } = useLocale();

  return (
    <VStack
      gap={4}
      p={{
        base: 4,
        md: 6,
      }}
      borderRadius="xl"
      bg="white"
      align="start"
    >
      <Flex
        as="header"
        w="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <Heading
          fontSize={{
            base: 'sm',
            lg: 'xl',
          }}
          as="h3"
        >
          How can we help you?
        </Heading>
        <Flex
          display={{
            base: 'flex',
            lg: 'none',
          }}
          justify="center"
          align="center"
          w={7}
          onClick={onClose}
          h={7}
          backgroundColor="greyscale.300"
          borderRadius="full"
        >
          <Delete fontSize="12px" color="greyscale.600" />
        </Flex>
      </Flex>
      <VStack gap={0} w="full">
        {/*TODO: Translations*/}
        <SupportBoxItem
          icon={<Chat />}
          title="Chat"
          description={`Our team's here for you`}
          onClick={openZowieChat}
        />
        <SupportBoxItem
          icon={<Envelope />}
          title="Customer Support"
          description="Contact Our Support Team!"
          link={config.contactUrl}
        />
        <SupportBoxItem
          icon={<Help />}
          title={`FAQ's`}
          description="We answer your questions"
          link={config.helpUrl.replace('{{locale}}', locale)}
        />
      </VStack>
    </VStack>
  );
}

function SupportBoxItem({
  icon,
  title,
  description,
  link,
  onClick,
}: PropsWithChildren<{
  icon: ReactElement;
  title: string;
  description: string;
  link?: string;
  onClick?: () => void;
}>) {
  const styledIcon = cloneElement(icon, {
    color: 'primary.400',
    width: '20px',
    height: '20px',
  });

  const isLinkExternal = link?.startsWith('http');
  const ItemLink = onClick
    ? ({ children }: PropsWithChildren) => children
    : ({ children }: PropsWithChildren) => (
        <Link
          as={isLinkExternal ? undefined : NavLink}
          href={link}
          isExternal={isLinkExternal}
          display="inline-block"
          w="full"
        >
          {children}
        </Link>
      );

  return (
    <ItemLink>
      <HStack
        py={4}
        px={{
          base: 0,
          lg: 2,
        }}
        _hover={{ bg: 'greyscale.200' }}
        borderRadius="md"
        onClick={onClick}
        w="full"
        cursor={onClick ? 'pointer' : undefined}
      >
        <Flex
          justify="center"
          align="center"
          w="40px"
          h="40px"
          rounded="full"
          borderWidth="2px"
          borderStyle="solid"
          borderColor="greyscale.300"
          me={2.5}
        >
          {styledIcon}
        </Flex>
        <VStack flex={1} gap={0} align="start">
          <Heading fontSize="sm" as="h4">
            {title}
          </Heading>
          <Text fontSize="xs" color="greyscale.600">
            {description}
          </Text>
        </VStack>
        <Flex p={2} justify="center" align="center">
          <AltArrowForward width={6} height={6} color="greyscale.500" />
        </Flex>
      </HStack>
    </ItemLink>
  );
}
