import React from 'react';
import { Trans } from 'react-i18next';
import { Link, type LinkProps as ChakraLinkProps } from '@chakra-ui/react';
import config from 'config';
import { type TranslationKey } from 'types/i18n';

interface LinkProps extends ChakraLinkProps {
  tKey: TranslationKey;
}

const ContactLink: React.FC<LinkProps> = ({ tKey, ...props }) => {
  return (
    <Trans
      // invesigate why this is working only when casted to TranslationKey[]
      i18nKey={tKey as unknown as TranslationKey[]}
      values={{ contactEmail: config.contactEmail }}
      components={{ a: <Link fontSize="sm" isExternal {...props} /> }}
    />
  );
};

export default ContactLink;
