import { postPromoCode, type PostPromoCodeParams } from '@airhelp/plus';
import { useMutation } from '@tanstack/react-query';
import { ahplusClient } from 'api/clients/clients';

const useApplyPromoCode = () => {
  const useApplyPromoCodeMutation = useMutation({
    mutationFn: (params: PostPromoCodeParams) =>
      postPromoCode({
        client: ahplusClient,
        params,
      }),
  });

  return useApplyPromoCodeMutation;
};

export default useApplyPromoCode;
