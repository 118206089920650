import styles from './Carousel.module.scss';

const defaultSettings = {
  dots: true,
  fade: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  arrows: false,
  className: [styles.slider],
  dotsClass: [styles.dots],
};

export default defaultSettings;
