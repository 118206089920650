import React from 'react';
import { type IconType } from '@airhelp/icons';
import { IconButton } from '@chakra-ui/react';

const FormIcon = ({
  iconName,
  Icon,
  onClick,
  isDisabled,
  dataTestId,
}: {
  iconName: string;
  Icon: IconType;
  onClick: () => void;
  isDisabled?: boolean;
  dataTestId?: string;
}) => {
  return (
    <IconButton
      aria-label={iconName}
      icon={
        <Icon
          w="auto"
          h={5}
          color={isDisabled ? 'primary.300' : 'primary.900'}
          _hover={{ color: isDisabled ? 'primary.300' : 'primary.500' }}
        />
      }
      onClick={onClick}
      size="xs"
      isDisabled={isDisabled}
      bg="transparent"
      data-testid={dataTestId}
      _active={{ background: 'transparent', boxShadow: 'none' }}
      _hover={{ background: 'transparent' }}
    />
  );
};

export default FormIcon;
