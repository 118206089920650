import { useEffect, useState } from 'react';
import { loadStripe, type Stripe } from '@stripe/stripe-js';

interface UseStripeObjectProps {
  publishableKey: string;
  accountId: string;
}

const useStripeObject = ({
  publishableKey,
  accountId,
}: UseStripeObjectProps) => {
  const [stripeObject, setStripeObject] = useState<Stripe | null>(null);

  useEffect(() => {
    const fetchStripeObject = async () => {
      if (accountId) {
        const response = await loadStripe(publishableKey);
        setStripeObject(response);
      }
    };

    fetchStripeObject();
  }, [accountId]);

  return stripeObject;
};

export default useStripeObject;
