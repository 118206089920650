import { CloseButton, Box, Text } from '@chakra-ui/react';
import { Heart } from '@airhelp/icons';
import { useTranslation } from 'react-i18next';
import airport from 'assets/images/airhelp-airport.png';

interface UpgradeModalHeaderProps {
  onClose: () => void;
}

export const UpgradeModalHeader: React.FC<UpgradeModalHeaderProps> = ({
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      as="header"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        paddingX: {
          base: '8px',
          md: '16px',
        },
        paddingY: {
          base: '8px',
          md: '16px',
        },
        minHeight: {
          base: '240px',
          md: '380px',
        },
        borderTopRadius: 'lg',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundImage: `url(${airport})`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          bg: 'success.500',
          gap: '6px',
          alignItems: 'center',
          height: 'max-content',
          padding: '4px 6px',
          borderRadius: '6px',
        }}
      >
        <Heart color="white" />
        <Text fontWeight="700" color="white" fontSize="sm">
          {t('new_benefits_modal.just_for_you')}
        </Text>
      </Box>
      <CloseButton size="md" bg="greyscale.200" onClick={onClose} />
    </Box>
  );
};
