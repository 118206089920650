import React from 'react';
import {
  type Control,
  Controller,
  type FieldErrors,
  type UseFormTrigger,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '@airhelp/react';
import { Flex, VStack } from '@chakra-ui/react';
import { type UpdateUserProfilePayload } from '@airhelp/webapp';
import useEffectOnce from 'hooks/useEffectOnce';
import {
  phoneNumberPattern,
  phoneNumberLength,
  validatePhoneNumberCountryCode,
  validateName,
  removeExtraSpace,
} from 'utils/validations';
import { type UserProfile } from 'models/userProfile/UserProfile';

interface IComponent {
  control: Control<UpdateUserProfilePayload>;
  errors: FieldErrors<UpdateUserProfilePayload>;
  userProfile: UserProfile;
  responsive?: boolean;
  trigger: UseFormTrigger<UpdateUserProfilePayload>;
}

const FormInputs: React.FC<IComponent> = ({
  control,
  errors,
  responsive,
  userProfile,
  trigger,
}) => {
  const { t } = useTranslation();

  useEffectOnce(() => {
    trigger('phoneNumber');
  });

  return (
    <VStack spacing={6} mb={3}>
      <Flex
        flexDirection={responsive ? { base: 'column', md: 'row' } : 'column'}
        gap={6}
        w="100%"
      >
        <Controller
          render={({ field: { onChange, name, value, onBlur } }) => (
            <Input
              isInvalid={Boolean(errors.firstName)}
              errorMessage={errors.firstName?.message || ''}
              size="lg"
              id="first-name"
              label={t('user.first_name')}
              name={name}
              value={value}
              type="text"
              placeholder={t('user.first_name')}
              onChange={onChange}
              onBlur={() => {
                onBlur();
                onChange(removeExtraSpace(value));
              }}
              data-testid="first-name"
            />
          )}
          rules={{
            required: {
              value: true,
              message: t('errors.please_add_first_name'),
            },
            validate: (value) => validateName(value, t),
          }}
          name="firstName"
          control={control}
          defaultValue={userProfile.firstName || ''}
        />

        <Controller
          render={({ field: { onChange, name, value, onBlur } }) => (
            <Input
              isInvalid={Boolean(errors.lastName)}
              errorMessage={errors.lastName?.message || ''}
              size="lg"
              id="last-name"
              label={t('user.surname')}
              name={name}
              value={value}
              type="text"
              placeholder={t('user.surname')}
              onChange={onChange}
              onBlur={() => {
                onBlur();
                onChange(removeExtraSpace(value));
              }}
              data-testid="last-name"
            />
          )}
          rules={{
            required: {
              value: true,
              message: t('errors.please_add_last_name'),
            },
            validate: (value) => validateName(value, t),
          }}
          name="lastName"
          control={control}
          defaultValue={userProfile.lastName || ''}
        />
      </Flex>
      <Flex
        flexDirection={responsive ? { base: 'column', md: 'row' } : 'column'}
        gap={6}
        w="100%"
      >
        <Input
          size="lg"
          id="email"
          label={t('user.email')}
          value={userProfile.email}
          type="text"
          placeholder={t('common.email_placeholder')}
          disabled
          data-testid="email"
        />

        <Controller
          render={({ field: { onChange, name, value } }) => (
            <Input
              isInvalid={Boolean(errors.phoneNumber)}
              errorMessage={errors.phoneNumber?.message || ''}
              size="lg"
              id="phone-number"
              label={t('user.phone_number')}
              name={name}
              value={value}
              type="text"
              placeholder={t('common.phone_number_placeholder')}
              onChange={onChange}
              data-testid="phone-number"
            />
          )}
          rules={{
            minLength: {
              value: phoneNumberLength.min,
              message: t('errors.too_short', {
                min: phoneNumberLength.min,
                attribute: t('user.phone_number'),
              }),
            },
            maxLength: {
              value: phoneNumberLength.max,
              message: t('errors.too_long', {
                max: phoneNumberLength.max,
                attribute: t('user.phone_number'),
              }),
            },
            pattern: {
              value: phoneNumberPattern,
              message: t('errors.phone_number_invalid'),
            },
            validate: {
              countryCode: (value) => validatePhoneNumberCountryCode(value, t),
            },
          }}
          name="phoneNumber"
          control={control}
          defaultValue={userProfile.phoneNumber || ''}
        />
      </Flex>
    </VStack>
  );
};

export default FormInputs;
