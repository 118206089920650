import {
  fetchBillingManagement,
  type FetchBillingManagementResponse,
} from '@airhelp/plus';
import { type ApiQueryOptions } from 'hooks/api/queryHelper';
import { useProtectedQuery } from 'hooks/api/useProtectedQuery';
import { ahplusClient } from 'api/clients/clients';

const useBillingManagement = ({
  options,
}: {
  options?: ApiQueryOptions<FetchBillingManagementResponse>;
}) => {
  return useProtectedQuery({
    queryKey: ['billing-management'],
    queryFn: () => fetchBillingManagement({ client: ahplusClient }),
    ...options,
  });
};

export default useBillingManagement;
