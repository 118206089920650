import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type IconType } from '@airhelp/icons';
import { LanguageSwitcherModal } from '@airhelp/react';
import { useDisclosure } from '@chakra-ui/react';
import { TrackingContext } from 'contexts';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import {
  locales,
  type Locale,
  type LocaleObject,
  mapLocaleToFlagKey,
} from 'contexts/LocaleContext/locales';
import { useTrackEvent } from 'utils/tracking/hooks';

interface ListItem {
  id: string;
  name: string;
  icon?: IconType;
  rtl?: boolean;
  hidden?: boolean;
}

const mapLocaleItemsToListItems = (items: LocaleObject[]): ListItem[] => {
  return items.map(({ code, displayName, icon, hidden, rtl }) => ({
    id: code,
    name: displayName,
    icon,
    hidden,
    rtl,
  }));
};

const suggestedLocales: LocaleObject[] = [locales.en, locales['pt-BR']];
const suggestedLocalesSettings: ListItem[] =
  mapLocaleItemsToListItems(suggestedLocales);

const LanguageSwitcher = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { locale, setLocale } = useLocale();
  const [flagKey, setFlagKey] = useState<string>(mapLocaleToFlagKey(locale));
  const { trackCtaClick } = useTrackEvent();
  const { subPageType } = useContext(TrackingContext);

  const mapLocaleToActiveItem = (localeValue: Locale): string => {
    return localeValue.toLowerCase();
  };

  const [activeItems, setActiveItems] = useState<string[]>([
    mapLocaleToActiveItem(locale),
  ]);

  const onItemClick = (data: ListItem): void => {
    setLocale(data.id);
    setFlagKey(mapLocaleToFlagKey(data.id));

    onClose();
  };

  const supportedLocales: LocaleObject[] = Object.values(locales).filter(
    (l) => !suggestedLocales.includes(l) && !l.hidden,
  );

  const supportedLocalesSettings: ListItem[] = useMemo(
    () => mapLocaleItemsToListItems(supportedLocales),
    [supportedLocales],
  );

  const settingsModalContent = [
    {
      listHeading: t('common.suggested_languages'),
      listItems: suggestedLocalesSettings,
    },
    {
      listHeading: t('common.select_language'),
      listItems: supportedLocalesSettings,
      listAutoflow: { base: 'row', lg: 'column' },
    },
  ];

  useEffect(() => {
    setActiveItems([locale]);
    setFlagKey(mapLocaleToFlagKey(locale));
  }, [locale, flagKey]);

  const onOpenCallback = () => {
    trackCtaClick('language icon', subPageType);

    onOpen();
  };

  return (
    <LanguageSwitcherModal
      heading={t('common.select_language')}
      activeItems={activeItems}
      lists={settingsModalContent}
      flagKey={flagKey}
      onItemClick={onItemClick}
      onClose={onClose}
      onOpen={onOpenCallback}
      isOpen={isOpen}
    />
  );
};

export default LanguageSwitcher;
