import {
  fetchAirLuggageJourneys,
  type FetchAirLuggageJourneysResponse,
} from '@airhelp/plus';
import { type ApiQueryOptions } from 'hooks/api/queryHelper';
import { useProtectedQuery } from 'hooks/api/useProtectedQuery';
import { ahplusClient } from 'api/clients/clients';
import { transformToAirLuggageJourneyClass } from 'models/journey/AirLuggageJourney';

const useFetchAirLuggageJourney = ({
  journeyId,
  options,
}: {
  journeyId: number;
  options?: ApiQueryOptions<FetchAirLuggageJourneysResponse>;
}) => {
  return useProtectedQuery({
    queryKey: ['journey', 'air-luggages', { journeyId }],
    queryFn: () =>
      fetchAirLuggageJourneys({ client: ahplusClient, params: { journeyId } }),
    select: (data) =>
      data.map((airLuggageJourney) =>
        transformToAirLuggageJourneyClass(airLuggageJourney),
      ),
    ...options,
  });
};

export default useFetchAirLuggageJourney;
